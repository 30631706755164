import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../shared/services/data.service';
import { RestaurantFactory } from '../shared/models/restaurantFactory';
import { SharedDataService } from '../shared/services/shared-data.service';
import { isPlatformBrowser } from '../../../node_modules/@angular/common';

// declare var google: any;

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.css']
})
export class ThankYouComponent implements OnInit {
  // coordinates of restaurant & user
  // public resLat: number = 0;
  // public resLong: number;
  // public userLat: number = 0;
  // public userLong: number;

  // public userRestDir;

  // public restaurant: Restaurant = new Restaurant();

  public user_name: string = "";
  public orderNummer: string = "";
  // private nextCall;
  private _trackingid = "-1";


  // public markerOptions = {
  //   origin: {
  //     //icon: '/assets/img/icon-map.png'
  //   },
  //   destination: {
  //     icon: '/assets/img/icon-map.png'
  //     // label: 'MARKER LABEL',
  //     // opacity: 0.8,
  //   },
  // }

  constructor(private _sharedDataService: SharedDataService, private _ActivatedRoute: ActivatedRoute, private _router: Router, private _dataService: DataService, public resFactory: RestaurantFactory, @Inject(PLATFORM_ID) private _platformId: Object) { }

  ngOnInit() {

    if(isPlatformBrowser(this._platformId))
    {
      // if (localStorage.getItem('totalitems') == null || localStorage.getItem('totalitems') == '0') {
      //   this._router.navigate(['/']);
      //   //hier checken of de guid bestaat voor deze restaurant dan gewoon laten zien
      //   //indien guid bestaat en placeorder  = 0
      //   return;
      // }


      this.getOrderDetails();

      // this.nextCall = setInterval(() => {
      //   this._loaderService.showLoader = true;
      // }, 1);
    }
  }

  private getOrderDetails() {

      const paymentid: string = this._ActivatedRoute.snapshot.queryParamMap.get('token');
      const payerid: string = this._ActivatedRoute.snapshot.queryParamMap.get('PayerID');
      const payment_intent: string = this._ActivatedRoute.snapshot.queryParamMap.get('payment_intent');

      
      if(this._sharedDataService.orderguid===""){
        this._router.navigate(["/"]);
      }

      this._dataService.getOrderMetaData(this._sharedDataService.orderguid, paymentid, payerid, payment_intent).subscribe(res => {
        // console.log(res);

        if (res != null) {
          if (res.success == 1) {
            if (res.value.roPymntVfd == 1) {


              localStorage.setItem('totalitems', '0');
              localStorage.removeItem('basket');



              //console.log("ontvangen data")
              //.log(res);




              // if(this.resFactory.restaurant.resto_lat != null && this.resFactory.restaurant.resto_lng !=null)
              // {
              //   // console.log("this.resFactory.restaurant.resto_lat: "+this.resFactory.restaurant.resto_lat);
              //   // console.log("this.resFactory.restaurant.resto_lng: "+this.resFactory.restaurant.resto_lng);

              //   this.resLat = this.resFactory.restaurant.resto_lat;
              //   this.resLong = this.resFactory.restaurant.resto_lng;
              // }
              // else
              // {
              //   // console.log("call httpx");
              //   this.getCoordinates(res.value.restaurant_address, "", res.value.restaurant_postcode, res.value.restaurant_city, false);
              // }


              // if (res.value.roAddress != null && res.value.roAddress != "") {
              //   this.getCoordinates(res.value.roAddress, "", res.value.roPostal_code, res.value.roCity, true);
              // }

              //this.restaurant.restaurant_name = res.value.restaurant_name;
              this._trackingid = res.value.rotrackingid;
              this.user_name = res.value.roUseName;
              this.orderNummer = res.value.roStrId;

              const gaValue: number = Math.round(res.value.roPrice);
              const gaAction: string = this.orderNummer + "_" + this.resFactory.restaurant.id + "_" + this.resFactory.restaurant.restaurant_postcode + "_" + this.resFactory.restaurant.restaurant_name + "_" + gaValue;
              this._sharedDataService.sendGoogleAnalyticsEvent("RealOrder", gaAction, this.orderNummer, gaValue,res.value.email);
              this.stopInterval();
            } else {
              //console.log("nog niet betaald");
              this.stopInterval();
              //this._router.navigate(['/order', this.orderGuid]);
              //window.alert("1Er is iets misgelopen, gelieve later opnieuw te proberen. Geen data gevonden.");

              window.location.href = this._sharedDataService.checkouturl + "/order/" + this._sharedDataService.orderguid;
            }
          } else {
            this.stopInterval();
            window.location.href = this._sharedDataService.checkouturl + "/order/" + this._sharedDataService.orderguid;
            //this._router.navigate(['/']);
            // window.alert("succes is false");
            // console.log("succes is false")
              //window.alert("2Er is iets misgelopen, gelieve later opnieuw te proberen. Geen data gevonden.");
            // this._router.navigate(["/"]);
          }
        } else {
          this.stopInterval();
          //console.log("order nog niet geplaatst");
          // this._router.navigate(["/"]);
          //window.alert("3Er is iets misgelopen, gelieve later opnieuw te proberen. Geen data gevonden.");
          //window.location.href = environment.CHECKOUT_URL + "order/" + this.orderGuid;
        }
      }, err => {
        this.stopInterval();
        // console.log("error on call")
          //window.alert("4Er is een fout opgetreden, gelieve later opnieuw te proberen.");
        this._router.navigate(["/"]);
      });

    // });
  }

  // private getCoordinates(street: string, no: string, zip: string, city: string, isUserLocation: boolean) {
  //   return this._dataService.getCoordinates(street, no, zip, city).subscribe((res) => {
  //     if (res.status == "OK") {
  //       if (isUserLocation) {
  //         this.userLat = res.results[0].geometry.location.lat;
  //         this.userLong = res.results[0].geometry.location.lng;
  //       } else {
  //         this.resLat = res.results[0].geometry.location.lat;
  //         this.resLong = res.results[0].geometry.location.lng;
  //       }

  //       if (this.resLong != null && this.userLong != null) {
  //         this.userRestDir = {
  //           origin: { lat: +(this.userLat), lng: +(this.userLong) },
  //           destination: { lat: +(this.resLat), lng: +(this.resLong) }
  //         }
  //       }
  //     }
  //   })
  // }

  private stopInterval() {
    // clearInterval(this.nextCall);
  }

  get_tracker_url(){

    if(this._sharedDataService.langpath == "nl"){
      return this._sharedDataService.baseurl + "/foodtracker?tracker="+this._trackingid;
    }
    else{
      return this._sharedDataService.baseurl + "/"+this._sharedDataService.langpath + "/foodtracker?tracker="+this._trackingid;
    }

  }

}
