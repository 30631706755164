import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { readFileSync } from 'fs';
import { join } from 'path';
import { Observable, of } from 'rxjs';

declare var require: any;
 const fs = require('fs');

//const i18nMap = require('../../assets/i18n/autogen/map.json');

export class TranslateBrowserLoader implements TranslateLoader {
    constructor(
      private transferState: TransferState,
      private http: HttpClient,
      private prefix: string = 'assets/i18n/',
      private suffix: string = '.json',
    ) { }
    
    public getTranslation(lang: string): Observable<any> {
      const key = makeStateKey<any>('transfer-translate-' + lang);
      const data = this.transferState.get(key, null);
      
      // First we are looking for the translations in transfer-state, if none found, http load as fallback
      return data
        ? of(data)
        : new TranslateHttpLoader(this.http, this.prefix, this.suffix).getTranslation(lang);
    }
  }

  export class TranslateFsLoader implements TranslateLoader {
    constructor(
      // ADDED: inject the transferState service
      private transferState: TransferState,
      private prefix = 'dist/browser/assets/i18n',
      private suffix = '.json'
    ) { 
  
      // const path = join(process.cwd(), `${this.prefix}/nl${this.suffix}`);
      // console.log("path:"+path);
      // console.log(readFileSync(path, 'utf8'));
  
    }
  
    public getTranslation(lang: string): Observable<any> {
      const path = join(process.cwd(), `${this.prefix}/${lang}${this.suffix}`);
      // console.log(path);
      // console.log(readFileSync(path, 'utf8'));
      const data = JSON.parse(readFileSync(path, 'utf8'));
      // ADDED: store the translations in the transfer state:
      const key = makeStateKey<any>('transfer-translate-' + lang);
      this.transferState.set(key, data);
      return of(data);
    }
  }
