import { BrowserModule, TransferState } from '@angular/platform-browser';
import { NgModule, PLATFORM_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
// import { HttpModule, XHRBackend, RequestOptions } from '@angular/http';

import { HomeComponent } from './home/home.component';
import { AppComponent } from './app.component';
import { DataService } from './shared/services/data.service';
import { HeaderComponent } from './header/header.component';
import { SharedDataService } from './shared/services/shared-data.service';
import { ContactComponent } from './contact/contact.component';
import { BeoordelingenComponent } from './beoordelingen/beoordelingen.component';
import { AppRouting } from './app.routes';
import { RestaurantFactory } from './shared/models/restaurantFactory';
import { FooterComponent } from './footer/footer.component';
import { LoaderComponent } from './shared/loader/loader.component';
import { LoaderService } from './shared/services/loader.service';
import { HttpXService } from './shared/services/httpx.service';
import { ZipCodeModalComponent } from './modals/zip-code-modal/zip-code-modal.component';
import { PrivacyBeleidComponent } from "./privacybeleid/privacybeleid.component";


import { BasketFactory } from './shared/models/basketFactory';
import { AddItemModalComponent } from './modals/add-item-modal/add-item-modal.component';
import { ClosedModalComponent } from './modals/closed-modal/closed-modal.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { CartModalComponent } from './modals/cart-modal/cart-modal.component';
import { JsonPipe, TitleCasePipe } from '@angular/common';
import { FilterCategoriesPipe } from './filtercategories.pipe';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HttpHandler } from '@angular/common/http';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ModalModule } from 'ngx-bootstrap/modal';

import { TranslateBrowserLoader, TranslateFsLoader } from './translate-loaders';
import { isPlatformBrowser } from '@angular/common';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
    declarations: [
        HomeComponent,
        AppComponent,
        HeaderComponent,
        ContactComponent,
        BeoordelingenComponent,
        FooterComponent,
        LoaderComponent,
        ZipCodeModalComponent,
        AddItemModalComponent,
        ClosedModalComponent,
        ThankYouComponent,
        CartModalComponent,
        FilterCategoriesPipe,
        PrivacyBeleidComponent
    ],
    imports: [
        ModalModule.forRoot(),
        BrowserModule.withServerTransition({ appId: 'order-app' }),
        FormsModule,
        HttpClientModule,
        // TranslateModule.forRoot({
        //   loader: {
        //     provide: TranslateLoader,
        //     useFactory: HttpLoaderFactory,
        //     deps: [HttpClient]
        //   }
        // }),
        AppRouting,
        InfiniteScrollModule,
        LazyLoadImageModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateLoaderFactory,
                deps: [HttpClient, TransferState, PLATFORM_ID] // ADDED: dependency for the factory func
            }
        })
    ],
    providers: [
        DataService,
        SharedDataService,
        BasketFactory,
        RestaurantFactory,
        LoaderService,
        {
            provide: HttpXService,
            useFactory: createHttpXLoader,
            deps: [
                HttpHandler,
                LoaderService
            ]
        },
        TitleCasePipe,
        CookieService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function createHttpXLoader(options: HttpHandler, loaderService: LoaderService) {
  return new HttpXService(options, loaderService);
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function translateLoaderFactory(httpClient: HttpClient, transferState: TransferState, platform: any) {
  // console.log("testerke : "+(isPlatformBrowser(platform)?"platform true":"platform false"));
  // console.log(transferState??JSON.stringify(transferState));
  return isPlatformBrowser(platform)
    ? new TranslateBrowserLoader(transferState, httpClient)
    : new TranslateFsLoader(transferState);
}