<footer id="footer" *ngIf="resFactory.restaurant != null">
  <div class="container">
    <div class="row">
      <div class="col-sm poweredby-wrapper">
        <h3>Powered By:</h3>
        <div class="poweredby">
          <a href="{{shareddataservice.baseurl}}/menu/{{resFactory.restaurant.restaurant_slug}}" rel="nofollow">
          <img alt="{{resFactory.restaurant.calc_title}}" class="logo" lazyLoad="{{logomenute}}">
          </a>
        </div>
      </div>



      <div class="col-sm" itemscope="" itemtype="http://schema.org/Restaurant">
        <meta itemprop="url" content="{{resFactory.restaurant.url}}">
        <meta itemprop="hasMenu" content="{{resFactory.restaurant.url}}">
        <meta itemprop="priceRange" content="€€">
        
        <h3>
          <i class="icon-location"></i>{{'footer.adres-label' | translate}}</h3>
        <b itemprop="name" [attr.content]="concatStrings(resFactory.restaurant.restaurant_name,resFactory.restaurant.restaurant_city)">{{resFactory.restaurant.restaurant_name}}</b>
        <div itemprop="branchOf" [attr.content]="resFactory.restaurant.restaurant_name">{{resFactory.restaurant.restaurant_city}}</div>
        <div itemprop="address" itemscope="" itemtype="http://schema.org/PostalAddress">
          <span itemprop="streetAddress">{{resFactory.restaurant.restaurant_address}}</span>
          <br>
          <span itemprop="postalCode">{{resFactory.restaurant.restaurant_postcode}}</span>&nbsp;
          <span itemprop="addressLocality">{{resFactory.restaurant.restaurant_city}}</span>{{footerCountry}}
          <meta itemprop="addressCountry" content="{{resFactory.restaurant.countryId==2?'NL':'BE'}}">
        </div>

        <div>{{'footer.tel-label' | translate}}
          <span itemprop="telephone">{{resFactory.restaurant.restaurant_tel}}</span>
        </div>
        <div itemprop="geo" itemscope="" itemtype="http://schema.org/GeoCoordinates">
          <meta itemprop="latitude" content="{{resFactory.restaurant.resto_lat}}">
          <meta itemprop="longitude" content="{{resFactory.restaurant.resto_lng}}">
        </div>
        <meta itemprop="image" [content]="'https://cdn.etenonline.be/upload/restaurants/' + resFactory.restaurant.restaurant_logo + '-b.png'">
        
        <div *ngIf="resFactory.restaurant.review_total_cnt>0" itemprop="aggregateRating" itemscope itemtype="http://schema.org/AggregateRating">
          <meta itemprop="ratingValue" [content]="resFactory.restaurant.review_avg_star_cnt">
          <meta itemprop="reviewCount" [content]="resFactory.restaurant.review_total_cnt">
          <meta itemprop="bestRating" content="5">
          <meta itemprop="worstRating" content="1">
        </div>

        


      </div>
      <div class="col-sm">
        <p>
         <a style="color:#fff" routerLink="{{getlinkforcurrlanguage('privacy-statement')}}">{{'footer.privacy-statement' | translate}}</a>
        </p>
      </div>
    </div>
  </div>
</footer>