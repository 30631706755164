<app-loader></app-loader>
<app-header *ngIf="!isLoading"></app-header>
<router-outlet *ngIf="!isLoading"></router-outlet>
<app-footer *ngIf="!isLoading"></app-footer>


<div *ngIf="!cookieDismiss" class="container-fluid cookie_directive_container">
    <nav class="navbar navbar-default navbar-fixed-bottom">
        <div class="container">
            <div class="navbar-inner navbar-content-center">
                <p class="credit"> 
                    {{'global.cookie_text'|translate}}
                    <a (click)="cookiedismiss()" routerLink="{{getlinkforcurrlanguage('privacy-statement')}}"> {{'global.more_information'|translate}}.</a>
                </p>
                <button class="btn btn-success btn-md btn-block" (click)="cookiedismiss()">{{'close-modal.sluit-button-titel' | translate}}</button>
                <br>
            </div>
        </div>
    </nav>
</div>

<a *ngIf="isScrolled" class="scroll-top-button-wrapper"  (click)="goTop()" title="{{'home.ga-naar-boven'|translate}}">
    <i class="icon-angle-up"></i>
</a>
