import { SharedDataService } from './../shared/services/shared-data.service';
import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { RestaurantFactory } from '../shared/models/restaurantFactory';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {

  public footerCountry="";
  public logomenute = "https://cdn.etenonline.be/static/images/footer_logo_menute_be.png";

  constructor(@Inject(PLATFORM_ID) private _platformId: Object, public resFactory: RestaurantFactory, public shareddataservice: SharedDataService) {
    
    if(this.shareddataservice.baseurl.endsWith(".nl") && this.resFactory.restaurant.countryId==1){
      this.footerCountry=", België";
    }
    else if(!this.shareddataservice.baseurl.endsWith(".nl") && this.resFactory.restaurant.countryId==2){
      this.footerCountry=", Nederland";
    }

    if(this.shareddataservice.baseurl.endsWith(".nl")){
      this.logomenute = "https://cdn.etenonline.be/static/images/footer_logo_menute_nl.png";
    }
  
  }

  ngOnInit() {
  }

  public concatStrings(str1: string, str2: string): string {
    return str1 + " " + str2;
  }


  getlinkforcurrlanguage(page:string){

    return this.shareddataservice.getlinkforcurrlanguage(page, this.resFactory.restaurant.lang);

    
  }
  // public getShareFacebookLink(): string {
  //   if (!isPlatformServer(this._platformId)) {
  //     return "http://www.facebook.com/sharer.php?u= " + window.location.origin;
  //   } else {
  //     return "";
  //   }
  // }

  // public getShareTwitterLink(): string {
  //   if (!isPlatformServer(this._platformId)) {
  //     return "https://twitter.com/share?url=" + window.location.origin + "&amp;text=Het%20gaat%20smaken&amp;hashtags=LetsOrderFood";
  //   } else {
  //     return "";
  //   }
  // }
}
