<main *ngIf="restaurant != null">
  <div class="container-fluid hero" *ngIf="(!searchapproved()) && ((strippednote_public.length > 0) || ((restaurant.slogan != '') && (restaurant.slogan != null)))">
      <div class="col-sm-12 txt-block" *ngIf="(restaurant.slogan != '') && (restaurant.slogan != null)">
          <div class="middle">
              {{restaurant.slogan}}
          </div>
        </div>
    <div class="container intro" *ngIf="strippednote_public.length > 0">
      <div class="restaurantnotice">{{restaurant.note_public}}</div>
    </div>
  </div>
  <div class="container-fluid bestelling">
    <div class="container" #categoryList>
      <h2  *ngIf="!searchapproved()">{{'home.ons-menu-label' | translate}} <br *ngIf="restaurant.disc > 0"> <div *ngIf="restaurant.disc > 0" class="korting-headerh">{{'home.algemeen-korting-online-bestellingen-label' | translate: {kortingProcent: restaurant.disc | number : '1.0-0'} }}</div> </h2>

      <nav class="navbar-expand-md navbar-light nav-category" #navbarCategory>
          <div class="menu-category-bar-container">
        <div class="menu-category-bar">
        <div class="menu-category-search">
          <i (click)="editSearch()" class="icon-search menu-category-search-icon"></i>
          <div class="menu-category-search-form">
          <label class="menu-category-search-label">  <input aria-label="Search" #search maxlength="25" class="menu-category-search-input" [(ngModel)]="searchText" placeholder="{{'home.zoek-naar-gerechten' | translate}}">
            </label>
          </div>
          <i *ngIf="searchText.length>0" (click)="removeSearchText()" class="icon-cancel menu-category-search-close-icon"></i>
        </div>
                  <!-- Cart -->
<div class="btn-group cart" (click)="calculateSubtotal()"  style="margin-bottom: 5px;">
    <button class="btn btn-secondary cart-btn" [ngClass]="{'basket-card-color-blue': isNewBasketItemAdded}"
      type="button" (click)="checkCartModalToOpen()">
      <span id="aantal" class="number">{{basketFactory.totalCountOfItems}}</span>
      <i class="icon-shopping-basket"></i>
      <span *ngIf="basketList.length > 0">€ {{ basketFactory.totalPrice | number : '1.2-2'}}</span>
    </button>
  </div>
        </div>
        </div>

        <ng-container *ngIf="isMobileView">
        <select *ngIf="!searchapproved()" class="navbar-toggler navbar-toggler-right categories-select" aria-label="Categories" [(ngModel)]="selectedSearchOptionForCategory"
          (change)="changeHeightOfPageAfterCategoryItemClicked(selectedSearchOptionForCategory)">
          <option [value]="undefined" hidden disabled>{{'home.kies-categorie-label' | translate}}</option>
          <option *ngFor="let cat of restaurant.categories_subsite" [value]="cat.id">
            <a href="{{ '#' + cat.category_name}}">{{cat.category_name}}</a>
          </option>
        </select>
      </ng-container>


        <ng-container *ngIf="!isMobileView">
        <div *ngIf="!searchapproved()" class="collapse navbar-collapse ">
          <!-- scrollable-height -->

          <ul class="btns-group">
            <li *ngFor="let cat of restaurant.categories_subsite; let index = index" [class.active]="index == 0" class="cursor-pointer" id="bc-{{cat.id}}">
              <div (click)="changeHeightOfPageAfterCategoryItemClicked(cat.id)" title="{{cat.category_name}}">
                  <!-- href="{{ '/menu#' + cat.category_name}}"  -->
                <div style="color: white;">{{cat.category_name}}</div>
              </div>
            </li>
          </ul>

        </div>
      </ng-container>
      </nav>

<div  #menulist>
      <div *ngFor="let menu of menuList  | filtercategories : searchText" id="cat-{{menu.catId}}" [title]="menu.catName">
        <ng-container *ngIf="(menu.catId==0) && (!searchapproved())">
        <div class="catheader">
        <h3>{{menu.catName}}</h3>
        </div>
        <div class="menu-row">
            <div  *ngFor="let product of restaurant.favorite_menus" (click)="check(product,product.category_name)" class="menu-item" itemscope="" itemtype="http://schema.org/Product">
              <div class="row">
                  <div class="menu-item-inner">
                    <div class="meal-image" *ngIf="product.menu_image && product.menu_image != ''">
                      <img itemprop="image" [lazyLoad]="CDN_URL+MENU_PATH+restaurant.id+'-'+restaurant.restaurant_slug+'/'+product.menu_image" alt="{{product.menu_name}}">
                    </div>
                <div class="menu-item-name">
                  <span class="title" itemprop="name">[{{product.category_name}}] {{product.menu_name}}</span>
                </div>
                <div *ngIf="basketFactory.userSelectedPickupMethod; else notPickupFav" class="menu-item-add-btn" itemprop="offers" itemscope="" itemtype="http://schema.org/Offer">
                  <div *ngIf="product.menugroups <= 0" class="btn btn-more" ><span itemprop="priceCurrency" content="EUR">€ </span> <span itemprop="price" [attr.content]="product.toPrice" >{{product.toPrice}} <i class="icon-plus" aria-hidden="true"></i></span></div>
                  <div *ngIf="product.menugroups > 0" class="btn btn-more" ><span itemprop="priceCurrency" content="EUR">€ </span> <span itemprop="price" [attr.content]="product.toPrice">{{product.toPrice}} <i class="icon-ellipsis" aria-hidden="true"></i></span> </div>
                </div>
                <ng-template #notPickupFav>
                  <div class="menu-item-add-btn" itemprop="offers" itemscope="" itemtype="http://schema.org/Offer">
                    <div *ngIf="product.menugroups <= 0" class="btn btn-more" ><span itemprop="priceCurrency" content="EUR">€ </span> <span itemprop="price" [attr.content]="product.price" >{{product.price}} <i class="icon-plus" aria-hidden="true"></i></span></div>
                    <div *ngIf="product.menugroups > 0" class="btn btn-more" ><span itemprop="priceCurrency" content="EUR">€ </span> <span itemprop="price" [attr.content]="product.price">{{product.price}} <i class="icon-ellipsis" aria-hidden="true"></i></span> </div>
                  </div>
                </ng-template>
                  </div>
                <div class="menu-item-inner">
              <div class="menu-item-desc">
                <span *ngIf="(product.description != null) && (product.description.length > 0)" class="ingredient" itemprop="description">
                  {{product.description}}
                </span>
              </div>

            </div>
            </div>

            </div>
        </div>
      </ng-container>
          <!-- item -->
          <ng-container *ngIf="menu.catId>0">
          <div class="catheader">
          <h3>{{menu.catName}}</h3>
          <img class="cat-img" [lazyLoad]="menu.imageurl" alt="{{menu.catName}}">
          <h4 class="category-desc" *ngIf="menu.catDesc != null">{{menu.catDesc}}</h4>
          </div>
          <div class="menu-row">
          <ng-container *ngFor="let product of menu.products" >
          <div *ngIf="(searchapproved() && showbyfilter(product,menu.catName)) || (!searchapproved())" (click)="check(product, menu.catName)" class="menu-item" itemscope="" itemtype="http://schema.org/Product">
            <div class="row">
              <div class="menu-item-inner">
                <div class="meal-image" *ngIf="product.menu_image && product.menu_image != ''">
                  <img itemprop="image" [lazyLoad]="CDN_URL+MENU_PATH+restaurant.id+'-'+restaurant.restaurant_slug+'/'+product.menu_image" alt="{{product.menu_name}}">
                </div>
                  <div class="menu-item-name">
                      <span class="title" itemprop="name">{{product.menu_name}}</span>
                    </div>
            <div *ngIf="basketFactory.userSelectedPickupMethod; else notPickup" class="menu-item-add-btn" itemprop="offers" itemscope="" itemtype="http://schema.org/Offer">
              <div class="btn btn-more">
                <span itemprop="priceCurrency" content="EUR">€ </span> <span itemprop="price" [attr.content]="product.toPrice" >{{product.toPrice}} <i [ngClass]="{'icon-ellipsis':product.menugroups > 0,'icon-plus':product.menugroups <= 0}" aria-hidden="true"></i></span>
              </div>
            </div>
						<ng-template #notPickup>
							<div class="menu-item-add-btn" itemprop="offers" itemscope="" itemtype="http://schema.org/Offer">
								<div class="btn btn-more">
									<span itemprop="priceCurrency" content="EUR">€ </span> <span itemprop="price" [attr.content]="product.price" >{{product.price}} <i [ngClass]="{'icon-ellipsis':product.menugroups > 0,'icon-plus':product.menugroups <= 0}" aria-hidden="true"></i></span>
								</div>
							</div>
						</ng-template>
          </div>
          <div class="menu-item-desc" [id]="product.id">
              <span *ngIf="(product.description != null) && (product.description.length > 0)" class="ingredient" itemprop="description">
                {{product.description}}
              </span>
            </div>
          </div>

          </div>
        </ng-container>
        </div>
      </ng-container>
      </div>
      <div *ngIf="searchText && searchText.length>1 &&((menuList  | filtercategories : searchText).length==0)" class="no_products_found">
          <i (click)="removeSearchText()" class="icon-search no_products_found_icon"></i>
          <h3 class="no_products_found_text">{{'home.geen-product-gevonden-label' | translate}}</h3>
          <button (click)="removeSearchText()" class="reset-product-search">{{'home.zoekopdracht-wissen-label' | translate}}</button>
      </div>
    </div>
    </div>
  </div>
</main>
