<main>
    <div class="container hero">
        <h1>{{'privacybeleid.title'|translate}}</h1>
        <div *ngIf="shareddataservice.langpath=='nl'; else elseBlock">

            Deze pagina is voor het laatst aangepast op 23 juli 2021.

            <p>
                Hieronder vind je het privacybeleid, het cookiebeleid en de voorwaarden van Besteller BVBA (hierna
                Menute of ‘wij/ons/onze’) beschreven, met maatschappelijke zetel te Guido Gezellelaan 122 bus 1,
                3550 Heusden-Zolder. Je kan ons bereiken op het telefoonnummer 011 361 900 of door een email te sturen
                naar info@menute.be.
            </p>

            <p><strong>Artikel 1 – Algemeen</strong></p>
            <p>1.1   BESTELLER BVBA, handelend onder de benaming MENUTE (hierna genoemd MENUTE ) met
                maatschappelijke zetel te 3550 Heusden-Zolder, G. Gezellelaan 122 bus 1, is ingeschreven in de
                Kruispuntbank van Ondernemingen (KBO) onder het nummer 0683.531.086. MENUTE is bereikbaar via haar
                website: www.menute.be. </p>
            <p>1.2   Enkel deze voorwaarden zijn van toepassing op de relatie tussen MENUTE en de Besteller, die bij
                zijn Bestelling erkent deze te aanvaarden. Indien er uitdrukkelijk schriftelijk de voorkeur gegeven
                wordt aan een afwijking van deze voorwaarden of aan een bijzondere overeenkomst, blijven deze
                voorwaarden minstens op aanvullende wijze gelden. </p>
            <p>1.3   Deze voorwaarden zijn enkel van toepassing op de Diensten. MENUTE is niet verantwoordelijk voor
                het Aanbod van de Restaurants. Op het Aanbod zijn de algemene voorwaarden van het Restaurant van
                toepassing. </p>
            <p><strong>Artikel 2 – Definities</strong></p>
            <p>2.1   In deze Algemene Voorwaarden betekent:</p>
            <p>         -     Aanbod: de producten en diensten van het Restaurant die de Besteller via de Omgeving bij
                het Restaurant kan bestellen; </p>
            <p>-     Bestelling: de bestelling door een Besteller bij het Restaurant in het kader van een overeenkomst;
            </p>
            <p>-     Besteller: een natuurlijke of rechtspersoon die via de Omgeving van MENUTE een bestelling
                plaatst bij een restaurant;</p>
            <p>-     Diensten: de diensten die door MENUTE worden aangeboden aan de Besteller, onder andere
                bestaande uit het publiceren van het Aanbod, het totstandkomen van de Overeenkomst en het overmaken van
                de Bestellingen aan de Restaurants; </p>
            <p>-     Overeenkomst:               de overeenkomst tussen de Besteller en het Restaurant voor wat betreft
                de bestelling, bezorging en/of het afhalen van maaltijden, dranken en aanverwante producten;</p>
            <p>-     Omgeving : de websites, apps en andere kanalen van MENUTE en aan haar partners, waarop de
                diensten toegankelijk worden gemaakt;</p>
            <p>-     Restaurant: het bedrijf dat de door de besteller bestelde maaltijden, dranken en aanverwante
                producten bereidt en beroep doet op MENUTE voor wat betreft de totstandkoming van de overeenkomst en
                de diensten, de betaling ervan en de Financiële Diensten gebruikt;</p>
            <p><strong>Artikel 3 - Totstandkoming van de Overeenkomst</strong></p>
            <p>3.1   De Overeenkomst komt tot stand wanneer de Besteller via de Omgeving van MENUTE een bestelling
                plaatst en daarbij het aanbod van het Restaurant heeft aanvaard. Het Restaurant is verantwoordelijk voor
                de nakoming van de Overeenkomst. MENUTE is geen partij bij de Overeenkomst. Het Restaurant machtigt
                MENUTE om namens haar Overeenkomsten te sluiten. MENUTE handelt bij het sluiten van deze
                Overeenkomsten in naam van het Restaurant.  </p>
            <p>3.2   Bestellingen zijn slechts geldig indien zij schriftelijk door MENUTE zijn bevestigd. </p>
            <p>3.2   De Overeenkomst kan slechts door het Restaurant worden uitgevoerd, indien het Restaurant beschikt
                over de correcte en volledige contactgegevens van de Besteller. De Besteller dient deze in te vullen
                tijdens de Bestelling en het dient onjuistheden of onvolledigheden onverwijld aan MENUTE en het
                Restaurant te melden. </p>
            <p>3.3   MENUTE publiceert het Aanbod van de Restaurants op haar Omgeving, overeenkomstig de informatie
                die de Restaurants hebben aangeleverd. MENUTE is niet verantwoordelijk, noch aansprakelijk voor de
                inhoud van het Aanbod en van de informatie van de Restaurants op de Omgeving. </p>
            <p>3.4   MENUTE publiceert op haar Omgeving de informatie van het Restaurant die noodzakelijk is in het
                kader van de Bestelling op een wijze waarop de informatie voor de Besteller duidelijk leesbaar en
                begrijpbaar is.  </p>
            <p>3.5   MENUTE beschouwt als Besteller elke natuurlijke of rechtspersoon die de Bestelling via de
                Omgeving heeft geplaatst, tenzij er uitdrukkelijk werd meegedeeld dat men in opdracht handelt van een
                derde en op voorwaarde dat de naam, het adres en het ondernemingsnummer van deze derde op datzelfde
                moment wordt meegedeeld aan MENUTE. Degene die een Bestelling plaatste voor een rechtspersoon,
                verbindt zich solidair met de rechtspersoon in wiens naam hij handelt. </p>
            <p><strong>Artikel 4 – Uitvoering – Levering </strong></p>
            <p>4.1   MENUTE besteedt de vereiste zorg aan de uitvoering van de aan haar toevertrouwde opdrachten en
                zal de bestellingen die via haar Omgeving worden geplaatst onverwijld doorgeven aan het Restaurant.
                Vertraging in de uitvoering kan nooit aanleiding geven tot boete of schadevergoeding, beëindiging van de
                Diensten of weigering om de bestelling in ontvangst te nemen. </p>
            <p>4.2   De Besteller dient na het plaatsen van de Bestelling telefonisch en per e-mail bereikbaar te zijn
                voor het Restaurant en MENUTE voor wat betreft de status van de Bestelling en dit via de door de
                Besteller aangeleverde contactinformatie. </p>
            <p>4.3   Indien de Besteller opteert voor de levering van de Bestelling, dient de Besteller aanwezig te zijn
                op het adres dat de Besteller heeft aangeleverd op het moment dat hij de Bestelling plaatste. </p>
            <p>4.4   Indien de Besteller opteert voor het afhalen van de Bestelling, dient hij op het gekozen tijdstip
                aanwezig te zijn op afhaallocatie die door het Restaurant werd doorgegeven op het moment van de
                bevestiging van de Bestelling. </p>
            <p>4.5   MENUTE is niet verantwoordelijk voor de juistheid en de volledigheid van de door de Besteller
                geplaatste bestellingen. </p>
            <p><strong>Artikel 5 – Betaling </strong></p>
            <p>5.1   Op het moment dat de Overeenkomst tot stand is gekomen, ontstaat er een betalingsverplichting van
                de Besteller opzichtens het Restaurant. De Besteller kan haar betalingsverplichting voldoen door middel
                van een online betaling via de Omgeving, dan wel door een betaling aan het Restaurant op het moment van
                de levering of de afhaling. </p>
            <p>5.2   Behoudens hetgeen bepaald is in artikel 6.4, is het gedeeltelijk terugboeken van een online
                betaling slechts mogelijk, indien de Bestelling niet (volledig) geleverd kan worden. De terugboeking
                wordt uitgevoerd op dezelfde rekening, als deze waarvan de betaling werd uitgevoerd. </p>
            <p>5.3   Het Restaurant heeft MENUTE gemachtigd om in naam van het Restaurant de online betaling van de
                Besteller in ontvangst te nemen. </p>
            <p><strong>Artikel 6 – Ontbinding van de Overeenkomst en annulering van de Bestelling  </strong></p>
            <p>6.1   De Besteller kan de Bestelling niet annuleren bij MENUTE. De Besteller kan de Bestelling
                slechts annuleren indien het Restaurant uitdrukkelijk aangeeft dat de annulering van de Bestelling door
                de Besteller mogelijk is. </p>
            <p>6.2   Het Restaurant is gerechtigd de bestelling te annuleren in geval van overmacht, indien het Aanbod
                niet beschikbaar is of indien de contactgegevens die door de Besteller zijn doorgegeven niet correct of
                onbereikbaar blijken te zijn. </p>
            <p>6.3   Indien de Besteller een Bestelling plaatst die vals is en waarbij hij niet tot betaling overgaat
                en/of de levering niet in ontvangst neemt of afhaalt of op enige andere wijze zijn verbintenissen niet
                nakomt, behoudt MENUTE zich het recht voor om toekomstige Bestellingen van deze Besteller te
                weigeren.</p>
            <p>6.4   MENUTE behoudt zich het recht voor om namens het Restaurant Bestellingen te weigeren en
                Overeenkomsten te annuleren, indien er twijfel bestaat over de juistheid van de Bestelling. Indien
                MENUTE een Bestelling annuleert die reeds betaald is, dan zal MENUTE dat bedrag overmaken op de
                rekening waarvan de betaling werd uitgevoerd. Indien de Besteller valse of frauduleuze Bestellingen
                plaatst, behoudt MENUTE zich het recht voor om hiervan aangifte te doen bij de politie of het
                parket. </p>
            <p><strong>Artikel 7 – Beoordelingen </strong></p>
            <p>7.1   MENUTE biedt aan de Besteller de mogelijkheid om de Overeenkomst met het Restaurant te
                beoordelen. </p>
            <p>MENUTE behoudt zich het recht voor om de beoordelingen op de Omgeving te publiceren en van de
                Omgeving te verwijderen, zonder dat zij hierover voorafgaand overleg dient te plegen met het Restaurant.
            </p>
            <p>7.2   MENUTE is niet aansprakelijk voor de inhoud van de beoordelingen en de daaruit voortvloeiende
                gevolgen. </p>
            <p><strong>Artikel 8 – Overmacht</strong></p>
            <p>8.1   Indien MENUTE de bestelling niet kan uitvoeren wegens overmacht, waaronder begrepen wordt
                ongevallen, ziekte, brand, oorlog, stakingen, lock-outs, opstanden, vertragingen bij leveranciers,
                gebrek aan vervoermateriaal, enz., heeft MENUTE het recht aan de Diensten een einde te stellen
                zonder enige verdere schadeloosstelling aan de klant.</p>
            <p><strong>Artikel 9 – Klachten en aansprakelijkheid </strong></p>
            <p>9.1   Klachten van de Besteller over het Aanbod, de Bestelling of de uitvoering van de Overeenkomst,
                dienen te worden gericht aan het Restaurant via de in artikel 1 van deze algemene voorwaarden bepaalde
                contactgegevens. MENUTE kan slechts een bemiddelende rol op zich nemen in het kader van de
                klachtenbehandeling. MENUTE in niet verantwoordelijk voor de afwikkeling van klachten van Bestellers
                over de nakoming van de Overeenkomsten. </p>
            <p>9.2   Indien de Besteller klachten heeft over de Diensten, dan dienen deze klachten op straffe van
                verval, schriftelijk te worden gemeld aan MENUTE door middel van een aangetekend schrijven. </p>
            <p>9.3   MENUTE is niet verantwoordelijk, noch aansprakelijk voor de inhoud van het Aanbod en van de
                informatie van de Restaurants op de Omgeving. </p>
            <p>9.4   MENUTE is niet aansprakelijk voor de bereikbaarheid van de Omgeving. </p>
            <p>9.5   MENUTE is niet aansprakelijk voor de uitvoering van de Overeenkomst door het Restaurant. </p>
            <p>9.6   MENUTE is enkel aansprakelijk voor schade die het directe en aantoonbare gevolg is van een aan
                haar toerekenbare tekortkoming. MENUTE kan in geen geval aansprakelijk gesteld worden voor alle
                andere vormen van schade zoals bedrijfsschade, schade door vertraging en gederfde winst. De
                aansprakelijkheid van MENUTE is in elk geval beperkt tot het bedrag ter hoogte van de factuurwaarde
                excl. BTW voor de betreffende opdracht</p>
            <p><strong>Artikel 10 – Intellectuele eigendom</strong></p>
            <p>10.1 De voor of na de totstandkoming van de Overeenkomst aan de Besteller ter hand gestelde documenten
                zijn auteursrechtelijk beschermd en blijven eigendom van MENUTE. Zij mogen zonder toestemming van
                deze laatste niet door de Besteller worden gebruikt, gekopieerd of vermenigvuldigd.</p>
            <p><strong>Artikel 11 – Varia en geschillenregeling</strong></p>
            <p>11.1 Deze algemene voorwaarden doen geen enkele afbreuk aan de uitoefening door MENUTE van alle
                andere haar toekomende wettelijke of contractuele rechten.</p>
            <p>12.2 Het is het restaurant verboden zijn rechten en verplichtingen onder de met MENUTE afgesloten
                Overeenkomst over te dragen zonder de voorafgaandelijke schriftelijke toestemming van MENUTE. Elke
                ongeoorloofde overdracht zal van rechtswege nietig zijn.</p>
            <p>12.3 Een eventueel in gebreke blijven van MENUTE om de uitvoering te eisen van de bepalingen van deze
                algemene voorwaarden, zal geen afstand van of verzaking aan de toepassing van deze of enigerlei andere
                bepaling kunnen impliceren.</p>
            <p>12.4 De ongeldigheid van één of meerdere bepalingen van deze algemene voorwaarden doet geen afbreuk aan
                de toepassing van de andere bepalingen.</p>
            <p>12.5 In de relatie tussen MENUTE en de Besteller is enkel het Belgische recht van toepassing. Elk
                geschil kan door MENUTE worden voorgelegd aan de rechtbanken van het arrondissement Antwerpen,
                afdeling Hasselt onverminderd het recht voor MENUTE om gerechtelijke stappen te ondernemen voor de
                rechtbanken van het rechtsgebied van de klant.</p>


        </div>
        <ng-template #elseBlock>
            <div *ngIf="shareddataservice.langpath=='fr'; else elseBlock2">
                Cette page a été modifiée pour la dernière fois le 23 juillet 2021.

                <p>
                    Hieronder vind je het privacybeleid, het cookiebeleid en de voorwaarden van Besteller BVBA (hierna
                    Menute of ‘wij/ons/onze’) beschreven, met maatschappelijke zetel te Guido Gezellelaan 122 bus 1,
                    3550 Heusden-Zolder. Je kan ons bereiken op het telefoonnummer 011 361 900 of door een email te
                    sturen naar info@menute.be.
                </p>

                <p><strong>Artikel 1 – Algemeen</strong></p>
                <p>1.1   BESTELLER BVBA, handelend onder de benaming MENUTE (hierna genoemd MENUTE ) met
                    maatschappelijke zetel te 3550 Heusden-Zolder, G. Gezellelaan 122 bus 1, is ingeschreven in de
                    Kruispuntbank van Ondernemingen (KBO) onder het nummer 0683.531.086. MENUTE is bereikbaar via
                    haar website: www.menute.be. </p>
                <p>1.2   Enkel deze voorwaarden zijn van toepassing op de relatie tussen MENUTE en de Besteller, die
                    bij zijn Bestelling erkent deze te aanvaarden. Indien er uitdrukkelijk schriftelijk de voorkeur
                    gegeven wordt aan een afwijking van deze voorwaarden of aan een bijzondere overeenkomst, blijven
                    deze voorwaarden minstens op aanvullende wijze gelden. </p>
                <p>1.3   Deze voorwaarden zijn enkel van toepassing op de Diensten. MENUTE is niet verantwoordelijk
                    voor het Aanbod van de Restaurants. Op het Aanbod zijn de algemene voorwaarden van het Restaurant
                    van toepassing. </p>
                <p><strong>Artikel 2 – Definities</strong></p>
                <p>2.1   In deze Algemene Voorwaarden betekent:</p>
                <p>         -     Aanbod: de producten en diensten van het Restaurant die de Besteller via de Omgeving
                    bij het Restaurant kan bestellen; </p>
                <p>-     Bestelling: de bestelling door een Besteller bij het Restaurant in het kader van een
                    overeenkomst;</p>
                <p>-     Besteller: een natuurlijke of rechtspersoon die via de Omgeving van MENUTE een bestelling
                    plaatst bij een restaurant;</p>
                <p>-     Diensten: de diensten die door MENUTE worden aangeboden aan de Besteller, onder andere
                    bestaande uit het publiceren van het Aanbod, het totstandkomen van de Overeenkomst en het overmaken
                    van de Bestellingen aan de Restaurants; </p>
                <p>-     Overeenkomst:               de overeenkomst tussen de Besteller en het Restaurant voor wat
                    betreft de bestelling, bezorging en/of het afhalen van maaltijden, dranken en aanverwante producten;
                </p>
                <p>-     Omgeving : de websites, apps en andere kanalen van MENUTE en aan haar partners, waarop de
                    diensten toegankelijk worden gemaakt;</p>
                <p>-     Restaurant: het bedrijf dat de door de besteller bestelde maaltijden, dranken en aanverwante
                    producten bereidt en beroep doet op MENUTE voor wat betreft de totstandkoming van de
                    overeenkomst en de diensten, de betaling ervan en de Financiële Diensten gebruikt;</p>
                <p><strong>Artikel 3 - Totstandkoming van de Overeenkomst</strong></p>
                <p>3.1   De Overeenkomst komt tot stand wanneer de Besteller via de Omgeving van MENUTE een
                    bestelling plaatst en daarbij het aanbod van het Restaurant heeft aanvaard. Het Restaurant is
                    verantwoordelijk voor de nakoming van de Overeenkomst. MENUTE is geen partij bij de
                    Overeenkomst. Het Restaurant machtigt MENUTE om namens haar Overeenkomsten te sluiten.
                    MENUTE handelt bij het sluiten van deze Overeenkomsten in naam van het Restaurant.  </p>
                <p>3.2   Bestellingen zijn slechts geldig indien zij schriftelijk door MENUTE zijn bevestigd. </p>
                <p>3.2   De Overeenkomst kan slechts door het Restaurant worden uitgevoerd, indien het Restaurant
                    beschikt over de correcte en volledige contactgegevens van de Besteller. De Besteller dient deze in
                    te vullen tijdens de Bestelling en het dient onjuistheden of onvolledigheden onverwijld aan
                    MENUTE en het Restaurant te melden. </p>
                <p>3.3   MENUTE publiceert het Aanbod van de Restaurants op haar Omgeving, overeenkomstig de
                    informatie die de Restaurants hebben aangeleverd. MENUTE is niet verantwoordelijk, noch
                    aansprakelijk voor de inhoud van het Aanbod en van de informatie van de Restaurants op de Omgeving.
                </p>
                <p>3.4   MENUTE publiceert op haar Omgeving de informatie van het Restaurant die noodzakelijk is in
                    het kader van de Bestelling op een wijze waarop de informatie voor de Besteller duidelijk leesbaar
                    en begrijpbaar is.  </p>
                <p>3.5   MENUTE beschouwt als Besteller elke natuurlijke of rechtspersoon die de Bestelling via de
                    Omgeving heeft geplaatst, tenzij er uitdrukkelijk werd meegedeeld dat men in opdracht handelt van
                    een derde en op voorwaarde dat de naam, het adres en het ondernemingsnummer van deze derde op
                    datzelfde moment wordt meegedeeld aan MENUTE. Degene die een Bestelling plaatste voor een
                    rechtspersoon, verbindt zich solidair met de rechtspersoon in wiens naam hij handelt. </p>
                <p><strong>Artikel 4 – Uitvoering – Levering </strong></p>
                <p>4.1   MENUTE besteedt de vereiste zorg aan de uitvoering van de aan haar toevertrouwde opdrachten
                    en zal de bestellingen die via haar Omgeving worden geplaatst onverwijld doorgeven aan het
                    Restaurant. Vertraging in de uitvoering kan nooit aanleiding geven tot boete of schadevergoeding,
                    beëindiging van de Diensten of weigering om de bestelling in ontvangst te nemen. </p>
                <p>4.2   De Besteller dient na het plaatsen van de Bestelling telefonisch en per e-mail bereikbaar te
                    zijn voor het Restaurant en MENUTE voor wat betreft de status van de Bestelling en dit via de
                    door de Besteller aangeleverde contactinformatie. </p>
                <p>4.3   Indien de Besteller opteert voor de levering van de Bestelling, dient de Besteller aanwezig te
                    zijn op het adres dat de Besteller heeft aangeleverd op het moment dat hij de Bestelling plaatste.
                </p>
                <p>4.4   Indien de Besteller opteert voor het afhalen van de Bestelling, dient hij op het gekozen
                    tijdstip aanwezig te zijn op afhaallocatie die door het Restaurant werd doorgegeven op het moment
                    van de bevestiging van de Bestelling. </p>
                <p>4.5   MENUTE is niet verantwoordelijk voor de juistheid en de volledigheid van de door de
                    Besteller geplaatste bestellingen. </p>
                <p><strong>Artikel 5 – Betaling </strong></p>
                <p>5.1   Op het moment dat de Overeenkomst tot stand is gekomen, ontstaat er een betalingsverplichting
                    van de Besteller opzichtens het Restaurant. De Besteller kan haar betalingsverplichting voldoen door
                    middel van een online betaling via de Omgeving, dan wel door een betaling aan het Restaurant op het
                    moment van de levering of de afhaling. </p>
                <p>5.2   Behoudens hetgeen bepaald is in artikel 6.4, is het gedeeltelijk terugboeken van een online
                    betaling slechts mogelijk, indien de Bestelling niet (volledig) geleverd kan worden. De terugboeking
                    wordt uitgevoerd op dezelfde rekening, als deze waarvan de betaling werd uitgevoerd. </p>
                <p>5.3   Het Restaurant heeft MENUTE gemachtigd om in naam van het Restaurant de online betaling van
                    de Besteller in ontvangst te nemen. </p>
                <p><strong>Artikel 6 – Ontbinding van de Overeenkomst en annulering van de Bestelling  </strong></p>
                <p>6.1   De Besteller kan de Bestelling niet annuleren bij MENUTE. De Besteller kan de Bestelling
                    slechts annuleren indien het Restaurant uitdrukkelijk aangeeft dat de annulering van de Bestelling
                    door de Besteller mogelijk is. </p>
                <p>6.2   Het Restaurant is gerechtigd de bestelling te annuleren in geval van overmacht, indien het
                    Aanbod niet beschikbaar is of indien de contactgegevens die door de Besteller zijn doorgegeven niet
                    correct of onbereikbaar blijken te zijn. </p>
                <p>6.3   Indien de Besteller een Bestelling plaatst die vals is en waarbij hij niet tot betaling
                    overgaat en/of de levering niet in ontvangst neemt of afhaalt of op enige andere wijze zijn
                    verbintenissen niet nakomt, behoudt MENUTE zich het recht voor om toekomstige Bestellingen van
                    deze Besteller te weigeren.</p>
                <p>6.4   MENUTE behoudt zich het recht voor om namens het Restaurant Bestellingen te weigeren en
                    Overeenkomsten te annuleren, indien er twijfel bestaat over de juistheid van de Bestelling. Indien
                    MENUTE een Bestelling annuleert die reeds betaald is, dan zal MENUTE dat bedrag overmaken op
                    de rekening waarvan de betaling werd uitgevoerd. Indien de Besteller valse of frauduleuze
                    Bestellingen plaatst, behoudt MENUTE zich het recht voor om hiervan aangifte te doen bij de
                    politie of het parket. </p>
                <p><strong>Artikel 7 – Beoordelingen </strong></p>
                <p>7.1   MENUTE biedt aan de Besteller de mogelijkheid om de Overeenkomst met het Restaurant te
                    beoordelen. </p>
                <p>MENUTE behoudt zich het recht voor om de beoordelingen op de Omgeving te publiceren en van de
                    Omgeving te verwijderen, zonder dat zij hierover voorafgaand overleg dient te plegen met het
                    Restaurant. </p>
                <p>7.2   MENUTE is niet aansprakelijk voor de inhoud van de beoordelingen en de daaruit
                    voortvloeiende gevolgen. </p>
                <p><strong>Artikel 8 – Overmacht</strong></p>
                <p>8.1   Indien MENUTE de bestelling niet kan uitvoeren wegens overmacht, waaronder begrepen wordt
                    ongevallen, ziekte, brand, oorlog, stakingen, lock-outs, opstanden, vertragingen bij leveranciers,
                    gebrek aan vervoermateriaal, enz., heeft MENUTE het recht aan de Diensten een einde te stellen
                    zonder enige verdere schadeloosstelling aan de klant.</p>
                <p><strong>Artikel 9 – Klachten en aansprakelijkheid </strong></p>
                <p>9.1   Klachten van de Besteller over het Aanbod, de Bestelling of de uitvoering van de Overeenkomst,
                    dienen te worden gericht aan het Restaurant via de in artikel 1 van deze algemene voorwaarden
                    bepaalde contactgegevens. MENUTE kan slechts een bemiddelende rol op zich nemen in het kader van
                    de klachtenbehandeling. MENUTE in niet verantwoordelijk voor de afwikkeling van klachten van
                    Bestellers over de nakoming van de Overeenkomsten. </p>
                <p>9.2   Indien de Besteller klachten heeft over de Diensten, dan dienen deze klachten op straffe van
                    verval, schriftelijk te worden gemeld aan MENUTE door middel van een aangetekend schrijven. </p>
                <p>9.3   MENUTE is niet verantwoordelijk, noch aansprakelijk voor de inhoud van het Aanbod en van de
                    informatie van de Restaurants op de Omgeving. </p>
                <p>9.4   MENUTE is niet aansprakelijk voor de bereikbaarheid van de Omgeving. </p>
                <p>9.5   MENUTE is niet aansprakelijk voor de uitvoering van de Overeenkomst door het Restaurant.
                </p>
                <p>9.6   MENUTE is enkel aansprakelijk voor schade die het directe en aantoonbare gevolg is van een
                    aan haar toerekenbare tekortkoming. MENUTE kan in geen geval aansprakelijk gesteld worden voor
                    alle andere vormen van schade zoals bedrijfsschade, schade door vertraging en gederfde winst. De
                    aansprakelijkheid van MENUTE is in elk geval beperkt tot het bedrag ter hoogte van de
                    factuurwaarde excl. BTW voor de betreffende opdracht</p>
                <p><strong>Artikel 10 – Intellectuele eigendom</strong></p>
                <p>10.1 De voor of na de totstandkoming van de Overeenkomst aan de Besteller ter hand gestelde
                    documenten zijn auteursrechtelijk beschermd en blijven eigendom van MENUTE. Zij mogen zonder
                    toestemming van deze laatste niet door de Besteller worden gebruikt, gekopieerd of vermenigvuldigd.
                </p>
                <p><strong>Artikel 11 – Varia en geschillenregeling</strong></p>
                <p>11.1 Deze algemene voorwaarden doen geen enkele afbreuk aan de uitoefening door MENUTE van alle
                    andere haar toekomende wettelijke of contractuele rechten.</p>
                <p>12.2 Het is het restaurant verboden zijn rechten en verplichtingen onder de met MENUTE afgesloten
                    Overeenkomst over te dragen zonder de voorafgaandelijke schriftelijke toestemming van MENUTE.
                    Elke ongeoorloofde overdracht zal van rechtswege nietig zijn.</p>
                <p>12.3 Een eventueel in gebreke blijven van MENUTE om de uitvoering te eisen van de bepalingen van
                    deze algemene voorwaarden, zal geen afstand van of verzaking aan de toepassing van deze of enigerlei
                    andere bepaling kunnen impliceren.</p>
                <p>12.4 De ongeldigheid van één of meerdere bepalingen van deze algemene voorwaarden doet geen afbreuk
                    aan de toepassing van de andere bepalingen.</p>
                <p>12.5 In de relatie tussen MENUTE en de Besteller is enkel het Belgische recht van toepassing. Elk
                    geschil kan door MENUTE worden voorgelegd aan de rechtbanken van het arrondissement Antwerpen,
                    afdeling Hasselt onverminderd het recht voor MENUTE om gerechtelijke stappen te ondernemen voor
                    de rechtbanken van het rechtsgebied van de klant.</p>

            </div>
        </ng-template>

        <ng-template #elseBlock2>
            This page was last modified on 23 July 2021.

            <p>
                Hieronder vind je het privacybeleid, het cookiebeleid en de voorwaarden van Besteller BVBA (hierna
                Menute of ‘wij/ons/onze’) beschreven, met maatschappelijke zetel te Guido Gezellelaan 122 bus 1,
                3550 Heusden-Zolder. Je kan ons bereiken op het telefoonnummer 011 361 900 of door een email te sturen
                naar info@menute.be.
            </p>

            <p><strong>Artikel 1 – Algemeen</strong></p>
            <p>1.1   BESTELLER BVBA, handelend onder de benaming MENUTE (hierna genoemd MENUTE ) met
                maatschappelijke zetel te 3550 Heusden-Zolder, G. Gezellelaan 122 bus 1, is ingeschreven in de
                Kruispuntbank van Ondernemingen (KBO) onder het nummer 0683.531.086. MENUTE is bereikbaar via haar
                website: www.menute.be. </p>
            <p>1.2   Enkel deze voorwaarden zijn van toepassing op de relatie tussen MENUTE en de Besteller, die bij
                zijn Bestelling erkent deze te aanvaarden. Indien er uitdrukkelijk schriftelijk de voorkeur gegeven
                wordt aan een afwijking van deze voorwaarden of aan een bijzondere overeenkomst, blijven deze
                voorwaarden minstens op aanvullende wijze gelden. </p>
            <p>1.3   Deze voorwaarden zijn enkel van toepassing op de Diensten. MENUTE is niet verantwoordelijk voor
                het Aanbod van de Restaurants. Op het Aanbod zijn de algemene voorwaarden van het Restaurant van
                toepassing. </p>
            <p><strong>Artikel 2 – Definities</strong></p>
            <p>2.1   In deze Algemene Voorwaarden betekent:</p>
            <p>         -     Aanbod: de producten en diensten van het Restaurant die de Besteller via de Omgeving bij
                het Restaurant kan bestellen; </p>
            <p>-     Bestelling: de bestelling door een Besteller bij het Restaurant in het kader van een overeenkomst;
            </p>
            <p>-     Besteller: een natuurlijke of rechtspersoon die via de Omgeving van MENUTE een bestelling
                plaatst bij een restaurant;</p>
            <p>-     Diensten: de diensten die door MENUTE worden aangeboden aan de Besteller, onder andere
                bestaande uit het publiceren van het Aanbod, het totstandkomen van de Overeenkomst en het overmaken van
                de Bestellingen aan de Restaurants; </p>
            <p>-     Overeenkomst:               de overeenkomst tussen de Besteller en het Restaurant voor wat betreft
                de bestelling, bezorging en/of het afhalen van maaltijden, dranken en aanverwante producten;</p>
            <p>-     Omgeving : de websites, apps en andere kanalen van MENUTE en aan haar partners, waarop de
                diensten toegankelijk worden gemaakt;</p>
            <p>-     Restaurant: het bedrijf dat de door de besteller bestelde maaltijden, dranken en aanverwante
                producten bereidt en beroep doet op MENUTE voor wat betreft de totstandkoming van de overeenkomst en
                de diensten, de betaling ervan en de Financiële Diensten gebruikt;</p>
            <p><strong>Artikel 3 - Totstandkoming van de Overeenkomst</strong></p>
            <p>3.1   De Overeenkomst komt tot stand wanneer de Besteller via de Omgeving van MENUTE een bestelling
                plaatst en daarbij het aanbod van het Restaurant heeft aanvaard. Het Restaurant is verantwoordelijk voor
                de nakoming van de Overeenkomst. MENUTE is geen partij bij de Overeenkomst. Het Restaurant machtigt
                MENUTE om namens haar Overeenkomsten te sluiten. MENUTE handelt bij het sluiten van deze
                Overeenkomsten in naam van het Restaurant.  </p>
            <p>3.2   Bestellingen zijn slechts geldig indien zij schriftelijk door MENUTE zijn bevestigd. </p>
            <p>3.2   De Overeenkomst kan slechts door het Restaurant worden uitgevoerd, indien het Restaurant beschikt
                over de correcte en volledige contactgegevens van de Besteller. De Besteller dient deze in te vullen
                tijdens de Bestelling en het dient onjuistheden of onvolledigheden onverwijld aan MENUTE en het
                Restaurant te melden. </p>
            <p>3.3   MENUTE publiceert het Aanbod van de Restaurants op haar Omgeving, overeenkomstig de informatie
                die de Restaurants hebben aangeleverd. MENUTE is niet verantwoordelijk, noch aansprakelijk voor de
                inhoud van het Aanbod en van de informatie van de Restaurants op de Omgeving. </p>
            <p>3.4   MENUTE publiceert op haar Omgeving de informatie van het Restaurant die noodzakelijk is in het
                kader van de Bestelling op een wijze waarop de informatie voor de Besteller duidelijk leesbaar en
                begrijpbaar is.  </p>
            <p>3.5   MENUTE beschouwt als Besteller elke natuurlijke of rechtspersoon die de Bestelling via de
                Omgeving heeft geplaatst, tenzij er uitdrukkelijk werd meegedeeld dat men in opdracht handelt van een
                derde en op voorwaarde dat de naam, het adres en het ondernemingsnummer van deze derde op datzelfde
                moment wordt meegedeeld aan MENUTE. Degene die een Bestelling plaatste voor een rechtspersoon,
                verbindt zich solidair met de rechtspersoon in wiens naam hij handelt. </p>
            <p><strong>Artikel 4 – Uitvoering – Levering </strong></p>
            <p>4.1   MENUTE besteedt de vereiste zorg aan de uitvoering van de aan haar toevertrouwde opdrachten en
                zal de bestellingen die via haar Omgeving worden geplaatst onverwijld doorgeven aan het Restaurant.
                Vertraging in de uitvoering kan nooit aanleiding geven tot boete of schadevergoeding, beëindiging van de
                Diensten of weigering om de bestelling in ontvangst te nemen. </p>
            <p>4.2   De Besteller dient na het plaatsen van de Bestelling telefonisch en per e-mail bereikbaar te zijn
                voor het Restaurant en MENUTE voor wat betreft de status van de Bestelling en dit via de door de
                Besteller aangeleverde contactinformatie. </p>
            <p>4.3   Indien de Besteller opteert voor de levering van de Bestelling, dient de Besteller aanwezig te zijn
                op het adres dat de Besteller heeft aangeleverd op het moment dat hij de Bestelling plaatste. </p>
            <p>4.4   Indien de Besteller opteert voor het afhalen van de Bestelling, dient hij op het gekozen tijdstip
                aanwezig te zijn op afhaallocatie die door het Restaurant werd doorgegeven op het moment van de
                bevestiging van de Bestelling. </p>
            <p>4.5   MENUTE is niet verantwoordelijk voor de juistheid en de volledigheid van de door de Besteller
                geplaatste bestellingen. </p>
            <p><strong>Artikel 5 – Betaling </strong></p>
            <p>5.1   Op het moment dat de Overeenkomst tot stand is gekomen, ontstaat er een betalingsverplichting van
                de Besteller opzichtens het Restaurant. De Besteller kan haar betalingsverplichting voldoen door middel
                van een online betaling via de Omgeving, dan wel door een betaling aan het Restaurant op het moment van
                de levering of de afhaling. </p>
            <p>5.2   Behoudens hetgeen bepaald is in artikel 6.4, is het gedeeltelijk terugboeken van een online
                betaling slechts mogelijk, indien de Bestelling niet (volledig) geleverd kan worden. De terugboeking
                wordt uitgevoerd op dezelfde rekening, als deze waarvan de betaling werd uitgevoerd. </p>
            <p>5.3   Het Restaurant heeft MENUTE gemachtigd om in naam van het Restaurant de online betaling van de
                Besteller in ontvangst te nemen. </p>
            <p><strong>Artikel 6 – Ontbinding van de Overeenkomst en annulering van de Bestelling  </strong></p>
            <p>6.1   De Besteller kan de Bestelling niet annuleren bij MENUTE. De Besteller kan de Bestelling
                slechts annuleren indien het Restaurant uitdrukkelijk aangeeft dat de annulering van de Bestelling door
                de Besteller mogelijk is. </p>
            <p>6.2   Het Restaurant is gerechtigd de bestelling te annuleren in geval van overmacht, indien het Aanbod
                niet beschikbaar is of indien de contactgegevens die door de Besteller zijn doorgegeven niet correct of
                onbereikbaar blijken te zijn. </p>
            <p>6.3   Indien de Besteller een Bestelling plaatst die vals is en waarbij hij niet tot betaling overgaat
                en/of de levering niet in ontvangst neemt of afhaalt of op enige andere wijze zijn verbintenissen niet
                nakomt, behoudt MENUTE zich het recht voor om toekomstige Bestellingen van deze Besteller te
                weigeren.</p>
            <p>6.4   MENUTE behoudt zich het recht voor om namens het Restaurant Bestellingen te weigeren en
                Overeenkomsten te annuleren, indien er twijfel bestaat over de juistheid van de Bestelling. Indien
                MENUTE een Bestelling annuleert die reeds betaald is, dan zal MENUTE dat bedrag overmaken op de
                rekening waarvan de betaling werd uitgevoerd. Indien de Besteller valse of frauduleuze Bestellingen
                plaatst, behoudt MENUTE zich het recht voor om hiervan aangifte te doen bij de politie of het
                parket. </p>
            <p><strong>Artikel 7 – Beoordelingen </strong></p>
            <p>7.1   MENUTE biedt aan de Besteller de mogelijkheid om de Overeenkomst met het Restaurant te
                beoordelen. </p>
            <p>MENUTE behoudt zich het recht voor om de beoordelingen op de Omgeving te publiceren en van de
                Omgeving te verwijderen, zonder dat zij hierover voorafgaand overleg dient te plegen met het Restaurant.
            </p>
            <p>7.2   MENUTE is niet aansprakelijk voor de inhoud van de beoordelingen en de daaruit voortvloeiende
                gevolgen. </p>
            <p><strong>Artikel 8 – Overmacht</strong></p>
            <p>8.1   Indien MENUTE de bestelling niet kan uitvoeren wegens overmacht, waaronder begrepen wordt
                ongevallen, ziekte, brand, oorlog, stakingen, lock-outs, opstanden, vertragingen bij leveranciers,
                gebrek aan vervoermateriaal, enz., heeft MENUTE het recht aan de Diensten een einde te stellen
                zonder enige verdere schadeloosstelling aan de klant.</p>
            <p><strong>Artikel 9 – Klachten en aansprakelijkheid </strong></p>
            <p>9.1   Klachten van de Besteller over het Aanbod, de Bestelling of de uitvoering van de Overeenkomst,
                dienen te worden gericht aan het Restaurant via de in artikel 1 van deze algemene voorwaarden bepaalde
                contactgegevens. MENUTE kan slechts een bemiddelende rol op zich nemen in het kader van de
                klachtenbehandeling. MENUTE in niet verantwoordelijk voor de afwikkeling van klachten van Bestellers
                over de nakoming van de Overeenkomsten. </p>
            <p>9.2   Indien de Besteller klachten heeft over de Diensten, dan dienen deze klachten op straffe van
                verval, schriftelijk te worden gemeld aan MENUTE door middel van een aangetekend schrijven. </p>
            <p>9.3   MENUTE is niet verantwoordelijk, noch aansprakelijk voor de inhoud van het Aanbod en van de
                informatie van de Restaurants op de Omgeving. </p>
            <p>9.4   MENUTE is niet aansprakelijk voor de bereikbaarheid van de Omgeving. </p>
            <p>9.5   MENUTE is niet aansprakelijk voor de uitvoering van de Overeenkomst door het Restaurant. </p>
            <p>9.6   MENUTE is enkel aansprakelijk voor schade die het directe en aantoonbare gevolg is van een aan
                haar toerekenbare tekortkoming. MENUTE kan in geen geval aansprakelijk gesteld worden voor alle
                andere vormen van schade zoals bedrijfsschade, schade door vertraging en gederfde winst. De
                aansprakelijkheid van MENUTE is in elk geval beperkt tot het bedrag ter hoogte van de factuurwaarde
                excl. BTW voor de betreffende opdracht</p>
            <p><strong>Artikel 10 – Intellectuele eigendom</strong></p>
            <p>10.1 De voor of na de totstandkoming van de Overeenkomst aan de Besteller ter hand gestelde documenten
                zijn auteursrechtelijk beschermd en blijven eigendom van MENUTE. Zij mogen zonder toestemming van
                deze laatste niet door de Besteller worden gebruikt, gekopieerd of vermenigvuldigd.</p>
            <p><strong>Artikel 11 – Varia en geschillenregeling</strong></p>
            <p>11.1 Deze algemene voorwaarden doen geen enkele afbreuk aan de uitoefening door MENUTE van alle
                andere haar toekomende wettelijke of contractuele rechten.</p>
            <p>12.2 Het is het restaurant verboden zijn rechten en verplichtingen onder de met MENUTE afgesloten
                Overeenkomst over te dragen zonder de voorafgaandelijke schriftelijke toestemming van MENUTE. Elke
                ongeoorloofde overdracht zal van rechtswege nietig zijn.</p>
            <p>12.3 Een eventueel in gebreke blijven van MENUTE om de uitvoering te eisen van de bepalingen van deze
                algemene voorwaarden, zal geen afstand van of verzaking aan de toepassing van deze of enigerlei andere
                bepaling kunnen impliceren.</p>
            <p>12.4 De ongeldigheid van één of meerdere bepalingen van deze algemene voorwaarden doet geen afbreuk aan
                de toepassing van de andere bepalingen.</p>
            <p>12.5 In de relatie tussen MENUTE en de Besteller is enkel het Belgische recht van toepassing. Elk
                geschil kan door MENUTE worden voorgelegd aan de rechtbanken van het arrondissement Antwerpen,
                afdeling Hasselt onverminderd het recht voor MENUTE om gerechtelijke stappen te ondernemen voor de
                rechtbanken van het rechtsgebied van de klant.</p>

        </ng-template>
    </div>
</main>