import { Component, OnInit, ViewChild, ElementRef, PLATFORM_ID, Inject  } from '@angular/core';
import { RestaurantFactory } from '../shared/models/restaurantFactory';
import { environment } from '../../environments/environment';
import { isPlatformServer } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { SharedDataService } from '../shared/services/shared-data.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  @ViewChild('navbarToggler', { static: true }) navbarToggler:ElementRef;

  public title: string = "";


  constructor(@Inject(PLATFORM_ID) private _platformId: Object, public resFactory: RestaurantFactory, private _translate: TranslateService,
  public shareddataservice: SharedDataService) {
    // if (!isPlatformServer(this._platformId)) {
    //   console.log("this._activatedRoute.url: "+ window.location.pathname);
    // }
    if((resFactory.restaurant.restaurant_name != undefined) && (resFactory.restaurant.restaurant_city)){
      this.title =   resFactory.restaurant.restaurant_name +   ((resFactory.restaurant.restaurant_name.toLowerCase().indexOf(resFactory.restaurant.restaurant_city.toLowerCase()) !== -1) ?"":(" " + resFactory.restaurant.restaurant_city));
    }
  }

  ngOnInit() {


    // var restLang = this.resFactory.restaurant.lang;
    // //console.log("restlang is: " + restLang);
    // if (restLang == "nl" || restLang == "en" || restLang == "fr") {
    //   //console.log("set default lang to restlang: " + restLang);
    //   this._translate.setDefaultLang(restLang);
    //   this.usedLanguage = restLang;
    // } else {
    //   //console.log("restlang is not nl, fr, en");
    //   //this._translate.setDefaultLang("nl");
    // }

    // if (!isPlatformServer(this._platformId)) {
    //   var userLang = localStorage.getItem("userLang");
    //   //console.log("set lang to userLang: " + userLang);

    //   if (userLang == "nl" || userLang == "en" || userLang == "fr") {
    //     //console.log("set lang to userLang: " + userLang);

    //     this._translate.use(userLang);
    //     this.usedLanguage = userLang;
    //   }
    // }
  }


  currentpath(){
return isPlatformServer(this._platformId)?('/'+this.shareddataservice.currentUrlPath):window.location.pathname;

  }

    


  public useLanguage(language: string) {
    // //localStorage.setItem("userLang",language);
    // this._translate.use(language);
    
    // this.shareddataservice.langpath=language;

    let currentpath = this.currentpath();


    if (!isPlatformServer(this._platformId)) {
    let ret="";

    
    //console.log("currentpath: "+currentpath);
    if(this.resFactory.restaurant.lang=="en"){

      //console.log('this.resFactory.restaurant.lang=="en"');
      

      if(language=="fr"){
        //console.log('language=="fr"');
        if(['/', '/fr','/nl'].indexOf(currentpath) > -1){
          ret = "/fr";
        }
        else if(['/fr/avis', '/nl/beoordelingen','/reviews'].indexOf(currentpath) > -1){
          ret = "/fr/avis";
        }
        else if(['/contact', '/fr/contact','/nl/contact'].indexOf(currentpath) > -1){
          ret = "/fr/contact";
        }
        else if(['/fr/declaration-de-confidentialite', '/nl/privacy-beleid','/privacy-statement'].indexOf(currentpath) > -1){
          ret = "/fr/declaration-de-confidentialite";
        }
        else if(currentpath.startsWith("/commande-aupres-de/") || currentpath.startsWith("/fr/commande-aupres-de/") || 
                currentpath.startsWith("/ordered-at/") || currentpath.startsWith("/en/ordered-at/") ||
                currentpath.startsWith("/besteld-bij/") || currentpath.startsWith("/nl/besteld-bij/")) {
          ret = "/fr/commande-aupres-de/"+this.resFactory.restaurant.restaurant_slug+"/"+this.shareddataservice.orderguid;
        }
      }
      else if(language=="nl"){
        //console.log('language=="nl"');
        if(['/', '/fr','/nl'].indexOf(currentpath) > -1){
          ret = "/nl/";
        }
        else if(['/fr/avis', '/nl/beoordelingen','/reviews'].indexOf(currentpath) > -1){
          ret = "/nl/beoordelingen";
        }
        else if(['/contact', '/fr/contact','/nl/contact'].indexOf(currentpath) > -1){
          ret = "/nl/contact";
        }
        else if(['/fr/declaration-de-confidentialite', '/nl/privacy-beleid','/privacy-statement'].indexOf(currentpath) > -1){
          ret = "/nl/privacy-beleid";
        }
        else if(currentpath.startsWith("/commande-aupres-de/") || currentpath.startsWith("/fr/commande-aupres-de/") || 
                currentpath.startsWith("/ordered-at/") || currentpath.startsWith("/en/ordered-at/") ||
                currentpath.startsWith("/besteld-bij/") || currentpath.startsWith("/nl/besteld-bij/")) {
          ret = "/nl/besteld-bij/"+this.resFactory.restaurant.restaurant_slug+"/"+this.shareddataservice.orderguid;
        }
      }
      else if(language=="en"){
        //console.log('language=="en"');
        if(['/', '/fr','/nl'].indexOf(currentpath) > -1){
          ret = "/";
        }
        else if(['/fr/avis', '/nl/beoordelingen','/reviews'].indexOf(currentpath) > -1){
          ret = "/reviews";
        }
        else if(['/contact', '/fr/contact','/nl/contact'].indexOf(currentpath) > -1){
          ret = "/contact";
        }
        else if(['/fr/declaration-de-confidentialite', '/nl/privacy-beleid','/privacy-statement'].indexOf(currentpath) > -1){
          ret = "/privacy-statement";
        }
        else if(currentpath.startsWith("/commande-aupres-de/") || currentpath.startsWith("/fr/commande-aupres-de/") || 
                currentpath.startsWith("/ordered-at/") || currentpath.startsWith("/en/ordered-at/") ||
                currentpath.startsWith("/besteld-bij/") || currentpath.startsWith("/nl/besteld-bij/")) {
          ret = "/ordered-at/"+this.resFactory.restaurant.restaurant_slug+"/"+this.shareddataservice.orderguid;
        }
      }


    }
    else if(this.resFactory.restaurant.lang=="nl"){
      //console.log('this.resFactory.restaurant.lang=="nl"');
     

      if(language=="fr"){
        //console.log('language=="fr"');
        if(['/', '/fr','/en'].indexOf(currentpath) > -1){
          ret = "/fr";
        }
        else if(['/fr/avis', '/beoordelingen','/en/reviews'].indexOf(currentpath) > -1){
          ret = "/fr/avis";
        }
        else if(['/contact', '/fr/contact','/en/contact'].indexOf(currentpath) > -1){
          ret = "/fr/contact";
        }
        else if(['/fr/declaration-de-confidentialite', '/privacy-beleid','/en/privacy-statement'].indexOf(currentpath) > -1){
          ret = "/fr/declaration-de-confidentialite";
        }
        else if(currentpath.startsWith("/commande-aupres-de/") || currentpath.startsWith("/fr/commande-aupres-de/") || 
                currentpath.startsWith("/ordered-at/") || currentpath.startsWith("/en/ordered-at/") ||
                currentpath.startsWith("/besteld-bij/") || currentpath.startsWith("/nl/besteld-bij/")) {
          ret = "/fr/commande-aupres-de/"+this.resFactory.restaurant.restaurant_slug+"/"+this.shareddataservice.orderguid;
        }
      }
      else if(language=="nl"){
        //console.log('language=="nl"');
        if(['/', '/fr','/en'].indexOf(currentpath) > -1){
          ret = "/";
        }
        else if(['/fr/avis', '/beoordelingen','/en/reviews'].indexOf(currentpath) > -1){
          ret = "/beoordelingen";
        }
        else if(['/contact', '/fr/contact','/en/contact'].indexOf(currentpath) > -1){
          ret = "/contact";
        }
        else if(['/fr/declaration-de-confidentialite', '/privacy-beleid','/en/privacy-statement'].indexOf(currentpath) > -1){
          ret = "/privacy-beleid";
        }
        else if(currentpath.startsWith("/commande-aupres-de/") || currentpath.startsWith("/fr/commande-aupres-de/") || 
                currentpath.startsWith("/ordered-at/") || currentpath.startsWith("/en/ordered-at/") ||
                currentpath.startsWith("/besteld-bij/") || currentpath.startsWith("/nl/besteld-bij/")) {
          ret = "/besteld-bij/"+this.resFactory.restaurant.restaurant_slug+"/"+this.shareddataservice.orderguid;
        }
      }
      else if(language=="en"){
        //console.log('language=="en"');
        if(['/', '/fr','/en'].indexOf(currentpath) > -1){
          ret = "/en";
        }
        else if(['/fr/avis', '/beoordelingen','/en/reviews'].indexOf(currentpath) > -1){
          ret = "/en/reviews";
        }
        else if(['/contact', '/fr/contact','/en/contact'].indexOf(currentpath) > -1){
          ret = "/en/contact";
        }
        else if(['/fr/declaration-de-confidentialite', '/privacy-beleid','/en/privacy-statement'].indexOf(currentpath) > -1){
          ret = "/en/privacy-statement";
        }
        else if(currentpath.startsWith("/commande-aupres-de/") || currentpath.startsWith("/fr/commande-aupres-de/") || 
                currentpath.startsWith("/ordered-at/") || currentpath.startsWith("/en/ordered-at/") ||
                currentpath.startsWith("/besteld-bij/") || currentpath.startsWith("/nl/besteld-bij/")) {
          ret = "/en/ordered-at/"+this.resFactory.restaurant.restaurant_slug+"/"+this.shareddataservice.orderguid;
        }
      }


    }
    else if(this.resFactory.restaurant.lang=="fr"){
      //console.log('this.resFactory.restaurant.lang=="fr"');
      

      if(language=="fr"){
        //console.log('language=="fr"');
        if(['/', '/nl','/en'].indexOf(currentpath) > -1){
          ret = "/";
        }
        else if(['/avis', '/nl/beoordelingen','/en/reviews'].indexOf(currentpath) > -1){
          ret = "/avis";
        }
        else if(['/contact', '/nl/contact','/en/contact'].indexOf(currentpath) > -1){
          ret = "/contact";
        }
        else if(['/declaration-de-confidentialite', '/nl/privacy-beleid','/en/privacy-statement'].indexOf(currentpath) > -1){
          ret = "/declaration-de-confidentialite";
        }
        else if(currentpath.startsWith("/commande-aupres-de/") || currentpath.startsWith("/fr/commande-aupres-de/") || 
                currentpath.startsWith("/ordered-at/") || currentpath.startsWith("/en/ordered-at/") ||
                currentpath.startsWith("/besteld-bij/") || currentpath.startsWith("/nl/besteld-bij/")) {
          ret = "/commande-aupres-de/"+this.resFactory.restaurant.restaurant_slug+"/"+this.shareddataservice.orderguid;
        }
      }
      else if(language=="nl"){
        //console.log('language=="nl"');
        if(['/', '/nl','/en'].indexOf(currentpath) > -1){
          ret = "/nl";
        }
        else if(['/avis', '/nl/beoordelingen','/en/reviews'].indexOf(currentpath) > -1){
          ret = "/nl/beoordelingen";
        }
        else if(['/contact', '/nl/contact','/en/contact'].indexOf(currentpath) > -1){
          ret = "/nl/contact";
        }
        else if(['/declaration-de-confidentialite', '/nl/privacy-beleid','/en/privacy-statement'].indexOf(currentpath) > -1){
          ret = "/nl/privacy-beleid";
        }
        else if(currentpath.startsWith("/commande-aupres-de/") || currentpath.startsWith("/fr/commande-aupres-de/") || 
                currentpath.startsWith("/ordered-at/") || currentpath.startsWith("/en/ordered-at/") ||
                currentpath.startsWith("/besteld-bij/") || currentpath.startsWith("/nl/besteld-bij/")) {
          ret = "/nl/besteld-bij/"+this.resFactory.restaurant.restaurant_slug+"/"+this.shareddataservice.orderguid;
        }
      }
      else if(language=="en"){
        //console.log('language=="en"');
        if(['/', '/nl','/en'].indexOf(currentpath) > -1){
          ret = "/en";
        }
        else if(['/avis', '/nl/beoordelingen','/en/reviews'].indexOf(currentpath) > -1){
          ret = "/en/reviews";
        }
        else if(['/contact', '/nl/contact','/en/contact'].indexOf(currentpath) > -1){
          ret = "/en/contact";
        }
        else if(['/declaration-de-confidentialite', '/nl/privacy-beleid','/en/privacy-statement'].indexOf(currentpath) > -1){
          ret = "/en/privacy-statement";
        }
        else if(currentpath.startsWith("/commande-aupres-de/") || currentpath.startsWith("/fr/commande-aupres-de/") || 
                currentpath.startsWith("/ordered-at/") || currentpath.startsWith("/en/ordered-at/") ||
                currentpath.startsWith("/besteld-bij/") || currentpath.startsWith("/nl/besteld-bij/")) {
          ret = "/en/ordered-at/"+this.resFactory.restaurant.restaurant_slug+"/"+this.shareddataservice.orderguid;
        }
      }

    }


    return ret;

  }
  else{
    return "";
  }
  }

  navBarTogglerIsVisible() {
    return this.navbarToggler.nativeElement.offsetParent !== null;
  }

  collapseNav() {
    if (this.navBarTogglerIsVisible()) {
    this.navbarToggler.nativeElement.click();
    }
  }


  isIn = false;   // store state
  toggleState() { // click handler
      let bool = this.isIn;
      this.isIn = bool === false ? true : false; 
  }


  getlinkforcurrlanguage(page:string){

    return this.shareddataservice.getlinkforcurrlanguage(page, this.resFactory.restaurant.lang);

    
  }



}
