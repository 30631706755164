  <div class="modal-header">
    <h5 class="modal-title" style="color: dimgray">{{'close-modal.gesloten-titel' | translate}}</h5>
    <button type="button" class="close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {{'close-modal.gesloten-label' | translate: {'name':resFactory.restaurant.restaurant_name } }}
    <br><br>
    <div [innerHTML]="'close-modal.gesloten-description' | translate: {'slug':resFactory.restaurant.restaurant_slug,'tld':tld}"></div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModal()">{{'close-modal.sluit-button-titel' | translate}}</button>
  </div>