import { Component, OnInit, ViewChild, ElementRef, PLATFORM_ID, Inject, Renderer2, HostListener  } from '@angular/core';
import { DataService } from '../shared/services/data.service';
import { Restaurant } from '../shared/models/restaurant.model';
import { environment } from '../../environments/environment';
import { SharedDataService } from '../shared/services/shared-data.service';
import { RestaurantFactory } from '../shared/models/restaurantFactory';
import { BasketFactory } from '../shared/models/basketFactory';

// import * as _ from 'lodash'; //save 70kb
import { CustomMenu } from '../shared/models/customMenu.model';
import { BasketModel } from '../shared/models/basket.model';
import { DeliverZipCode } from '../shared/models/deliverZipCode.model';
import { ZipCodeModalComponent } from '../modals/zip-code-modal/zip-code-modal.component';
import { BsModalService  } from 'ngx-bootstrap/modal';
import { AddItemModalComponent } from '../modals/add-item-modal/add-item-modal.component';
import { ClosedModalComponent } from '../modals/closed-modal/closed-modal.component';
import { BasketListStorageModel } from '../shared/models/basketStorage.model';
import { isPlatformServer } from '../../../node_modules/@angular/common';
import { CartModalComponent } from '../modals/cart-modal/cart-modal.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})


export class HomeComponent implements OnInit {


  @ViewChild('search') searchField: ElementRef;

  public restaurant: Restaurant = new Restaurant();
  public menuList: CustomMenu[] = [];
  public basketList: BasketModel[] = this.basketFactory.basketList;
  public isNewBasketItemAdded = false;
  public restaurantDeliverZipCodes: DeliverZipCode[] = this._resFactory.deliverZipCodes;
  public zipCodePattern: string = environment.ZIP_CODE_PATTERN;
  public selectedSearchOptionForCategory = undefined;
  public isMobileView = true;

  public basketListStorage: BasketListStorageModel[] = [];

  public strippednote_public = '';
  scrollingbyclick= false;


  public searchText='';


  private _accentMap = {
    'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u', 'ü': 'u', 'ö': 'o', 'ç': 'c'
  };


  CDN_URL = environment.CDN_URl;
  CAT_PATH = environment.CAT_PATH;
  MENU_PATH = environment.MENU_PATH;

  private screenSizeForDesktopImage = 1024;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
       this.checkIfMobileView();

       this.scrollEvent(null);
  }


    checkIfMobileView(){

    //console.log(window.innerWidth);
    // if (this.elementCategoryHeaderMobile) {
    //   this.isMobileView = !this.isHidden(this.elementCategoryHeaderMobile.nativeElement);
    // }


    //we gonna use ngIf to prevent huge dom load for mobile and desktop !!
    //sor for mobile only mobile and for desktop only dekstop

    if(window.innerWidth<768){
      //mobile
      this.isMobileView=true;
    }
    else{
      this.isMobileView=false;
    }
  }


  //public test: Number = Math.round(5.5);
  //viewchild to get the page below the navbar of category
  @ViewChild('navbarCategory') private elementNavbarCategory: ElementRef;
  @ViewChild('menulist') private elementMenuList: ElementRef;
  //@ViewChild('categoryheadermobile') private elementCategoryHeaderMobile: ElementRef;

  constructor(@Inject(PLATFORM_ID) private _platformId: Object, private _sharedDataService: SharedDataService, public basketFactory: BasketFactory,
   private _resFactory: RestaurantFactory, private _dataService: DataService, private _modalService: BsModalService, private renderer: Renderer2) {
    this._sharedDataService.restaurantObservable.subscribe(res => {
      this.restaurant = res;
    });

    if (!isPlatformServer(this._platformId)) {
      if (localStorage.getItem('basket') != null && localStorage.getItem('basket') != '') {

        if (this.isJson(localStorage.getItem('basket'))) {

          let basketstorage = JSON.parse(localStorage.getItem('basket'));
          this.readSessionItems();

          if (!Array.isArray(basketstorage)) {
            basketstorage = [basketstorage];
          }

          // new BasketListStorageModel().checkProperties(basketstorage);

          const basketList: BasketModel[] = new BasketListStorageModel().mapBasketStorageModelToBasketModel(basketstorage, this._resFactory.restaurant.menus_subsite, this.basketFactory.userSelectedPickupMethod);

          this.basketFactory.basketList = basketList;
          this.basketList = basketList;
          this.basketFactory.calculateTotalCountOfItems();
          this.calculateSubtotal();
        }
      }
    }
  }

  ngOnInit() {
    
    this.restaurant = this._resFactory.restaurant;

    if (this.restaurant == null || this.restaurant.note_public == null) {
      this.strippednote_public = '';
    } else {
      this.strippednote_public = this.restaurant.note_public.replace(/<[^>]+>/g, '');
    }


    this.groupMenuArray();
    if (this.restaurant.id != undefined) {
      //this.getDeliverZipCodes();
      this.calculateDeliveryPrice();
    }

    if (!isPlatformServer(this._platformId)) {
      //this.readSessionItems();
      this.checkIfMobileView();
      window.addEventListener('scroll', this.scrollEvent, true);
    }
  }


  // isHidden(el) {
  //   let style = window.getComputedStyle(el);
  //   return (style.display === 'none');
  // }

  scrollEvent = (event: any): void => {

    if (!this.scrollingbyclick) {

      //let docviewtop =  (document.documentElement.scrollTop || document.body.scrollTop);
      // let docviewbottom = docviewtop +document.documentElement.offsetHeight;



        let actives = document.getElementsByClassName('active');
        while (actives[0]) {
            actives[0].classList.remove('active');
        }

        for (let key = 0; key < this.restaurant.categories_subsite.length; key++) {
          let s = this.restaurant.categories_subsite[key];

          const categoriedom = document.getElementById('cat-' + s.id);



          if ((categoriedom) && (this.elementNavbarCategory.nativeElement)) {
            const y = categoriedom.getBoundingClientRect();
            const x = this.elementNavbarCategory.nativeElement;

             if (y.top >= ((window.innerHeight * 0.7 ) - x.scrollHeight - y.height)) {

              if (this.isMobileView) {
                this.selectedSearchOptionForCategory = s.id;
              } else {
                const categoriedom2 = document.getElementById('bc-' + s.id);
                if (categoriedom2) {
                    this.renderer.addClass(categoriedom2, 'active');
                }
              }





              break;
            }
          }
        }
      }

  }

  ngOnDestroy() {
    if (!isPlatformServer(this._platformId)) {
      window.removeEventListener('scroll', this.scrollEvent, true);
    }
  }

  // public checkforfav(item, catName: string)
  // {
  //   let t = this.menuList.find(i => i.catId === item.menu_cat);

  //   this.check(item, t !=null ? t.catName:catName);

  // }

  public check(item, catName: string) {
    if (this.isRestaurantOpenToday()) {
      if (this.checkDeliveryMethodUserFilledIn()) {
        const basketItem: BasketModel = new BasketModel().mapObjectToBasketModel(item, catName, this.basketFactory.userSelectedPickupMethod);
        if (item.menugroups > 0) {
          this.getItemExtras(basketItem);
        } else {
          this.basketFactory.addItemToCart(basketItem);
          this.basketCartColorBlue();
          this.calculateDeliveryPrice();
          this.sendAddToCartEventToGoogleAnalytics(basketItem);
          this.saveBasketListToLocalStorage(this.basketList);
        }
      } else {
        const modalRef = this._modalService.show(ZipCodeModalComponent,{id:"zip"});
        modalRef.content.isSubmittedSuccesfully.subscribe(res => {
          if (res) {
            this.check(item, catName);
          }
        });
      }
    } else {
      this._modalService.show(ClosedModalComponent,{id:"iclosed"});
      this.sendClosedToGoogleAnalytics();
    }

  }

  public checkCartModalToOpen() {
    if (this.basketFactory.userSelectedPickupMethod == true) {
      this.openCartModal();
      return;
    } else {
      if (this.basketFactory.userZipCode == '') {

        const modalRef = this._modalService.show(ZipCodeModalComponent,{id:"zip"});

        modalRef.content.isSubmittedSuccesfully.subscribe(res => {
          if (res) {
            this.openCartModal();
          }
        });

      } else {
        if (this.restaurantDeliverZipCodes.length > 0) {
          const deliverCostsIndex = this.restaurantDeliverZipCodes.findIndex(w => w.zcZip == this.basketFactory.userZipCode);
          //const deliverCostsIndex = _.findIndex(this.restaurantDeliverZipCodes, w => w.zcZip == this.basketFactory.userZipCode);

          if (deliverCostsIndex >= 0) {
            this.openCartModal();
          } else {
            const modalRef = this._modalService.show(ZipCodeModalComponent,{id:"zip"});

            modalRef.content.isSubmittedSuccesfully.subscribe(res => {
              if (res) {
                this.openCartModal();
              }
            });
          }
        } else {
          this.openCartModal();
        }
      }
    }
  }

  private openCartModal() {

    const initialState={
      restaurant:this.restaurant,
      restaurantDeliverZipCodes:this.restaurantDeliverZipCodes
    }

    this._modalService.show(CartModalComponent,{id:'cart',initialState});
    this.sendOpenCartEventToGoogleAnalytics();
  }

  public calculateSubtotal() {
    let total = 0;
    this.basketFactory.setPrices(0, -1);

    this.basketList.forEach(val=>{
      total = total + (+(val.totalPriceWithExtras * val.count));
    });
    // _.each(this.basketList, (val) => {
    //   total = total + (+(val.totalPriceWithExtras * val.count));
    // });

    this.basketFactory.setPrices(total, -1);

    this.calculateDeliveryPrice();
  }



  public changeHeightOfPageAfterCategoryItemClicked(catid: string) {
    if (catid == undefined) {
      return;
    }

    let catname = '';
    let heightCategoryDiv: number;

    const arr = Array.from(this.elementMenuList.nativeElement.children);
    let item = {} as any;
    arr.forEach(element => {

      item = element;
      if (item.id == 'cat-'+ catid) {

        heightCategoryDiv = item.offsetTop;
        catname = item.title;
      }
    });

    if (catname != '') {
      this.sendSelectCategoryEventToGoogleAnalytics(catname, catid);
    }
    const actives = document.getElementsByClassName('active');
    while (actives[0]) {
        actives[0].classList.remove('active');
    }

    const categoriedomclicked = document.getElementById('bc-' + catid);


    this.scrollingbyclick = true;
    if (heightCategoryDiv == undefined) {
        window.location.href = '#cat-' + catid;
        if ((categoriedomclicked) && (!this.isMobileView)) {
          this.renderer.addClass(categoriedomclicked, 'active');
        }
      setTimeout(() => {
        this.scrollingbyclick = false;
      }, 300);
      return;
    }

    //item.scrollIntoView({ behavior: 'smooth', block: 'start' })

    window.scrollTo({left: window.scrollX, top: (heightCategoryDiv - this.elementNavbarCategory.nativeElement.scrollHeight), behavior: 'smooth'});

    if ((categoriedomclicked) && (!this.isMobileView)) {
      this.renderer.addClass(categoriedomclicked, 'active');
    }
    setTimeout(() => {
      this.scrollingbyclick = false;
    }, 300);
  }

  private calculateDeliveryPrice() {
    const userSelectionMethod = this.basketFactory.userSelectedPickupMethod;
    this.basketFactory.calculateSubForToPriceOrPrice(this._resFactory.restaurant.menus_subsite, userSelectionMethod);

    this.basketFactory.calculateDiscount(+(this.restaurant.disc));
    const priceOfOrder: number = this.basketFactory.subtotalPrice - this.basketFactory.discountPrice;

    if (userSelectionMethod) {
      this.basketFactory.setPrices(-1, 0);
      this.basketFactory.isMinimumPriceReached = true;
    } else {
      const deliverCostsIndex = this.restaurantDeliverZipCodes.findIndex(w => w.zcZip == this.basketFactory.userZipCode);
     // const deliverCostsIndex = _.findIndex(this.restaurantDeliverZipCodes, w => w.zcZip == this.basketFactory.userZipCode);
      if (deliverCostsIndex >= 0) {
        const pivot = this.restaurantDeliverZipCodes[deliverCostsIndex].pivot;
        this.basketFactory.setPrices(-1, +(pivot.delivery_charge));

        if (priceOfOrder < +(pivot.minimum_order)) {
          this.basketFactory.isMinimumPriceReached = false;
        } else {
          this.basketFactory.isMinimumPriceReached = true;
          if (+(pivot.free_above) > 0) {
            if (priceOfOrder >= +(pivot.free_above)) {
              this.basketFactory.setPrices(-1, 0);
            }
        }
        }
      }
    }

    if (!isPlatformServer(this._platformId)) {
      this.saveBasketListToLocalStorage(this.basketFactory.basketList);
    }
  }

  private basketCartColorBlue() {
    this.isNewBasketItemAdded = true;
    setTimeout(() => {
      this.isNewBasketItemAdded = false;
    }, 300);
  }

  private openAddItemModal(item: BasketModel) {

    const initialState={
      product:item,
    }

    const modalRef = this._modalService.show(AddItemModalComponent,{id:'additem',initialState});
    modalRef.content.addedSuccesfully.subscribe(res => {
      if (res == true) {
        this.basketCartColorBlue();
        this.calculateDeliveryPrice();
        this.sendAddToCartEventToGoogleAnalytics(item);
        this.saveBasketListToLocalStorage(this.basketList);
      }
    });
  }


  private sendClosedToGoogleAnalytics() {
    this._sharedDataService.sendGoogleAnalyticsEvent('closed', this.restaurant.id.toString(), '', -1);
  }


  private sendAddToCartEventToGoogleAnalytics(item: BasketModel) {
    let gaCategory: string = 'atc_' + this.restaurant.restaurant_name + '_';

    if (this.basketFactory.userSelectedPickupMethod) {
      gaCategory += this.restaurant.restaurant_postcode;
    } else {
      gaCategory += this.basketFactory.userZipCode;
    }

    gaCategory += '_' + item.catName + '_' + item.productName + '_' + item.count;

    this._sharedDataService.sendGoogleAnalyticsEvent(gaCategory, item.productId, '', -1);
  }

  private sendOpenCartEventToGoogleAnalytics() {
    let gaCategory: string = 'cartv_' + this.restaurant.restaurant_name + '_';

    if (this.basketFactory.userSelectedPickupMethod) {
      gaCategory += this.restaurant.restaurant_postcode;
    } else {
      gaCategory += this.basketFactory.userZipCode;
    }


    gaCategory += '_'+ Math.round(this.basketFactory.totalPrice);

    this._sharedDataService.sendGoogleAnalyticsEvent(gaCategory, this.restaurant.id.toString(), '', -1);
  }


  private sendSelectCategoryEventToGoogleAnalytics(catname: string, catid: string) {
    let gaCategory: string = 'sc_' + catname + '_' + this.restaurant.restaurant_name + '_';

    if (this.basketFactory.userSelectedPickupMethod) {
      gaCategory += this.restaurant.restaurant_postcode;
    } else {
      if (this.basketFactory.userZipCode == null || this.basketFactory.userZipCode == '') {
        gaCategory += this.restaurant.restaurant_postcode;
      } else {
        gaCategory += this.basketFactory.userZipCode;
      }
    }

    this._sharedDataService.sendGoogleAnalyticsEvent(gaCategory, catid, '', -1);
  }

  private getItemExtras(item: BasketModel) {
    this.openAddItemModal(item);
  }

  private groupMenuArray() {

   // const productsByCat = _.groupBy(this.restaurant.menus_subsite, 'menu_cat');
    const productsByCat = this.restaurant.menus_subsite.reduce(function (r, a) {
      r[a.menu_cat] = r[a.menu_cat] || [];
      r[a.menu_cat].push(a);
      return r;
  }, Object.create(null));

    if (this.restaurant.categories_subsite) {
      this.restaurant.categories_subsite.forEach(element => {
        const customMenu: CustomMenu = new CustomMenu();
        customMenu.catId = element.id;
        customMenu.catName = element.category_name;
        customMenu.products = productsByCat[element.id];
        customMenu.catDesc = element.note_public;
        customMenu.imageurl = element.media ? this.getCategoryImage(element.media) : this.CDN_URL + this.CAT_PATH + this.restaurant.firstresttypeslug+'/'+ this.convertToSlug(element.category_name);

        for (const i in customMenu.products) {
          if (customMenu.products[i].menugroups > 0) {
            customMenu.products[i].defaultselected = '';
          }
        }

        this.menuList.push({ ...customMenu });
      });
    }
  }

  private readSessionItems() {
    if (!this.validateZipCode(this.basketFactory.userZipCode)) {
      if (this.validateZipCode(sessionStorage.getItem('zip'))) {
        this.basketFactory.userZipCode = sessionStorage.getItem('zip');
      } else {
        this.basketFactory.userZipCode = '';
      }
    }

    if (!this.basketFactory.userSelectedPickupMethod) {
      if (this.checkStringIsBoolean(localStorage.getItem('pickup'))) {
        this.basketFactory.userSelectedPickupMethod = JSON.parse(localStorage.getItem('pickup'));
      } else {
        this.basketFactory.userSelectedPickupMethod = false;
      }
    }
  }

  // private getDeliverZipCodes() {
  //   this._dataService.getRestaurantDeliverZipCodes(this.restaurant.id).subscribe(res => {
  //     this.restaurantDeliverZipCodes = res;
  //     this._resFactory.deliverZipCodes = res;

  //     this.calculateDeliveryPrice();
  //   })
  // }

  private isRestaurantOpenToday(): boolean {
    if (this.restaurant.isOpenToday == 0) {
      return false;
    } else {
      return true;
    }
  }

  private isRestaurantDeliveringToday(): boolean {
    if (this.restaurant.isDeliveringToday == 0) {
      return false;
    } else {
      return true;
    }
  }

  private checkDeliveryMethodUserFilledIn(): boolean {
    if (!this.isRestaurantDeliveringToday() && !this.basketFactory.userSelectedPickupMethod) {
      return false;
    }
    if (!this.isRestaurantDeliveringToday() && this.basketFactory.userSelectedPickupMethod) {
      return true;
    }

    if (this.restaurantDeliverZipCodes.length === 0) {
      return false;
    } else {
      if (this.basketFactory.userSelectedPickupMethod) {
        return true;
      }

      if (this.validateZipCode(this.basketFactory.userZipCode)) {
        const index = this.restaurantDeliverZipCodes.findIndex(x => x.zcZip == this.basketFactory.userZipCode);
        if (index < 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
  }

  private saveBasketListToLocalStorage(basketlist: BasketModel[]) {
    const item = new BasketListStorageModel().mapBasketModelToBasketStorageModel(basketlist);
    if (item != null) {
      localStorage.setItem('basket', JSON.stringify(item));
    }
  }

  private checkStringIsBoolean(text: string): boolean {
    if (text) {
      if (text.toLowerCase() == 'true' || text.toLowerCase() == 'false') {
        return true;
      } else {
        return false;
      }
    }
  }

  private validateZipCode(zipCode: string): boolean {
    const regexp = new RegExp('^[1-9]{1}[0-9]{3}$');
    return regexp.test(zipCode);
  }

  private isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }


  public showbyfilter(product: any, catName: any) {
    if (this.searchapproved) {

      const s = this.accent_fold(this.searchText.toLowerCase());

      return (this.accent_fold(catName.toLowerCase()).includes(s) || this.accent_fold(product.menu_name.toLowerCase()).includes(s) || (product.description != null && this.accent_fold(product.description.toLowerCase()).includes(s)));
    }
  }

  accent_fold (s) {
    if (!s) { return ''; }
    let ret = '';
    for (let i = 0; i < s.length; i++) {
      ret += this._accentMap[s.charAt(i)] || s.charAt(i);
    }
    return ret;
  }

  editSearch(): void {
    this.searchField.nativeElement.focus();
  }


  searchapproved() {
    if ((!this.searchText) || (this.searchText.length < 2)) {
      return false;
    } else {
      return true;
    }
  }


  removeSearchText() {
    this.searchText = '';
  }


  convertToSlug(Text) {
    return this._sharedDataService.accent_fold(Text.toLowerCase())
    .replace(/ /g,'-')
    .replace(/[^\w-]+/g,'');
  }

  private getCategoryImage(media){
   
    let url = "";

    let urlParts = media?.url?.split('.');
    if(urlParts?.length > 1){
      urlParts[0] = urlParts[0] + '_' + (isPlatformServer(this._platformId)?'mobile' :(window.innerWidth >= this.screenSizeForDesktopImage ? 'desktop' : 'mobile'));
      url = urlParts.join('.');
    }else{
      url = media.url;
    }
    
    return this.CDN_URL+this.CAT_PATH+url;
  
  }

}
