<nav class="navbar navbar-expand-md navbar-light container">
    <div class="fcontainer">
        <div class="fbox fbox1"><span>
          <button #navbarToggler class="navbar-toggler navbar-toggler-right" type="button" aria-label="Menu" (click)="toggleState()">
            <span class="navbar-toggler-icon"></span>
          </button></span></div>
        <div class="fbox fbox2"><span><h1 class="headertitle">{{resFactory.restaurant.calc_title}}</h1></span></div>
        <div class="fbox fbox3"><span> <a class="navbar-brand" href="/"><img *ngIf="resFactory.restaurant != null" [src]="'https://cdn.etenonline.be/upload/restaurants/' + resFactory.restaurant.restaurant_logo + '-b.png'" alt="{{resFactory.restaurant.calc_title}} image" itemprop="image"></a></span></div>
      </div>
  
  
 
  <div class="collapse navbar-collapse justify-content-end" id="navigation" [ngClass]="{ 'show': isIn }">
    <ul class="navbar-nav ">
      <li class="nav-item">
        <a class="nav-link" (click)="collapseNav()" routerLink="{{getlinkforcurrlanguage('home')}}" [class.navactive]="currentpath()===getlinkforcurrlanguage('home')" title="{{'header.title-link-home' | translate: {title: resFactory.restaurant.calc_title} }}">{{'header.bestellen-titel' | translate}}</a>
      </li>
      <li *ngIf="resFactory.restaurant.review_total_cnt>0" class="nav-item">
      <a class="nav-link" (click)="collapseNav()" routerLink="{{getlinkforcurrlanguage('reviews')}}" [class.navactive]="currentpath()===getlinkforcurrlanguage('reviews')" title="{{'header.title-link-beoordelingen' | translate: {title: resFactory.restaurant.calc_title} }}">{{'header.beoordelingen-titel' | translate}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="collapseNav()" routerLink="{{getlinkforcurrlanguage('contact')}}" [class.navactive]="currentpath()===getlinkforcurrlanguage('contact')" title="{{'header.title-link-contact' | translate: {title: resFactory.restaurant.calc_title} }}">{{'header.contact-titel' | translate}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="collapseNav()" [routerLink]="useLanguage(resFactory.restaurant.lang)" [class.navactive]="shareddataservice.langpath==resFactory.restaurant.lang">{{resFactory.restaurant.lang | uppercase }}</a>
      </li>
      <ng-template ngFor let-item [ngForOf]="shareddataservice.avaiblelangs">
        <li class="nav-item" *ngIf="item!=resFactory.restaurant.lang">
          <a class="nav-link" (click)="collapseNav()" [routerLink]="useLanguage(item)" [class.navactive]="shareddataservice.langpath==item">{{item | uppercase }}</a>
        </li>
      </ng-template>
    </ul>
  </div>
</nav>