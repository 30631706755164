import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { BasketModel, ExtrasModel } from '../../shared/models/basket.model';
import { BasketFactory } from '../../shared/models/basketFactory';
import { Restaurant } from '../../shared/models/restaurant.model';
import { SharedDataService } from '../../shared/services/shared-data.service';
import { BasketListStorageModel } from '../../shared/models/basketStorage.model';

import { DeliverZipCode } from '../../shared/models/deliverZipCode.model';
import { DataService } from '../../shared/services/data.service';
import { Order } from '../../shared/models/order.model';
import { isPlatformServer } from '../../../../node_modules/@angular/common';
import { BsModalService  } from 'ngx-bootstrap/modal';
import { ZipCodeModalComponent } from '../zip-code-modal/zip-code-modal.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cart-modal',
  templateUrl: './cart-modal.component.html',
  styleUrls: ['./cart-modal.component.css']
})
export class CartModalComponent implements OnInit {

  public basketList: BasketModel[] = this.basketFactory.basketList;
  public basketErrorMessage: string = "";

  //To be imported from main component
  public restaurantDeliverZipCodes: DeliverZipCode[];
  public restaurant: Restaurant = new Restaurant();
  public deliverMethodDisabled: boolean = false;

  constructor(private _modalService: BsModalService, @Inject(PLATFORM_ID) private _platformId: Object,
  private _dataService: DataService, public basketFactory: BasketFactory, private _sharedDataService: SharedDataService,
  private _translateService: TranslateService) { }

  public isClickable = true;


  ngOnInit() {
    this.basketFactory.calculateDiscount(+(this.restaurant.disc));
    this.calculateDeliveryPrice();
    this.checkRestaurantDeliversToUser();
  }

  public changeZipCode() { 

    const modalRef = this._modalService.show(ZipCodeModalComponent,{id:"zip"});
    modalRef.content.isSubmittedSuccesfully.subscribe(res => {
      if (res) {

        const initialState = {
          restaurant:this.restaurant,
          restaurantDeliverZipCodes:this.restaurantDeliverZipCodes
        };

        this._modalService.show(CartModalComponent,{id:'cart',initialState});
        this.checkRestaurantDeliversToUser();
      }
    });
    this.closeModal();
  }

  public deleteItemFromBasketList(item: BasketModel) {
    let indexToRemove: number = this.basketList.findIndex(x => x == item);
    if (indexToRemove >= 0) {
      this.basketFactory.totalCountOfItems = this.basketFactory.totalCountOfItems - item.count;
      this.basketList.splice(indexToRemove, 1);

      this.sendDeleteAllFromBasketListEventToGoogleAnalytics(item);
    }
    this.calculateSubtotal();
    this.saveBasketListToLocalStorage(this.basketList);
  }

  public checkCountDisabled(item: BasketModel) {
    if (item.count == 1) {
      return true;
    }
    else{
      return false;
    }
  }

  public countPlus(selectedItem: BasketModel) {
    this.basketFactory.totalCountOfItems++;
    selectedItem.count++;
    this.calculateSubtotal();
    this.sendAddCopyOneItemToBasketListEventToGoogleAnalytics(selectedItem);
    this.saveBasketListToLocalStorage(this.basketList);
  }

  public countMin(selectedItem: BasketModel) {
    if (selectedItem.count > 1) {
      selectedItem.count--;
      this.basketFactory.totalCountOfItems--;
    }
    this.calculateSubtotal();
    this.sendDeleteOneItemFromBasketListEventToGoogleAnalytics(selectedItem);
    this.saveBasketListToLocalStorage(this.basketList);
  }

  public calculateSubtotal() {
    let total: number = 0
    this.basketFactory.setPrices(0, -1);

    this.basketList.forEach(val=>{
      total = total + (+(val.totalPriceWithExtras * val.count));
    });
    // _.each(this.basketList, (val) => {
    //   total = total + (+(val.totalPriceWithExtras * val.count));
    // });

    this.basketFactory.setPrices(total, -1);
    this.basketFactory.calculateDiscount(+(this.restaurant.disc));
    this.calculateDeliveryPrice();
  }

  private sendDeleteAllFromBasketListEventToGoogleAnalytics(item: BasketModel) {
    let gaCategory: string = "da_" + this.restaurant.restaurant_name + "_";

    if (this.basketFactory.userSelectedPickupMethod) {
      gaCategory += this.restaurant.restaurant_postcode;
    } else {
      gaCategory += this.basketFactory.userZipCode;
    }

    gaCategory += "_" + item.catName + "_" + item.productName;

    this._sharedDataService.sendGoogleAnalyticsEvent(gaCategory, item.productId, "", -1);
  }

  private calculateDeliveryPrice() {
    const userSelectionMethod = this.basketFactory.userSelectedPickupMethod;
    this.basketFactory.calculateSubForToPriceOrPrice(this.restaurant.menus_subsite, userSelectionMethod);

    this.basketFactory.calculateDiscount(+(this.restaurant.disc));
    const priceOfOrder: number = this.basketFactory.subtotalPrice - this.basketFactory.discountPrice;

    if (userSelectionMethod) {
      this.basketFactory.setPrices(-1, 0);
      this.basketFactory.isMinimumPriceReached = true;
      this.basketErrorMessage = "";
    } else {
      const deliverCostsIndex = this.restaurantDeliverZipCodes.findIndex( w => w.zcZip == this.basketFactory.userZipCode);
      if (deliverCostsIndex >= 0) {
        const pivot = this.restaurantDeliverZipCodes[deliverCostsIndex].pivot;
        this.basketFactory.setPrices(-1, +(pivot.delivery_charge));

        if (priceOfOrder < +(pivot.minimum_order)) {
          this.basketFactory.isMinimumPriceReached = false;

          this._translateService.get('cart-modal.min-order-delivery-label', { bedrag: pivot.minimum_order }).subscribe((translated: string) => {
            this.basketErrorMessage = translated;
          });
        } else {
          this.basketErrorMessage = "";
          this.basketFactory.isMinimumPriceReached = true;
          if (+(pivot.free_above) > 0) {
            this._translateService.get('cart-modal.free-above-label', { bedrag: pivot.free_above }).subscribe((translated: string) => {
              this.basketErrorMessage = translated;
            });
          }
          if ((+(pivot.free_above) > 0) && (priceOfOrder >= +(pivot.free_above))) {
            this.basketFactory.setPrices(-1, 0);
            this._translateService.get('cart-modal.free-label').subscribe((translated: string) => {
              this.basketErrorMessage = translated;
            });
          }
        }
      }
    }

    if (!isPlatformServer(this._platformId)) {
      localStorage.setItem('pickup', userSelectionMethod.toString());
      this.saveBasketListToLocalStorage(this.basketFactory.basketList);
    }
  }

  private sendAddCopyOneItemToBasketListEventToGoogleAnalytics(item: BasketModel) {
    let gaCategory: string = "add_" + item.count + "_" + item.catName + "_" + item.productName + "_" + this.restaurant.restaurant_name + "_";

    if (this.basketFactory.userSelectedPickupMethod) {
      gaCategory += this.restaurant.restaurant_postcode;
    } else {
      gaCategory += this.basketFactory.userZipCode;
    }

    this._sharedDataService.sendGoogleAnalyticsEvent(gaCategory, item.productId, "", -1);
  }

  private sendDeleteOneItemFromBasketListEventToGoogleAnalytics(item: BasketModel) {
    let gaCategory: string = "del_" + item.count + "_" + item.catName + "_" + item.productName + "_" + this.restaurant.restaurant_name + "_";

    if (this.basketFactory.userSelectedPickupMethod) {
      gaCategory += this.restaurant.restaurant_postcode;
    } else {
      gaCategory += this.basketFactory.userZipCode;
    }

    this._sharedDataService.sendGoogleAnalyticsEvent(gaCategory, item.productId, "", -1);
  }

  private saveBasketListToLocalStorage(basketlist: BasketModel[]) {
    let item = new BasketListStorageModel().mapBasketModelToBasketStorageModel(basketlist);
    if (item != null) {
      localStorage.setItem('basket', JSON.stringify(item));
    }
  }

  public placeOrder() {

    try{
          this.isClickable = false;
          setTimeout(() => {
            this.isClickable = true;
        }, 2000);
   
          this.saveBasketListToLocalStorage(this.basketList);
          localStorage.setItem('totalitems', this.basketFactory.totalCountOfItems.toString());

          const base_url: string = this._sharedDataService.checkouturl + "/order/"

          const newOrder: Order = new Order();

          newOrder.deliveryprice = this.basketFactory.deliverCosts;
          newOrder.rest_id = this.restaurant.id.toString();
          newOrder.rest_logo = this.restaurant.restaurant_logo;
          newOrder.rest_name = this.restaurant.restaurant_name;
          newOrder.rest_slogan = this.restaurant.slogan;
          newOrder.rest_slug = this.restaurant.restaurant_slug;
          newOrder.restaurant_delivercodes = this.restaurantDeliverZipCodes;
          newOrder.totalprice = this.basketFactory.totalPrice;
          newOrder.zipcode = this.basketFactory.userZipCode;
          newOrder.rest_subimg = this.restaurant.typeImageUrl;
          newOrder.subtotal = this.basketFactory.subtotalPrice - this.basketFactory.discountPrice;
          newOrder.subtotal_before = this.basketFactory.subtotalPrice;
          newOrder.referrer = sessionStorage.getItem('referrer');
          newOrder.discountP = +(this.restaurant.disc);
          newOrder.discountC = +(this.basketFactory.discountPrice);
          newOrder.lang = this._translateService.defaultLang;

          if (this.basketFactory.userSelectedPickupMethod) {
            newOrder.pickup = 1;
          } else {
            newOrder.pickup = 0;
          }

          let url: string = window.location.href;
          url = url.slice(0, url.lastIndexOf("/") + 1);

          newOrder.checkoutreferrer = url;

          this._dataService.placeOrder(this.basketList, newOrder).subscribe(res => {
            if (res.success == 1) {
              this.sendPlaceOrderEventToGoogleAnalytics(newOrder.totalprice);
              if (!isPlatformServer(this._platformId)) {
                window.location.href = base_url + res.guid + this.clientid();
              }

            } else {
              if (res.success == 0) {

                this._dataService.doErrorLog({
                  message: res.html.toString(),
                  file: "cart-modal.component.ts",
                  line: "256",
                  stackTrace: "no stack"
                  }).subscribe(res => {

                  });
                this.sendErrorOnPlaceOrderToGoogleAnalytics(res.html.toString());
                if (!isPlatformServer(this._platformId)) {

                  this._translateService.get('cart-modal.error-label').subscribe((translated: string) => {
                    window.alert(translated);
                  });
                }
              }
            }
          },
            err => {
              //console.log(err);
              if (!isPlatformServer(this._platformId)) {

                console.log(err);
                this._dataService.doErrorLog({
                  message: err.message??err.toString(),
                  file: "cart-modal.component.ts",
                  line: "273",
                  stackTrace: err.stack??"no stack"
                  }).subscribe(res => {

                  });


                this.sendErrorOnPlaceOrderToGoogleAnalytics("Er is een onbekende fout opgetreden, gelieve later opnieuw te proberen.");
                this._translateService.get('cart-modal.error-label').subscribe((translated: string) => {
                  window.alert(translated);
                });





              }
            });
    }
    catch(err){
      this._dataService.doErrorLog({
        message: err.message??err.toString(),
        file: "cart-modal.component.ts",
        line: "287",
        stackTrace: err.stack??"no stack"
        }).subscribe(res => {

        });
    }
  }


private clientid()
{
  let ret = '';

  try{

    if((<any>window).ga != undefined)
    {
      //ret =  '?clientId='+(<any>window).ga.getAll()[0].get('clientId');
      ret =  '?'+(<any>window).ga.getAll()[0].get('linkerParam');
    }
  }
  catch(Error)
  {
    this.sendErrorOnPlaceOrderToGoogleAnalytics('get_clientId_error');
      //alert(Error.message);
  }
  finally
  {
    return ret;
  }



}




  public closeModal() {
    this._modalService?.hide('cart');
  }

  public generateExtrasTextBasketItem(extras: ExtrasModel[]): string {
    let result: string = "";
    extras.sort((a,b) => a.ExtrasType.Index - b.ExtrasType.Index);
    extras.forEach(element => {
      result = result + element.Name + ", ";
    });

    result = result.slice(0, result.length - 2);
    return result
  }

  private checkRestaurantDeliversToUser() {
    const deliverCostsIndex = this.restaurantDeliverZipCodes.findIndex(w => w.zcZip == this.basketFactory.userZipCode);

    if (deliverCostsIndex >= 0) {
      //postcode bestaat -> rest lever bij klant
      this.deliverMethodDisabled = false;
    } else {
      this.deliverMethodDisabled = true;
    }
  }

  private sendPlaceOrderEventToGoogleAnalytics(totalPrice: number) {
    let gaCategory: string = "afr_" + this.restaurant.id + "_" + this.restaurant.restaurant_name + "_";

    if (this.basketFactory.userSelectedPickupMethod) {
      gaCategory += this.restaurant.restaurant_postcode;
    } else {
      gaCategory += this.basketFactory.userZipCode;
    }

    gaCategory += "_" + totalPrice;

    this._sharedDataService.sendGoogleAnalyticsEvent(gaCategory, totalPrice.toString(), "", -1);
  }

  private sendErrorOnPlaceOrderToGoogleAnalytics(errorText: string) {
    let gaCategory: string = "ERROR_API_PlaceOrder_" + this.restaurant.restaurant_name + "_";

    if (this.basketFactory.userSelectedPickupMethod) {
      gaCategory += this.restaurant.restaurant_postcode;
    } else {
      gaCategory += this.basketFactory.userZipCode;
    }

    this._sharedDataService.sendGoogleAnalyticsEvent(gaCategory,errorText , "", -1);
  }

}
