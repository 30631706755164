import { BasketModel } from "./basket.model";
// import * as _ from 'lodash';
import { DeliverZipCode } from "./deliverZipCode.model";
import { Injectable } from "@angular/core";

@Injectable()
export class BasketFactory {
    //Order
    public basketList: BasketModel[] = [];
    // Pricing
    public subtotalPrice: number = 0;
    public discountPrice: number = 0;

    public totalPrice: number = 0;
    public deliverCosts: number = 0;
    public isMinimumPriceReached: boolean = false;
    //Delivering Method & Delivering address (zip code)
    public userSelectedPickupMethod: boolean; // afhalen
    public userZipCode: string = "";

    public userSelectedDeliveryPivot: DeliverZipCode;

    public totalCountOfItems: number = 0;

    public setPrices(subtotal: number, deliver: number) {
        if (subtotal != -1) {
            this.subtotalPrice = +(subtotal);
        }
        if (deliver != -1) {
            this.deliverCosts = +(deliver);
        }

        if (this.subtotalPrice == 0){
            this.discountPrice = 0;
        }

        this.totalPrice = this.subtotalPrice - this.discountPrice + this.deliverCosts;
    }

    public addItemToCart(item: BasketModel) {
        let basketListItem: BasketModel = this.basketList.find(x => x.productId == item.productId && x.extras.map(e=>e.id).toString() == item.extras.map(e=>e.id).toString() && x.totalPriceWithExtras == item.totalPriceWithExtras);

        if (basketListItem == undefined) {
            this.basketList.push(item)
            this.totalCountOfItems = this.totalCountOfItems + item.count;
        } else {
            basketListItem.count += item.count;
            this.totalCountOfItems += item.count;
        }
        this.subtotalPrice = this.subtotalPrice + (item.count * item.totalPriceWithExtras);

        this.setPrices(this.subtotalPrice, -1);
    }

    public calculateTotalCountOfItems() {
        this.totalCountOfItems = 0;

        this.basketList.forEach(element => {
            this.totalCountOfItems += element.count;
        });
    }

    public calculateDiscount(discountPerc: number){
        if (discountPerc == null || discountPerc == 0){
            this.discountPrice = 0;
            return;
        }
        this.discountPrice = (this.subtotalPrice * (discountPerc/100));

        this.discountPrice = Math.round(this.discountPrice * 100) / 100;
        this.setPrices(-1,-1);
    }

    public calculateSubForToPriceOrPrice(items, isPickup: boolean) {
        this.subtotalPrice = 0;
        for (let i = 0; i < this.basketList.length; i++) {
            const element = this.basketList[i];
            let basketListItem = items.find(item => item.id == element.productId);

            if (basketListItem != undefined) {
                let calculatedTotal: number = 0;

                for(const elem of element.extras) {
                    calculatedTotal += +(elem.Price);
                }

                element.itemPrice = +(basketListItem.price);
                element.itemToPrice = +(basketListItem.toPrice);
                element.totalPriceWithExtras = (isPickup ? +(basketListItem.toPrice) : +(basketListItem.price)) + calculatedTotal;

                this.subtotalPrice += (element.count * element.totalPriceWithExtras);
            }
        }

        this.setPrices(-1, -1);
    }
}