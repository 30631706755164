import { Component, OnInit, HostListener, Inject, PLATFORM_ID, Renderer2, Optional } from '@angular/core';
import { DataService } from './shared/services/data.service';
import { SharedDataService } from './shared/services/shared-data.service';
import { Restaurant } from './shared/models/restaurant.model';
import { environment } from '../environments/environment';
import { RestaurantFactory } from './shared/models/restaurantFactory';
// import { Router, NavigationStart, NavigationEnd } from '../../node_modules/@angular/router';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Meta, makeStateKey, TransferState } from '@angular/platform-browser';
import { isPlatformServer, DOCUMENT } from '@angular/common';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { setTheme } from 'ngx-bootstrap/utils';
declare var dataLayer;
const REST_KEY = makeStateKey('rest');
//var window,document;  creates error void (0) ??

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  rest: any;

  public isLoading: boolean = true;
  public isScrolled: boolean = false;
  //public req: any = '';
  
  public cookieDismiss: boolean=true;


  

  @HostListener('window:scroll', ['$event'])
  private onScroll($event: Event): void {
    const scrollPosition = window.scrollY;
    this.isScrolled = scrollPosition > 10;
  };
  constructor(private _state: TransferState, private _meta: Meta, @Inject(PLATFORM_ID) private _platformId: Object, @Optional() @Inject(REQUEST) public req: any,
    private _dataService: DataService, private _sharedDataService: SharedDataService, private _restaurantFactory: RestaurantFactory,
    private _router: Router, private _renderer2: Renderer2, @Inject(DOCUMENT) private _document, private _translate: TranslateService,
    private _cookieService: CookieService) {
      setTheme('bs5'); // or 'bs4'
      //if (!isPlatformServer(this._platformId)) {
       
       
      //}

      _translate.onDefaultLangChange.subscribe((event: LangChangeEvent) => {
        //console.log("LangChangeEvent this._translate.defaultLang: "+this._translate.defaultLang);
        _document.documentElement.lang=this._translate.defaultLang;
        // let r = _document.querySelector("html");
        // this._renderer2.setAttribute(r, 'xml:lang',this._translate.defaultLang );

        // if(this._sharedDataService.langpath != this._translate.defaultLang){
        //   this._sharedDataService.langpath = this._translate.defaultLang;
        // }

        if (!isPlatformServer(this._platformId)) {
          //change populair dishes to current lang on clientside changes
          if (_restaurantFactory?.restaurant.favorite_menus?.length > 0) {
            let populaire_gerechten = "Popular dishes";
            if(this._translate.defaultLang=='fr'){
              populaire_gerechten = "Meilleures ventes";
            }
            else if(this._translate.defaultLang=='nl'){
              populaire_gerechten = "Populaire gerechten";
            }
           let t = _restaurantFactory.restaurant.categories_subsite.find(i => i.id==0);
          
            if(t){
              t.category_name=populaire_gerechten;
            }
          }
        }
        

      });


      
      


      // _translate.onLangChange.subscribe((event: LangChangeEvent) => {
      //   if (!isPlatformServer(this._platformId)) {
      //     document.documentElement.lang=this._translate.currentLang;
      //     // let r = document.querySelector("html");
      //     // this._renderer2.setAttribute(r, 'xml:lang',this._translate.currentLang );
      //     console.log("XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX");
      //     this._sharedDataService.setPageTitle(_restaurantFactory.restaurant, "", this._router.url );

      //   }
      // });

    this._router.events.subscribe(event => {

      if (event instanceof NavigationStart) {
        //console.log("NavigationStart event : "+event.url);
        // Could add more chars url:path?=;other possible
       
        //this._translate.use(this._sharedDataService.langpath);
          

          // if (['nl', 'fr','en'].indexOf(this._restaurantFactory.restaurant.lang) > -1) {
          //   this._translate.setDefaultLang(this._restaurantFactory.restaurant.lang);
          // } else {
          //   this._translate.setDefaultLang('nl');
          // }
        
      }
      else if (event instanceof NavigationEnd) {
        //console.log("NavigationEnd event : "+event.url);
        // console.log("routerevent end");
        // console.log("this._router.url: "+this._router.url);
        // console.log("event.urlAfterRedirects: "+event.urlAfterRedirects);
        
        const urlDelimitators = new RegExp(/[?//,;&:#$+=]/);
        let currentUrlPath = event.url.slice(1).split(urlDelimitators)[0];
        //console.log('URL_PATH: ', currentUrlPath);

        this._sharedDataService.currentUrlPath = event.url.slice(1);


        //for than kyou page to catch guid before thankyou page is loaded
        const thankyouUrls = ["/besteld-bij","/nl/besteld-bij","/commande-aupres-de",
                 "/fr/commande-aupres-de","/ordered-at","/en/ordered-at"]; //all thankyou page urls
        if(thankyouUrls.some(thankyouUrls => event.urlAfterRedirects.startsWith(thankyouUrls))){
          const tempguid = event.urlAfterRedirects.substring(event.urlAfterRedirects.lastIndexOf('/') + 1).split("?")[0];
  
          const regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}[0-9a-fA-F]{4}[0-9a-fA-F]{4}[0-9a-fA-F]{4}[0-9a-fA-F]{12}(\}){0,1}$/gi;
          if (regexGuid.test(tempguid)){
            this._sharedDataService.orderguid=tempguid;
          }
        }
        //end guid catch

        
        if(currentUrlPath=="nl" || currentUrlPath=="fr" || currentUrlPath=="en"){
         // console.log("checked urlpath == 'nl or fr or en'");
          this._sharedDataService.langpath = currentUrlPath;
        }
        else {
          //console.log("checked urlpath == ''");
          this._sharedDataService.langpath = this._restaurantFactory.restaurant.lang;
        }
        
        this._translate.setDefaultLang(this._sharedDataService.langpath);
        this._translate.use(this._sharedDataService.langpath);



        this._sharedDataService.setPageTitle(_restaurantFactory.restaurant, "", event.urlAfterRedirects);

        if (!isPlatformServer(this._platformId)) {
          this._meta.updateTag({ property: "og:url", content: _restaurantFactory.restaurant.url + event.urlAfterRedirects });
          let r = document.querySelector("link[rel='canonical']");
          this._renderer2.setAttribute(r, 'href', _restaurantFactory.restaurant.url + event.urlAfterRedirects);

          window.scrollTo(0, 0);
        }



      }

    });

    const isNl = isPlatformServer(this._platformId)?(this.req?this.req.hostname.endsWith('.nl'):false):window.location.origin.endsWith('.nl');
    
    if(isNl){
      this._sharedDataService.baseurl = environment.BASE_URL_NL;
      this._sharedDataService.checkouturl = environment.CHECKOUT_URL_NL;
    }
    else{
      this._sharedDataService.baseurl = environment.BASE_URL;
      this._sharedDataService.checkouturl = environment.CHECKOUT_URL;
    }


    if (isPlatformServer(this._platformId)) {

      //this.req = this.injector.get('request');
      //console.log(this.req.connection.encrypted ? 'https' : 'http');
      // console.log(req);
      //console.log(this.req.headers);
      //console.log(this.req.header('x-forwarded-proto'));
      if ((this.req != undefined) && (this.req.headers != undefined) && (this.req.headers.restid != undefined)) {

        if(isNl){
          this._meta.removeTag('name="apple-itunes-app"');
          this._meta.removeTag('name="google-play-app"');
          this._meta.removeTag('name="twitter:site"');
          this._meta.removeTag('property="fb:app_id"');
        }
        
        this._restaurantFactory.obtainedResId = this.req.headers.restid;

        const urlDelimitators = new RegExp(/[?//,;&:#$+=]/);
        this._sharedDataService.ssrcurrentUrlPath = this.req.originalUrl.slice(1).split(urlDelimitators)[0];
        

        // console.log("this._sharedDataService.ssrcurrentUrlPath: "+this._sharedDataService.ssrcurrentUrlPath);
        // console.log("this.req.originalUrl: "+this.req.originalUrl);

        if(['nl', 'fr','en'].indexOf(this._sharedDataService.ssrcurrentUrlPath) > -1){
          this._translate.setDefaultLang(this._sharedDataService.ssrcurrentUrlPath);
          //this._translate.use(this._sharedDataService.ssrcurrentUrlPath);
          //this._sharedDataService.langpath = this._sharedDataService.ssrcurrentUrlPath;
          
        }
        else{
          // console.log("this.req.headers.restlang: "+this.req.headers?.restlang);
          this._translate.setDefaultLang(this.req.headers?.restlang);
          //this._translate.use(this.req.headers?.restlang);
          //this._sharedDataService.langpath = this.req.headers?.restlang;
        }


        //console.log("restlang: "+this.req.headers.restlang);
        //this._translate.setDefaultLang("nl"); //this is for testing purpose
      }
    }
    // else{
    //   console.log("_document.documentElement.lang: "+ _document.documentElement.lang);
    // }
    // else {
    //   //this._restaurantFactory.obtainedResId = 1;
    //   console.log("before this._translate.getDefaultLang(): "+this._translate.getDefaultLang());
    //   console.log("this._sharedDataService.langpath = this._translate.defaultLang: "+this._sharedDataService.langpath);
    //   this._translate.setDefaultLang(this._sharedDataService.langpath);
    //   console.log("after this._translate.getDefaultLang(): "+this._translate.getDefaultLang());
    // }

  }

  public goTop() {
    var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
    if (currentScroll > 0) {
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth'});
    }
  }

  ngOnInit() {
    this.rest = this._state.get(REST_KEY, null as any);

    if (!this.rest) {
      if (this._restaurantFactory.obtainedResId == null) {
        if (!isPlatformServer(this._platformId)) {
          window.location.href = this._sharedDataService.baseurl;
        }
        return;
      }
      // call
      this.isLoading = true;
      //this.getRestaurantClient();
    } else {
      this.processGetRestaurant(this.rest);
    }

    this.sendGoogleAnalyticsEvent();

    if (this._restaurantFactory.restaurant.id == undefined || this._restaurantFactory.restaurant.id == 0) {
      //this.isLoading = true;
      if (isPlatformServer(this._platformId)) {

        this.isLoading = true;
        this.getRestaurantServer();
      } else {
        this.getRestaurantClient();
        this.isLoading = false;
      }

    }

    if (!isPlatformServer(this._platformId)) {

      if (sessionStorage.getItem('referrer') == null) {
        let tvolger = 'direct';
        if (document.referrer != '') {
           tvolger = document.referrer
        }

        if(/[?&]eo=/.test(location.search)){
          tvolger+="[eo]";
        }

        sessionStorage.setItem('referrer', tvolger);
        

      }


//START cookie consent check
      const cc_status = this._cookieService.get("cookies_consent");

      if(!cc_status){
        this.cookieDismiss = false;
      }
//END cookie consent check 
    
    }
  }

  private getRestaurantServer() {

    try{
      let rest = JSON.parse(this.req.headers.rest);

      this.processGetRestaurant(rest);
      this._state.set(REST_KEY, rest as any);
    }
    catch(err){
      console.log("frontend getRestaurantServer json error",this.req.headers.rest);
      console.log(err);
    }

    
    // this._dataService.getRestaurantInfoNode(this._restaurantFactory.obtainedResId).subscribe(res => {
    //   this.processGetRestaurant(res);
    //   this._state.set(REST_KEY, res as any);
    // }, err => {
    //   this._sharedDataService.setPageTitle(null, "EO - " + this._restaurantFactory.obtainedResId + " - Fout opgetreden");
    //   if (!isPlatformServer(this._platformId)) {
    //     alert("Er is onbekende fout opgetreden, gelieve later opnieuw te proberen. U wordt zo doorverwezen naar onze beveiligde partner website om toch eten te kunnen bestellen.");
    //   }
    // });
  }

  private getRestaurantClient() {
    this._dataService.getRestaurantInfo(this._restaurantFactory.obtainedResId).subscribe(res => {
      this.processGetRestaurant(res);
    }, err => {
      this._sharedDataService.setPageTitle(null, "EO - " + this._restaurantFactory.obtainedResId + " - Fout opgetreden");
      if (!isPlatformServer(this._platformId)) {
        alert("Er is onbekende fout opgetreden, gelieve later opnieuw te proberen. U wordt zo doorverwezen naar onze beveiligde partner website om toch eten te kunnen bestellen.");
        window.location.href = this._sharedDataService.baseurl;
      }
    });
  }

  private processGetRestaurant(res) {
    if (res.none_active == 1) {
      if (!isPlatformServer(this._platformId)) {
        window.location.href = this._sharedDataService.baseurl;
      }
    } else {
      //console.log(res);
      this.isLoading = false;
      let restaurant: Restaurant = res;



//unfortinally router.url doesnt work like exepted only in here 
//so i used req.path for this reason
      

      // if (!isPlatformServer(this._platformId)) {
      //   this.sendPageView(window.location.pathname);
      //   //restaurant.restaurant_logo = this.getImage(res);
      // }

      // console.log("ssrcurrentUrlPath :"+this._sharedDataService.ssrcurrentUrlPath);
      // console.log("_router.url :"+this._router.url);
      // console.log("_router :"+this._router);
      // console.log("_router :"+this._activatedroute.snapshot.url);
      // console.log("req.url :"+this.req.url);
      // console.log("req.originalUrl :"+this.req.originalUrl);
      // console.log("req.path :"+this.req.path);
      
      

      if (isPlatformServer(this._platformId)) {

        let host = (this.req.header('x-forwarded-proto') != undefined ? this.req.header('x-forwarded-proto') : "https") + "://" + this.req.headers.host;
        let hostMenu = host + "/";

        
 
        if(['nl', 'fr','en'].indexOf(this._sharedDataService.ssrcurrentUrlPath) > -1){
          //console.log("checked urlpath == 'nl or fr or en'");
          this._sharedDataService.langpath = this._sharedDataService.ssrcurrentUrlPath;
          //console.log("langpath is setted: " + this._sharedDataService.ssrcurrentUrlPath);


          let s = this._renderer2.createElement('link');

          if(['/nl/beoordelingen', '/en/reviews','/fr/avis'].indexOf(this.req.originalUrl)> -1){

            ['nl', 'fr','en'].forEach( (val) => {

              s = this._renderer2.createElement('link');
              s.rel = 'alternate';
              s.hreflang = val;

              if(val==res.lang){
                s.href = host + (res.lang=="en"?"/reviews":(res.lang=="fr"?"/avis":"/beoordelingen"));
                this._renderer2.appendChild(this._document.head, s);

                s = this._renderer2.createElement('link');
                s.rel = 'alternate';
                s.hreflang = 'x-default';
                
                s.href = host + (res.lang=="en"?"/reviews":(res.lang=="fr"?"/avis":"/beoordelingen"));
              }
              else{
                s.href = host + (val=="en"?"/en/reviews":(val=="fr"?"/fr/avis":"/nl/beoordelingen"));
              }
              this._renderer2.appendChild(this._document.head, s);

            });



          }
          else if(['/nl/contact', '/en/contact','/fr/contact'].indexOf(this.req.originalUrl)> -1){

            ['nl', 'fr','en'].forEach( (val) => {

              s = this._renderer2.createElement('link');
              s.rel = 'alternate';
              s.hreflang = val;

              if(val==res.lang){
                s.href = host + "/contact";
                this._renderer2.appendChild(this._document.head, s);

                s = this._renderer2.createElement('link');
                s.rel = 'alternate';
                s.hreflang = 'x-default';
                
                s.href = host + "/contact";
              }
              else{
                s.href = host + (val=="en"?"/en/contact":(val=="fr"?"/fr/contact":"/nl/contact"));
              }
              this._renderer2.appendChild(this._document.head, s);

            });



          }
          else if(['/nl/privacy-beleid', '/en/privacy-statement','/fr/declaration-de-confidentialite'].indexOf(this.req.originalUrl)> -1){

            ['nl', 'fr','en'].forEach( (val) => {

              s = this._renderer2.createElement('link');
              s.rel = 'alternate';
              s.hreflang = val;

              if(val==res.lang){
                s.href = host + (res.lang=="en"?"/privacy-statement":(res.lang=="fr"?"/declaration-de-confidentialite":"/privacy-beleid"));
                this._renderer2.appendChild(this._document.head, s);

                s = this._renderer2.createElement('link');
                s.rel = 'alternate';
                s.hreflang = 'x-default';
                
                s.href = host + (res.lang=="en"?"/privacy-statement":(res.lang=="fr"?"/declaration-de-confidentialite":"/privacy-beleid"));
              }
              else{
                s.href = host + (val=="en"?"/en/privacy-statement":(val=="fr"?"/fr/declaration-de-confidentialite":"/nl/privacy-beleid"));
              }
              this._renderer2.appendChild(this._document.head, s);

            });



          }
          else if(['/nl', '/en','/fr'].indexOf(this.req.originalUrl)> -1){

            ['nl', 'fr','en'].forEach( (val) => {

              s = this._renderer2.createElement('link');
              s.rel = 'alternate';
              s.hreflang = val;

              if(val==res.lang){
                s.href = host + "/";
                this._renderer2.appendChild(this._document.head, s);

                s = this._renderer2.createElement('link');
                s.rel = 'alternate';
                s.hreflang = 'x-default';
                
                s.href = host + "/";
              }
              else{
                s.href = host + "/" + val;
              }
              this._renderer2.appendChild(this._document.head, s);

            });



          }

        }
        else {
          if(['nl', 'fr','en'].indexOf(res.lang) > -1){
            //console.log("checked urlpath == ''");
            this._sharedDataService.langpath = res.lang;
            //console.log("langpath is setted: " + res.lang);

            let s = this._renderer2.createElement('link');
            s.rel = 'alternate';
            s.hreflang = 'x-default';
            s.href = host + this.req.originalUrl;

            this._renderer2.appendChild(this._document.head, s);

            //console.log("renderer2");
            ['nl', 'fr','en'].forEach( (val) => {
              

              s = this._renderer2.createElement('link');
              s.rel = 'alternate';
              s.hreflang = val;

              if(val==res.lang){
                s.href = host + this.req.originalUrl;
              }
              else{

                if(this.req.originalUrl=="/beoordelingen"){
                  if(val=="nl"){
                    s.href = hostMenu +"beoordelingen";
                  }
                  else if(val=="fr"){
                    s.href = hostMenu + val +"/avis";
                  }
                  else if(val=="en"){
                    s.href = hostMenu + val +"/reviews";
                  }
                  
                }
                else if(this.req.originalUrl=="/avis"){
                  if(val=="nl"){
                    s.href = hostMenu +val+"/beoordelingen";
                  }
                  else if(val=="fr"){
                    s.href = hostMenu +"avis";
                  }
                  else if(val=="en"){
                    s.href = hostMenu + val +"/reviews";
                  }
                  
                }
                else if(this.req.originalUrl=="/reviews"){
                  if(val=="nl"){
                    s.href = hostMenu +val+"/beoordelingen";
                  }
                  else if(val=="fr"){
                    s.href = hostMenu +"avis";
                  }
                  else if(val=="en"){
                    s.href = hostMenu +"reviews";
                  }
                  
                }



                if(this.req.originalUrl=="/privacy-beleid"){
                  if(val=="nl"){
                    s.href = hostMenu +"privacy-beleid";
                  }
                  else if(val=="fr"){
                    s.href = hostMenu + val +"/declaration-de-confidentialite";
                  }
                  else if(val=="en"){
                    s.href = hostMenu + val +"/privacy-statement";
                  }
                  
                }
                else if(this.req.originalUrl=="/declaration-de-confidentialite"){
                  if(val=="nl"){
                    s.href = hostMenu +val+"/privacy-beleid";
                  }
                  else if(val=="fr"){
                    s.href = hostMenu +"declaration-de-confidentialite";
                  }
                  else if(val=="en"){
                    s.href = hostMenu + val +"/privacy-statement";
                  }
                  
                }
                else if(this.req.originalUrl=="/privacy-statement"){
                  if(val=="nl"){
                    s.href = hostMenu +val+"/privacy-beleid";
                  }
                  else if(val=="fr"){
                    s.href = hostMenu +"declaration-de-confidentialite";
                  }
                  else if(val=="en"){
                    s.href = hostMenu +"privacy-statement";
                  }
                  
                }



                else{

                  

                  if(val=="nl"){
                    s.href = hostMenu + val + (this.req.originalUrl=="/"?"":this.req.originalUrl);
                  }
                  else if(val=="fr"){
                    s.href = hostMenu + val + (this.req.originalUrl=="/"?"":this.req.originalUrl);
                  }
                  else if(val=="en"){
                    s.href = hostMenu + val + (this.req.originalUrl=="/"?"":this.req.originalUrl);
                  }
                }


                
              }

              

              
              
  
              this._renderer2.appendChild(this._document.head, s);

            }); 
            

            //link rel alternate things


          }
        }

        //end language capture from url

        this._sharedDataService.setPageTitle(restaurant, "", this.req.originalUrl);

       
        res.url = hostMenu;
        this._meta.addTag({ property: "og:url", content: host + this.req.originalUrl});

        // let restlogo='';
        // if (restaurant.restaurant_logo){
        //   restlogo=',"image":"'+ this.BASE_URL + 'upload/restaurants/' + restaurant.restaurant_logo + '-b.png"';
        // }

        // let s = this._renderer2.createElement('script');
        // s.type = 'application/ld+json';
        // s.text = '{"@context":"http://schema.org","@type":"Restaurant","telephone":"' + restaurant.restaurant_tel + '","url":"' + hostMenu + '"' +
        //   ',"@id":"' + hostMenu + '"'+restlogo+',"name":"' + restaurant.restaurant_name + '","address":{"@type":"PostalAddress","streetAddress":"' + restaurant.restaurant_address +
        //   '","addressLocality":"' + restaurant.restaurant_city + '","addressRegion":"' + restaurant.restaurant_city + '","postalCode":"' + restaurant.restaurant_postcode + '",' +
        //   '"addressCountry":"BE"},"aggregateRating":{"@type":"AggregateRating","ratingValue":"' + restaurant.review_avg_star_cnt + '","reviewCount":' + restaurant.review_total_cnt +
        //   ',"bestRating":5},"geo":  {"@type":"GeoCoordinates","latitude":' + restaurant.resto_lat + ',"longitude":' + restaurant.resto_lng + '}, "potentialAction": {"@type":"OrderAction",' +
        //   '"target": {"@type": "EntryPoint","urlTemplate": "' + hostMenu + '","actionPlatform": ["http://schema.org/DesktopWebPlatform", "http://schema.org/MobileWebPlatform"],' +
        //   '"inLanguage": "nl-BE"},"deliveryMethod": ["http://purl.org/goodrelations/v1#DeliveryModeOwnFleet"],"priceSpecification":{"@type":"DeliveryChargeSpecification",' +
        //   '"appliesToDeliveryMethod":"http://purl.org/goodrelations/v1#DeliveryModeOwnFleet","priceCurrency":"EUR","price": 0.00,"eligibleTransactionVolume": {' +
        //   '"@type":"PriceSpecification","priceCurrency":"EUR","price": 0.00}}}}';

        // this._renderer2.appendChild(this._document.body, s);





        let s2 = this._renderer2.createElement('link');
        s2.rel = 'canonical';
        s2.href = host + this.req.originalUrl;

        this._renderer2.appendChild(this._document.head, s2);


        





        //adding populair products
        if (res.favorite_menus.length > 0) {

          // console.log("favorite_menus check");
          // console.log("this.langpath: "+this._sharedDataService.langpath);

          let populaire_gerechten = "Popular dishes";

          if(this._sharedDataService.langpath=='fr'){
            populaire_gerechten = "Meilleures ventes";
          }
          else if(this._sharedDataService.langpath=='nl'){
            populaire_gerechten = "Populaire gerechten";
          }


          res.favorite_menus.forEach(function (part, index) {
            let a = this[index].menu_cat;
            let t = res.categories_subsite.find(i => i.id === part.menu_cat);
            this[index].category_name = ((t != null) ? (t.category_name != null ? t.category_name : populaire_gerechten) : populaire_gerechten);
            //t !==null ? (t.category_name  != undefined?t.category_name:"Populaire Gerechten"):"Populaire Gerechten";
          }, res.favorite_menus); // use arr as this



          res.categories_subsite.unshift({ id: 0, category_name: populaire_gerechten, note_public: "", index: 0 });
        }
        //console.log(res.favorite_menus);


        let s = this._renderer2.createElement('script');
      

        if((environment.production) && (environment.BASE_URL.startsWith('https://www.'))){
          s.text = `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-W2RKGHK');
          `;
        }
        else{
          s.text = `
          const dataLayer = {
            push:function(a, b, c, d, e, f){
              console.log({a:a},{b:b},{c:c},{d:d},{e:e},{f:f});
            }
          }
          `;
        }
  
        
        this._renderer2.appendChild(this._document.head, s);



      } //end only_ssr

      let vartype = restaurant.types_subsite[0].type;

      if(this._sharedDataService.langpath=='fr'){
        vartype = restaurant.types_subsite[0].fr;
      }
      else if(this._sharedDataService.langpath=='nl'){
        vartype = restaurant.types_subsite[0].nl;
      }




      restaurant.typeImageUrl = environment.CDN_URl + "upload/kitchens/" + restaurant.types_subsite[0].type_image;
      restaurant.typeDesc = vartype;

      this._sharedDataService.restaurantObservable.emit(res);



      this._restaurantFactory.initializeFactory(res);


      this.splitOpeningshoursOfToday();
    }
  }

  // private sendPageView(url: string) {
  //   if (!isPlatformServer(this._platformId)) {
  //     (<any>window).ga('set', 'page', url);
  //     (<any>window).ga('send', 'pageview');
  //   }
  // }

  private sendGoogleAnalyticsEvent() {
    this._sharedDataService.googleAnalyticsEventEmitter.subscribe(res => {
      const gaEvent = JSON.parse(res);
      if (+(gaEvent.value) == -1) {
        gaEvent.value = undefined;
      }

      if (!isPlatformServer(this._platformId)) {
        //(<any>window).ga('send', 'event', gaEvent.category, gaEvent.action, gaEvent.label, gaEvent.value);
        dataLayer.push({ 'event': 'gaevent', 'category': gaEvent.category, 'action': gaEvent.action, 'label': gaEvent.label, 'value': gaEvent.value });
        //console.log(gaEvent.category + " " + gaEvent.action + " " + gaEvent.label + " " + gaEvent.value);
        if (gaEvent.category == "RealOrder") {
          dataLayer.push({ 'event': 'subconversion', 'value': gaEvent.value, 'orderid': gaEvent.label,email:gaEvent.email });

        }
      }
    })
  }

  // private getImage(res) {
  //   return this.BASE_URL + 'upload/restaurants/' + res.restaurant_logo + '-b.png';
  // }

  private splitOpeningshoursOfToday() {
    if (this._restaurantFactory.restaurant.denemeopen2 != "") {
      const arr: string[] = this._restaurantFactory.restaurant.denemeopen2.split(';');

      if (arr.length > 0) {
        switch (arr[0].toLowerCase()) {
          case "closedallday":
            this._restaurantFactory.restaurant.isOpenToday = 0;
            this._restaurantFactory.restaurant.isDeliveringToday = 0;
            break;
          case "open":
            this._restaurantFactory.restaurant.isOpenToday = 1;
            this._restaurantFactory.restaurant.isDeliveringToday = this.splitDeliveryhoursOfToday(arr[1]);
            break;
          case "close":
            this._restaurantFactory.restaurant.isOpenToday = 2;
            this._restaurantFactory.restaurant.isDeliveringToday = this.splitDeliveryhoursOfToday(arr[1]);
            break;
          default:
            this._restaurantFactory.restaurant.isOpenToday = 1;
            this._restaurantFactory.restaurant.isDeliveringToday = 1;
            break;
        }
      }
    }
  }

  private splitDeliveryhoursOfToday(deliveryToday: string): number {
    switch (deliveryToday.toLowerCase()) {
      case "closedallday":
        return 0;
      case "open":
        return 1;
      case "close":
        return 2;
      default:
        return 1;
    }
  }

  public getlinkforcurrlanguage(page:string){
    return this._sharedDataService.getlinkforcurrlanguage(page, this._restaurantFactory.restaurant.lang);
  }

  public cookiedismiss(){
    this.cookieDismiss = true;
    this._cookieService.set("cookies_consent","true",365,"/");
    dataLayer.push({ 'event': 'consentpos'});
  }
}
