  <div class="modal-header">
    <h5 class="modal-title">{{'zip-code-modal.titel' | translate}}</h5>
    <button type="button" class="close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div>
      <form #enterZipCode="ngForm">
        <span [innerHTML]="'zip-code-modal.postcode-ingave-label' | translate: {name: restaurant.restaurant_name}"></span>
        <br>
        <br>
        <label for="zipCode">{{'zip-code-modal.postcode-label' | translate}}</label>
        <input [disabled]="rdbPickupChecked" [(ngModel)]="userInputZipCode" [pattern]="zipCodePattern" name="zipcodeinput" type="number"
          class="form-control" [ngClass]="{ errorselect: !enterZipCode.valid }" id="zipCode" placeholder="{{'zip-code-modal.uw-postcode' | translate}}" (keyup.enter)="submitZipCode(userInputZipCode)"
          #inputUserZipCode>
        <br>
        <span class="item-add-zip-code-nota">{{'zip-code-modal.keuze-postcode-label' | translate}}</span>
      </form>

      <div *ngIf="!isDeliveringToUsersZipCode && isDeliveringToday">
        <hr> <span [innerHTML]="'zip-code-modal.rest-levert-niet-label' | translate: {name: restaurant.restaurant_name, zip: enteredUserZipCode }"></span>
        <br>
        <br> {{'zip-code-modal.andere-rest-kiezen-label' | translate}}
        <br>
        <br>
        <div class="container row align-text-center">
          <label class="radio-inline col-6">
            <input class="item-opacity" name="options" ng-control="options" type="radio" [value]="true" [(ngModel)]="rdbPickupChecked">
            <br/>{{'zip-code-modal.afhalen-label' | translate}}
          </label>
          <label class="radio-inline col-6">
            <input class="item-opacity" name="options" ng-control="options" type="radio" [value]="false" [(ngModel)]="rdbPickupChecked">
            <br/>{{'zip-code-modal.bezorgen-label' | translate}}
          </label>
        </div>
      </div>

      <div *ngIf="!isDeliveringToUsersZipCode && !isDeliveringToday">
        <hr>
        <div class="alert alert-danger">
          {{'zip-code-modal.rest-levert-vandaag-niet-label' | translate: {name: restaurant.restaurant_name} }}
        </div>

        {{'zip-code-modal.rest-afhalen-label' | translate}}
        <br>
        <div class="container row align-text-center">
          <label class="radio-inline col-6">
            <input name="options" ng-control="options" type="radio" [value]="true" [(ngModel)]="rdbPickupChecked">
            <br/>{{'zip-code-modal.afhalen-label' | translate}}
          </label>
          <label class="radio-inline col-6">
            <input name="options" ng-control="options" type="radio" [disabled]="true">
            <br/>{{'zip-code-modal.bezorgen-label' | translate}}
          </label>
        </div>
      </div>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-orange" type="submit" (click)="submitZipCode(userInputZipCode)" [disabled]="(userInputZipCode == '' || !enterZipCode.valid) && !rdbPickupChecked">{{'zip-code-modal.save-button' | translate}}</button>
    </div>
  </div>