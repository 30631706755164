import { Component, OnInit } from '@angular/core';
import { BsModalService  } from 'ngx-bootstrap/modal';
import { RestaurantFactory } from '../../shared/models/restaurantFactory';
import { SharedDataService } from '../../shared/services/shared-data.service';

@Component({
  selector: 'app-closed-modal',
  templateUrl: './closed-modal.component.html',
})
export class ClosedModalComponent implements OnInit {

  public tld:string='be';

  constructor(public activeModal: BsModalService, public resFactory: RestaurantFactory, private _shareddataservice: SharedDataService) {
    if(this._shareddataservice.baseurl.endsWith(".nl")){
      this.tld = 'nl';
    }

   }

  ngOnInit() {
  }

  public closeModal() {
    this.activeModal?.hide('iclosed');
  }

}
