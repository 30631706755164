<div class="sidedishes">
  <div class="modal-header">
    <h5 class="modal-title">[{{product.catName}}] {{product.productName}}</h5>
    <button type="button" class="close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body scroll">

    <div *ngIf="(product.description != null) && (product.description.length > 0)" class="ingredient item-add-modal-ingredients">
      {{product.description}}
    </div>


    <form #addItemForm="ngForm">
      <div class="sidedish" *ngFor="let option of product.links; let i = index">

        
        <div class="form-group">
          <h3>{{option.Name}}</h3>

          <!-- SELECTBOX START -->
          <ng-template [ngIf]="option.typelisting == 'SLT'">
          <select [name]="selectInputLabelName + i" required [ngClass]="{ errorselect: !addItemForm.valid }" class="form-control" [ngModel]="product.links[i].selected"
            (ngModelChange)="selectDropdownExtraItem(option, $event)">
            <!-- <option [value]="undefinedOptionValue" default selected disabled hidden>Maak een keuze</option> -->
            <option *ngFor="let child of option.subweb_childs; let j = index" [value]="child.id">{{child.Name}} <ng-template [ngIf]="child.Price>0">(+ € {{child.Price}})</ng-template></option>
          </select>
        </ng-template>
          <!-- SELECTBOX END -->

        <!-- CHECKBOX START -->
        <ng-template [ngIf]="option.typelisting == 'CHK'">
        <label class="col-xs-12 col-sm-6 col-md-6 col-lg-6 containerExtraMenu" *ngFor="let child of option.subweb_childs;">{{child.Name}} <ng-template [ngIf]="child.Price>0"> + € {{child.Price}}</ng-template>
          <input type="checkbox" (click)="selectExtraItem(child,option);" value="{{child.id}}">
          <span class="checkmark"></span>
        </label>
        </ng-template>
        <!-- CHECKBOX END -->

        </div>

        
      </div>

      

    </form>
    
    <div class="errorselect customPaddingAddItemError" *ngIf="!addItemForm.valid">{{'add-item-modal.selectie-keuzelijst-label' | translate}}</div>

  </div>
  <div class="modal-footer">
          <span>
            <div class="form-row">
              <div>
                <i class="icon-minus-squared clr-orange customFontSizeIcons" (click)="countMin(product)"></i>
                <div class="count-label">{{product.count}}</div>
                <i class="icon-plus-squared clr-orange customFontSizeIcons" (click)="countPlus(product)"></i>
              </div>
            </div>
          </span>
      <div>
    <button type="button" class="btn btn-orange addsidedishprod" type="submit" (click)="addItemToCard(product)" [disabled]="!addItemForm.valid"
      data-dismiss="modal">{{(product.count * product.totalPriceWithExtras) | currency:'EUR'}} <i class="icon-plus" aria-hidden="true"></i></button>
  </div>
  </div>
</div>