import { SharedDataService } from './../shared/services/shared-data.service';
import { Component } from '@angular/core';

@Component({
    selector: 'privacybeleid',
    templateUrl: './privacybeleid.component.html'
})


export class PrivacyBeleidComponent {
    constructor(public shareddataservice: SharedDataService) {}
  }