import { Component, OnInit, EventEmitter } from '@angular/core';
import { BasketModel, ExtrasModel, ExtrasType } from '../../shared/models/basket.model';

//import * as _ from "lodash"; save 70kb
import { BsModalService  } from 'ngx-bootstrap/modal';
import { BasketFactory } from '../../shared/models/basketFactory';

@Component({
  selector: 'app-add-item-modal',
  templateUrl: './add-item-modal.component.html',
  styleUrls: ['./add-item-modal.component.css']
})
export class AddItemModalComponent implements OnInit {

  public product: BasketModel = new BasketModel();
  public selectInputLabelName: string = "sel"

  public addedSuccesfully: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public activeModal: BsModalService, private _basketFactory: BasketFactory) { }

  ngOnInit() {
    this.selectFirstOption();
  }

  private selectFirstOption() {
    for (let i in this.product.links) {
      if (this.product.links[i].typelisting == "SLT"){
        for (let j in this.product.links[i].subweb_childs) {
          this.product.links[i].selected = this.product.links[i].subweb_childs[j].id;

          this.selectDropdownExtraItem(this.product.links[i], this.product.links[i].subweb_childs[j].id);

          break;
        }
      }
    }
  }

  public countPlus(selectedItem) {
    selectedItem.count++;
  }

  public countMin(selectedItem) {
    if (selectedItem.count > 1) {
      selectedItem.count--;
    }
  }

  public selectDropdownExtraItem(option, childId) {
    let menuChild: ExtrasModel = option.subweb_childs.find(x => x.id == childId) as ExtrasModel;

    if (this.product.extras.length > 0) {
      option.subweb_childs.forEach(element => {
        let indexOfArray = this.product.extras.findIndex(extra => extra.id == element.id);

        if (indexOfArray >= 0) {
          this.product.extras.splice(indexOfArray, 1);
          this.product.totalPriceWithExtras = this.product.totalPriceWithExtras - +(element.Price);
        }
      });
    }
    this.selectExtraItem(menuChild, option);
  }

  public selectExtraItem(child, option) {
    // console.log(child);
    // console.log(option);
    let extrasModel: ExtrasModel = new ExtrasModel();
    extrasModel = child as ExtrasModel;

    let extrasType: ExtrasType = new ExtrasType();
    extrasType.Id = option.id;
    extrasType.Name = option.Name;
    extrasType.Index = option.Index;

    extrasModel.ExtrasType = extrasType;

    let indexExtraItem: number = this.product.extras.findIndex(x => x.id == extrasModel.id);

    if (indexExtraItem < 0) {
      this.product.extras.push({ ...extrasModel });
      this.product.extras.sort((a,b) => a.ExtrasType.Index - b.ExtrasType.Index);

      this.product.totalPriceWithExtras = this.product.totalPriceWithExtras + +(extrasModel.Price);
    } else {
      this.product.extras.splice(indexExtraItem, 1);
      this.product.totalPriceWithExtras = this.product.totalPriceWithExtras - +(extrasModel.Price);
    }


    this.product.extras = this.product.extras.sort((a,b)=>(a.ExtrasType.Index - b.ExtrasType.Index || a.Index - b.Index));

    //this.product.extras = _.orderBy(this.product.extras, "id");
  }

  public closeModal() {
    this.activeModal?.hide('additem');
  }

  public addItemToCard(item) {
    this._basketFactory.addItemToCart(item);

    this.closeModal();
    this.addedSuccesfully.next(true);
  }

}
