<div class="container-fluid bestelling" *ngIf="resFactory.restaurant.id != null && orderNummer!=''">
  <div class="container ">
    <div class="title-fontsize text-center">{{'thank-you.titel' | translate: {username: user_name | uppercase} }}</div>
    <div>
      <!-- <br> -->
      <span>{{'thank-you.wat-nu-label' | translate}}
        <br>
        <br> <span [innerHTML]="'thank-you.sms-bevestiging-label' | translate: {restName: resFactory.restaurant.restaurant_name}"></span>
        <br>
        <br> {{'thank-you.order-no-label' | translate}}
        <b>{{orderNummer | uppercase}}</b>.
        <br>
        <br> {{'thank-you.ps-label' | translate}}<br>
        <p><a href="/">{{'thank-you.order-again' | translate}}</a></p>
        <p><a class="btn btn-warning" role="button" [href]="get_tracker_url()">{{'thank-you.track-your-order' | translate}}</a></p></span>
    </div>


  </div>
</div>