import { TitleCasePipe } from '@angular/common';
import { Restaurant } from "./restaurant.model";
import { DeliverZipCode } from "./deliverZipCode.model";
import { Injectable } from "@angular/core";
// import { DataService } from "../services/data.service";
// import { Observable } from "rxjs/Observable";


@Injectable()
export class RestaurantFactory {
    public restaurant: Restaurant = new Restaurant();
    public deliverZipCodes: DeliverZipCode[] = [];
    public obtainedResId: number;
    constructor(private tcp:TitleCasePipe) { }

    initializeFactory(res: Restaurant) {
        this.restaurant = res;
        this.deliverZipCodes  = res.zipcodes;


        this.restaurant.restaurant_name = this.tcp.transform(this.restaurant.restaurant_name);
        this.restaurant.restaurant_address = this.tcp.transform(this.restaurant.restaurant_address);
        this.restaurant.restaurant_city = this.tcp.transform(this.restaurant.restaurant_city);
        

        this.restaurant.calc_title =  this.restaurant.restaurant_name +   ((this.restaurant.restaurant_name.indexOf(this.restaurant.restaurant_city) !== -1) ?"":(" " + this.restaurant.restaurant_city));
        this.restaurant.firstresttypeslug= this.restaurant.types_subsite[0] !==undefined? this.restaurant.types_subsite[0].slug_en:this.restaurant.restaurant_slug;
    }

    // public getCoordinates(dataService: DataService): Observable<boolean> {
    //     return dataService.getCoordinates(this.restaurant.restaurant_address, "", this.restaurant.restaurant_postcode, this.restaurant.restaurant_city).map((res) => {
    //         if (res.status == "OK") {
    //             this.restaurant.resto_lat = res.results[0].geometry.location.lat;
    //             this.restaurant.resto_lng = res.results[0].geometry.location.lng;
    //             return true;
    //         } else {
    //             return false;
    //         }
    //     })
    // }

    // Al de restaurant-gebonden methods (zoals omtrent delivering) hier in steken - extension methods
}                                                                   