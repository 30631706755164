import { BasketModel } from "./basket.model";
// import * as _ from 'lodash';
import { DeliverZipCode } from "./deliverZipCode.model";

export class Order {
    public action: string;
    public rest_id: string;
    public zipcode: string;
    public payswith: string = "CNT";
    public rest_logo: string;
    public rest_slug: string;
    public rest_name: string;
    public rest_slogan: string;
    public totalprice: number;
    public deliveryprice: number;
    public checkoutreferrer: string = "";
    public restaurant_delivercodes: DeliverZipCode[];
    public pickup: number;
    public rest_subimg: string;
    public subtotal: number;
    public data: OrderData[] = [];
    public referrer: string = "";
    public subtotal_before: number;
    public discountP: number;
    public discountC: number;
    public lang:string;
}

export class OrderData {

    public menu_id: string;
    public custom_id: string = "";
    public menu_id_exact: string;
    public name: string;
    public qty: string;
    public price: string;
    public toPrice: string;
    public childs: OrderDataChild[] = [];
    public menucat: number;
    public menucat_name: string;

    public mapBasketModelToOrderDataModel(basketList: BasketModel[]): OrderData[] {
        const retList = [];

        if (basketList != null) {


            basketList.forEach((item) => {
                const orderData: OrderData = new OrderData();
                orderData.menu_id = item.productId;
                orderData.price = item.itemPrice.toString();
                orderData.toPrice = item.itemToPrice.toString();
                orderData.qty = item.count.toString();
                orderData.custom_id = item.internalProductId;
                orderData.menu_id_exact = item.productId;
                orderData.name = item.productName;
                orderData.menucat = +(item.catId);
orderData.menucat_name = item.catName;

                item.extras.forEach((extraItem) => {
                    orderData.menu_id += "-" + extraItem.id;

                    const orderDataChild: OrderDataChild = new OrderDataChild();

                    orderDataChild.id = extraItem.id.toString();
                    orderDataChild.name = extraItem.Name;
                    orderDataChild.price = extraItem.Price;

                    orderData.price = (+(orderData.price) + +(extraItem.Price)).toString();
                    orderData.toPrice =  (+(orderData.toPrice) + +(extraItem.Price)).toString();

                    orderData.childs.push({ ...orderDataChild })
                })

                retList.push({ ...orderData });
            });
        }
        //console.log(retList)
        return retList;
    }
}

export class OrderDataChild {
    public id: string;
    public name: string;
    public price: string;
}