<main *ngIf="resFactory.restaurant != null">

    <div class="container-fluid contact">
        <div class="container">
            <h2>{{'contact.titel' | translate: {nameRest: resFactory.restaurant.calc_title} }} </h2><div *ngIf="resFactory.restaurant.disc > 0" class="korting-headerc">{{'contact.algemeen-korting-online-bestellingen-label' | translate: {kortingProcent: resFactory.restaurant.disc | number : '1.0-0'} }}</div> 
            <p *ngIf="resFactory.restaurant.lang==shareddataservice.langpath" [innerHTML]="resFactory.restaurant.restaurant_description">
            </p>
            <div class="row">
                <div class="col-sm-6">
                    <h3>
                        <i class="icon-location"></i>{{'contact.adres-label' | translate}}</h3>
                    <p>
                        <b> {{resFactory.restaurant.restaurant_name}}</b>
                        <br> {{resFactory.restaurant.restaurant_address}},
                        <br> {{resFactory.restaurant.restaurant_postcode}} {{resFactory.restaurant.restaurant_city}}
                        <br>
                        <br> {{'contact.btw-label' | translate}} {{resFactory.restaurant.btw_nummer}}.
                        <br>
                        </p>
                        <div class="ordernow">
                                <a routerLink="{{getlinkforcurrlanguage('home')}}" class="ordernow-button" title="{{'beoordelingen.order_online_with' | translate}} {{resFactory.restaurant.calc_title}}" >{{'beoordelingen.order-online-button' | translate}}</a>
                              </div>
                        <p *ngIf="resFactory.restaurant.restaurant_tel">
                        <br> 
                        {{'contact.bereiken-op-label' | translate: {telNummer: resFactory.restaurant.restaurant_tel} }}
                    </p>
                </div>

                <div class="col-sm-6">
                    <div class="openhours-contact">
                        <h3>
                            <i class="icon-clock"></i> {{'contact.openingsuren-titel' | translate}}</h3>
                        <ul>
                            <li>
                                {{'contact.maandag-label' | translate}}
                                <span>{{resFactory.restaurant.open_monday}}</span>
                            </li>
                            <li>
                                {{'contact.dinsdag-label' | translate}}
                                <span>{{resFactory.restaurant.open_tuesday}}</span>
                            </li>
                            <li>
                                {{'contact.woensdag-label' | translate}}
                                <span>{{resFactory.restaurant.open_wednesday}}</span>
                            </li>
                            <li>
                                {{'contact.donderdag-label' | translate}}
                                <span>{{resFactory.restaurant.open_thursday}}</span>
                            </li>
                            <li>
                                {{'contact.vrijdag-label' | translate}}
                                <span>{{resFactory.restaurant.open_friday}}</span>
                            </li>

                            <li>
                                {{'contact.zaterdag-label' | translate}}
                                <span>{{resFactory.restaurant.open_saturday}}</span>
                            </li>
                            <li>
                                {{'contact.zondag-label' | translate}}
                                <span>{{resFactory.restaurant.open_sunday}}</span>
                            </li>
                        </ul>
                    </div>

                    <div class="openhours-contact">
                        <h3>
                            <i class="icon-clock"></i> {{'contact.bezorgtijden-titel' | translate}}</h3>
                        <ul>
                            <li>
                                {{'contact.maandag-label' | translate}}
                                <span>{{resFactory.restaurant.deliver_monday}}</span>
                            </li>
                            <li>
                                {{'contact.dinsdag-label' | translate}}
                                <span>{{resFactory.restaurant.deliver_tuesday}}</span>
                            </li>
                            <li>
                                {{'contact.woensdag-label' | translate}}
                                <span>{{resFactory.restaurant.deliver_wednesday}}</span>
                            </li>
                            <li>
                                {{'contact.donderdag-label' | translate}}
                                <span>{{resFactory.restaurant.deliver_thursday}}</span>
                            </li>
                            <li>
                                {{'contact.vrijdag-label' | translate}}
                                <span>{{resFactory.restaurant.deliver_friday}}</span>
                            </li>
                            <li>
                                {{'contact.zaterdag-label' | translate}}
                                <span>{{resFactory.restaurant.deliver_saturday}}</span>
                            </li>
                            <li>
                                {{'contact.zondag-label' | translate}}
                                <span>{{resFactory.restaurant.deliver_sunday}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </div>
</main>