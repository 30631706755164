import { Component, OnInit, NgZone } from '@angular/core';
import { RestaurantFactory } from '../shared/models/restaurantFactory';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '../shared/services/data.service';
import { SharedDataService } from '../shared/services/shared-data.service';
// import { niceDateFormatPipe } from './../pipes/nicedateformatpipe';
import { DatePipe } from '@angular/common';

//var window,document; creates error void (0) ??

@Component({
  selector: 'app-beoordelingen',
  templateUrl: './beoordelingen.component.html',
})
export class BeoordelingenComponent implements OnInit {

  public image: SafeStyle;
  public page: number=0;
  public reviews: any =[];
  public endreviews = false;

  constructor(public resFactory: RestaurantFactory, private _sanitizer: DomSanitizer, private _router: Router, private _route: ActivatedRoute,
    private _translate: TranslateService, private _dataService: DataService, public shareddataservice: SharedDataService, private _zone: NgZone) {
    
    this.image = this._sanitizer.bypassSecurityTrustStyle(`url(${this.resFactory.restaurant.typeImageUrl})`);
    this.reviews = this.resFactory.restaurant.reviews_subsite;
    if(this.resFactory.restaurant.review_total_cnt<11){
      this.endreviews=true;
    }
    
  }

  ngOnInit() {
    
  }


  getReviews() {

    if(!this.endreviews){
      this.page++;

      let window_scroll = document.documentElement.scrollTop;
      // console.log(window_scroll);

      this._dataService.getRestaurantReviews(this.resFactory.restaurant.id,this.page).subscribe(res => {
        
        this.reviews = this.reviews.concat(res as any);

        if(res.length<10){
          this.endreviews=true;
        }
        setTimeout(function(){ document.documentElement.scrollTop=window_scroll; }, 400);
        
      }, err => {
        console.log(err);
      });
    }
  }


  getlinkforcurrlanguage(page:string){

    return this.shareddataservice.getlinkforcurrlanguage(page, this.resFactory.restaurant.lang);

    
  }


  convertdatetime(strdatetime:string){
    if(strdatetime){
      var _value = new Date(strdatetime.replace(' ', 'T')).getTime();

      var diff = Math.floor( ( (Date.now() - _value) / 1000 ));
      var daydiff = Math.floor(diff / 86400);

      if ( (daydiff>=0) && (daydiff < 30) ){
           return this.convertToNiceDate(diff,daydiff);
      }else{
          var datePipe = new DatePipe("en-US");
          strdatetime = datePipe.transform(strdatetime, 'dd-MM-yyyy');
          return strdatetime;
      }
  }
  }


  private convertToNiceDate(diff, daydiff: number):String {
    

    if (isNaN(daydiff) || daydiff < 0 || daydiff >= 31){
        return '';
    }
    
    var ret ="";
    
    if(daydiff==0){
        if(diff<60){
            this._translate.get('beoordelingen.just_now').subscribe((translated: string) => {
                ret = translated;
            });
        }
        else if(diff < 120 ){
            this._translate.get('beoordelingen.1mingo').subscribe((translated: string) => {
                ret = translated;
            });
        }
        else if(diff < 3600){
            this._translate.get('beoordelingen.minsgo',{x:Math.floor(diff / 60)}).subscribe((translated: string) => {
                ret = translated;
            });
        }
        else if(diff < 7200){
            this._translate.get('beoordelingen.1hourgo').subscribe((translated: string) => {
                ret = translated;
            });
        }
        else if(diff < 86400){
            this._translate.get('beoordelingen.hoursgo',{x:Math.floor(diff / 3600)}).subscribe((translated: string) => {
                ret = translated;
            });
        }
    }
    else if(daydiff==1){
        this._translate.get('beoordelingen.yesterday').subscribe((translated: string) => {
            ret = translated;
        });
    }
    else if(daydiff < 7){
        ret = this._translate.instant('beoordelingen.daysgo',{x:daydiff});
        // this._translateService.get('beoordelingen.daysgo',{x:daydiff}).subscribe((translated: string) => {
        //     ret = translated;
        // });
    }
    else{

        let xweeks =  Math.ceil(daydiff / 7);

        if((xweeks = 1) && (this.shareddataservice.langpath == "nl")){
            ret = "Vorige week";
        }
        else{
            ret = this._translate.instant('beoordelingen.weeksgo',{x:xweeks});
            // this._translateService.get('beoordelingen.weeksgo',{x:xweeks}).subscribe((translated: string) => {
            //     ret = translated;
            // });
        }
    }
    return ret;


}



}
