import { SharedDataService } from './shared/services/shared-data.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filtercategories'
  })

  export class FilterCategoriesPipe implements PipeTransform {

    constructor(public shareddataservice: SharedDataService){}

    transform(items: any[], searchText: string): any[] {
      if(!items) return [];
      if((!searchText) || (searchText.length<2)) return items;

      searchText = this.shareddataservice.accent_fold(searchText.toLowerCase());



 return items.filter( cat => {

  if(cat.catId==0){
    return false;
  }
  else if( this.shareddataservice.accent_fold(cat.catName.toLowerCase()).includes(searchText))
  {
    return true;
  }


    let a = cat.products?.filter(menu =>{
        return  (this.shareddataservice.accent_fold(menu.menu_name.toLowerCase()).includes(searchText) ||  (menu.description != null && this.shareddataservice.accent_fold(menu.description.toLowerCase()).includes(searchText)));
    });



    return a?.length>0;


});

    //   return items.filter( it => {
    //     return (it.menu_name.toLowerCase().includes(searchText) || it.description.toLowerCase().includes(searchText)   );
    //   });
     }






  }
