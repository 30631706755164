  <div class="modal-header">
    <h5 class="modal-title">{{'cart-modal.winkelmand-titel' | translate}}</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body scroll">
    <div class="table-responsive">

      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col" class="align-text-center">
              <b>#</b>
            </th>
            <th scope="col">
              <b>{{'cart-modal.product-label' | translate}}</b>
            </th>
            <th scope="col">
              <b>{{'cart-modal.prijs-label' | translate}}</b>
            </th>
            <th scope="col">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of basketList" class="customPadding basket-card-tr-font">
            <th class="basket-cart-center basket-card-th-width">
              <div class="basket-cart-border">{{item.count}}</div>
              <div class="basket-card-cnt">
                <i class="icon-minus-squared clr-orange basket-card-count-min-icon-positioning nomargin" [ngClass]="{'disabledmin': checkCountDisabled(item)}"
                 (click)="countMin(item)"></i>
                <i class="icon-plus-squared clr-orange basket-card-count-plus-icon-positioning nomargin" (click)="countPlus(item)"></i>
              </div>

            </th>
            <td class="basket-card-product-name-width">
              [{{item.catName}}] {{item.productName}}
              <br>
              <div class="clr-orange">{{generateExtrasTextBasketItem(item.extras)}}</div>
            </td>

            <td>€ {{ (item.count * item.totalPriceWithExtras) | number : '1.2-2'}}</td>

            <td>
              <i class="icon-trash" (click)="deleteItemFromBasketList(item)"></i>
            </td>
          </tr>
          <tr *ngIf="basketList.length <= 0">
            <td colspan="4">{{'cart-modal.winkelmand-leeg-label' | translate}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <span *ngIf="basketList.length != 0">
      <hr>
      <div class="row">
        <div class="col-6" style="text-align: right;">
          {{'cart-modal.subtotaal-label' | translate}}
          <br *ngIf="basketFactory.discountPrice > 0">
          <span *ngIf="basketFactory.discountPrice > 0" class="text-danger">{{'cart-modal.discount-label' | translate}}</span>
          <br>{{'cart-modal.delivery-label' | translate}}
          <br>
          <b>{{'cart-modal.totaal-label' | translate}}</b>
        </div>
        <div class="col-6">
          € {{basketFactory.subtotalPrice | number : '1.2-2'}}
          <br *ngIf="basketFactory.discountPrice > 0">
          <span *ngIf="basketFactory.discountPrice > 0" class="text-danger">- € {{basketFactory.discountPrice | number : '1.2-2'}}</span>
          <br>
          <span *ngIf="basketFactory.userSelectedPickupMethod">{{'cart-modal.gratis-afhalen-label' | translate}}</span>
          <span *ngIf="basketFactory.deliverCosts <= 0 && !basketFactory.userSelectedPickupMethod">{{'cart-modal.gratis-label' | translate}}</span>
          <span *ngIf="basketFactory.deliverCosts > 0 && !basketFactory.userSelectedPickupMethod">€ {{basketFactory.deliverCosts | number : '1.2-2'}}</span>
          <br>
          <b>€ {{basketFactory.totalPrice | number : '1.2-2'}}</b>
        </div>
        <br *ngIf="basketFactory.discountPrice > 0">
        <span *ngIf="basketFactory.discountPrice > 0" class="text-danger discount-text">{{'cart-modal.online-discount-label' | translate: {kortingProcent: restaurant.disc | number : '1.0-0'} }} <br> {{'cart-modal.bespaart-zo-label' | translate: {bedrag: basketFactory.discountPrice | number : '1.2-2'} }}</span>
      </div>
      <hr>
      <span *ngIf="deliverMethodDisabled" style="font-size: 14px;"><a (click)="changeZipCode()" class="show-clickable">{{'cart-modal.wijzig-postcode-fout-label' | translate}}</a></span>
      <span *ngIf="!deliverMethodDisabled" style="font-size: 14px;"><a (click)="changeZipCode()" class="show-clickable" title="Postcode wijzigen">{{'cart-modal.wijzig-postcode-label' | translate}}</a></span>
      <hr>
      <div class="container row align-text-center">
        <label class="radio-inline col-6">
          <input class="item-opacity" (change)="calculateDeliveryPrice()" name="options" ng-control="options" type="radio" [value]="true" [(ngModel)]="basketFactory.userSelectedPickupMethod">
          <br/>{{'cart-modal.afhalen-label' | translate}}
        </label>
        <label class="radio-inline col-6">
          <input class="item-opacity" (change)="calculateDeliveryPrice()" name="options" ng-control="options" type="radio" [disabled]="deliverMethodDisabled" [value]="false" [(ngModel)]="basketFactory.userSelectedPickupMethod">
          <br/>{{'cart-modal.bezorgen-label' | translate}}
        </label>
      </div>
      <span style="font-size: 11px; color: gray;" *ngIf="basketFactory.userSelectedPickupMethod == false">{{'cart-modal.bestelpage-label' | translate}}</span>
      <hr>
      <div>
        {{basketErrorMessage}}
      </div>
    </span>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-orange" [disabled]="basketList.length == 0 || !basketFactory.isMinimumPriceReached || !isClickable"
      (click)="placeOrder()">{{'cart-modal.bestellen-label' | translate}}</button>
  </div>