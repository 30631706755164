<div *ngIf="resFactory.restaurant != null">
  <div *ngIf="image" class="hero-image" [style.background-image] ="image"></div>
  <div class="hero-content">
    <div class="container">
      <div class="subcontent">
        <h2 class="page-headers pb-2">{{'beoordelingen.recente-beoordelingen-label' | translate}}
          <br *ngIf="resFactory.restaurant.disc > 0">
          <span *ngIf="resFactory.restaurant.disc > 0" class="korting-headerr">{{'beoordelingen.algemeen-korting-online-bestellingen-label' | translate: {kortingProcent: resFactory.restaurant.disc | number : '1.0-0' } }}</span></h2>
        <h3 class="page-headers">
          <h3 *ngIf="resFactory.restaurant.review_total_cnt>0">
            <div class="review-rating">
              <div class="review-stars">
                <span [style.width]="resFactory.restaurant.review_avg_star_pro+'%'" class="review-stars-range"></span>
              </div>
            </div> 
            {{'beoordelingen.algemeen-sterren-label' | translate: {avgSterren: resFactory.restaurant.review_avg_star_cnt, maxSterren: resFactory.restaurant.review_total_cnt} }}<span *ngIf="resFactory.restaurant.review_total_cnt>1">{{'beoordelingen.plural-label' | translate}}</span>
          </h3>
          <h3 *ngIf="resFactory.restaurant.review_total_cnt<=0"> {{'beoordelingen.geen-review-label' | translate}}
            </h3>
        </h3>
      </div>
      <div class="ordernow text-center pagination-centered">
          <a routerLink="{{getlinkforcurrlanguage('home')}}" class="ordernow-button" title="{{'beoordelingen.order_online_with' | translate}} {{resFactory.restaurant.restaurant_name}} {{resFactory.restaurant.restaurant_city}}" >{{'beoordelingen.order-online-button' | translate}}</a>
        </div>
    </div>
  </div>
  <div class="container-fluid beoordelingen">
    <div class="container">

     
      <div class="item-row">
    
      <div class="item-block" *ngFor="let review of reviews">
        <div class="txt-block col-sm-7 col-md-12 col-lg-6 col-xl-7">
          <span class="title">{{review.user_name}}</span>
          <small class="date" >{{convertdatetime(review.created_at)}}</small>
          <p>
            <span>
              <div class="stars">
                <span *ngFor="let i of [1,2,3,4,5]">
                  <i class="icon-star-full icon-star" *ngIf="review.delivery >= i"></i>
                  <i class="icon-star-empty icon-star" *ngIf="review.delivery < i"></i>
                </span>
              </div>
              <strong *ngIf="review.roPickup != '1'">{{'beoordelingen.bezorging-label' | translate}} </strong>
              <strong *ngIf="review.roPickup == '1'">{{'beoordelingen.afhalen-label' | translate}} </strong>
            </span>
          </p>
          <p>
            <span>
              <div class="stars">
                <span *ngFor="let i of [1,2,3,4,5]">
                  <i class="icon-star-full icon-star" *ngIf="review.food_quality >= i"></i>
                  <i class="icon-star-empty icon-star" *ngIf="review.food_quality < i"></i>
                </span>
              </div>
              <strong>{{'beoordelingen.kwaliteit-label' | translate}} </strong>
            </span>
          </p>


        </div>

      

        <div *ngIf="review.review_text != null && review.review_text.length>0" class="col-lg-12 pt-2">{{review.review_text}}</div>

    
    </div>



      </div>

      <button class="btn btn-warning btn-lg btn-block" *ngIf="!endreviews" (click)="getReviews()">{{'beoordelingen.load_more' | translate}}</button>
    </div>
  </div>
</div>