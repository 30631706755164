import { ModuleWithProviders } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { ContactComponent } from "./contact/contact.component";
import { BeoordelingenComponent } from "./beoordelingen/beoordelingen.component";
import { ThankYouComponent } from "./thank-you/thank-you.component";
import { PrivacyBeleidComponent } from "./privacybeleid/privacybeleid.component";

export const appRoutes: Routes = [

    { path: 'contact', component: ContactComponent },
    { path: 'nl/contact', component: ContactComponent },
    { path: 'fr/contact', component: ContactComponent },
    { path: 'en/contact', component: ContactComponent },
    
    { path: 'nl/beoordelingen', component: BeoordelingenComponent },
    { path: 'fr/avis', component: BeoordelingenComponent },
    { path: 'en/reviews', component: BeoordelingenComponent },
    { path: 'beoordelingen', component: BeoordelingenComponent },
    { path: 'avis', component: BeoordelingenComponent },
    { path: 'reviews', component: BeoordelingenComponent },

    { path: '', component: HomeComponent },
    { path: 'nl', component: HomeComponent },
    { path: 'fr', component: HomeComponent },
    { path: 'en', component: HomeComponent },
    
    { path: 'besteld-bij/:restslug/:id', component: ThankYouComponent},
    { path: 'nl/besteld-bij/:restslug/:id', component: ThankYouComponent},
    { path: 'ordered-at/:restslug/:id', component: ThankYouComponent},
    { path: 'en/ordered-at/:restslug/:id', component: ThankYouComponent},
    { path: 'commande-aupres-de/:restslug/:id', component: ThankYouComponent},
    { path: 'fr/commande-aupres-de/:restslug/:id', component: ThankYouComponent},
   
    { path: 'nl/privacy-beleid', component: PrivacyBeleidComponent },
    { path: 'fr/declaration-de-confidentialite', component: PrivacyBeleidComponent },
    { path: 'en/privacy-statement', component: PrivacyBeleidComponent },
    { path: 'privacy-beleid', component: PrivacyBeleidComponent },
    { path: 'declaration-de-confidentialite', component: PrivacyBeleidComponent },
    { path: 'privacy-statement', component: PrivacyBeleidComponent },


   // { path: 'besteld-bij', redirectTo: 'ordered-at'},
    { path: '**', redirectTo: '' },
];

export const AppRouting: ModuleWithProviders<RouterModule> = RouterModule.forRoot(appRoutes, {
    initialNavigation: 'enabledBlocking'
});
