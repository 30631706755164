import { BasketModel, ExtrasModel } from "./basket.model";
// import * as _ from 'lodash';

export class BasketListStorageModel {
    public id: string = "";
    // public productName: string;
    // public description: string = "";
    // public catId: string;
    public catName: string = "";
    public plus: ExtrasModel[] = [];
    public count: number = 1;
    // public itemPrice: number;
    public tot: number = 0;
    // public internalProductId: string = "";

    // public checkProperties(basketlist: BasketListStorageModel[]): BasketListStorageModel[] {
    //     let basketListModel: BasketListStorageModel[] = [];

    //     basketlist.forEach(element => {
    //        // console.clear();

    //         console.log(element)
    //         if('id' in element){
    //             console.log("has property ID")
    //         } else {
    //             console.log("has not property ID")
    //         }

    //         console.log(element)
    //         if('ids' in element){
    //             console.log("has property ID")
    //         } else {
    //             console.log("has not property ID")
    //         }
    //     });

    //     return basketListModel;
    // }

    public mapBasketStorageModelToBasketModel(basketstorage: BasketListStorageModel[], menu_rest, isPickup: boolean): BasketModel[] {

        let retModel: BasketModel[] = [];

        basketstorage.forEach(element => {
            let basketListItem = menu_rest.find(item => item.id == element.id);

            if (basketListItem != undefined) {

                let basketItem: BasketModel = new BasketModel().mapObjectToBasketModel(basketListItem, element.catName, isPickup);

                let totalprice: number = (isPickup ? +(basketItem.itemToPrice) : +(basketItem.itemPrice));

                element.plus.forEach(element => {
                    totalprice = totalprice + (+(element.Price));
                });

                if (totalprice == element.tot) {
                    basketItem.count = element.count;
                    basketItem.extras = element.plus;
                    basketItem.totalPriceWithExtras = totalprice;
                    retModel.push(basketItem);
                }
            }
        });

        return retModel;
    }

    public mapBasketModelToBasketStorageModel(basketlist: BasketModel[]): BasketListStorageModel[] {
        let retModel: BasketListStorageModel[] = [];

        basketlist.forEach(element => {
            let basketStorageModel: BasketListStorageModel = new BasketListStorageModel();

            basketStorageModel.id = element.productId;
            basketStorageModel.count = element.count;
            basketStorageModel.plus = element.extras;
            basketStorageModel.tot = element.totalPriceWithExtras;
            basketStorageModel.catName = element.catName;

            retModel.push(basketStorageModel);
        });

        return retModel;
    }
}
