import { Injectable, EventEmitter, Inject, PLATFORM_ID } from '@angular/core';
import { Restaurant } from '../models/restaurant.model';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
//import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformServer } from '@angular/common';

@Injectable()
export class SharedDataService {

  public restaurantObservable: EventEmitter<Restaurant> = new EventEmitter();

  public googleAnalyticsEventEmitter: EventEmitter<string> = new EventEmitter();

  public langpath: string ="nl"; //ssr and client
  public ssrcurrentUrlPath: string =""; //just ssr
  public currentUrlPath: string="";
  public avaiblelangs: string[]=["nl","fr","en"];

  public orderguid:string = "";

  public baseurl:string="";
  public checkouturl:string="";

  constructor(private _titleService: Title,private _meta: Meta, @Inject(PLATFORM_ID) private _platformId: Object, private _translate: TranslateService) { }

  public sendGoogleAnalyticsEvent(category: string, action: string, label: string, value: number,email?:string ) {
    const gaEvent = { category: category, action: action, label: label, value: value, email:email };
    this.googleAnalyticsEventEmitter.emit(JSON.stringify(gaEvent));
  }

  public setPageTitle(restaurant: Restaurant, titleText: string, url: string = "/") {
    let pageTitle: string = "";
    let urltekst: string;
    let urldesc: string;
    let kortingtxt: string = "Korting";
    let etenbestellen: string = "eten bestellen";
    let keywords: string ="eten afhalen, eten bezorgen, eten leveren, online eten bestellen, eten online";

    let check_client_or_ssr = isPlatformServer(this._platformId)?this.langpath:(this._translate.currentLang==undefined?this.langpath:this._translate.currentLang);


    if(check_client_or_ssr=='fr'){
      kortingtxt="De réduction";
      etenbestellen="commande de nourriture";
      keywords="plats à emporter, livrer de la nourriture, commander de la nourriture en ligne, manger en ligne";
    }
    else if(check_client_or_ssr=='en'){
      kortingtxt="Discount";
      etenbestellen="order food";
      keywords="takeaway food, deliver food, online food order, pickup food, eat online";
    }



    switch (url) {
      case "/contact":
      case "/nl/contact":
      case "/fr/contact":
      case "/en/contact":
        urltekst = "Contact";
      break;
      
      case "/reviews":
      case "/en/reviews":
        urltekst="Reviews";
      break;

      case "/beoordelingen":
      case "/nl/beoordelingen":
        urltekst="Beoordelingen";
      break;

      case "/avis":
      case "/fr/avis":
        urltekst="Avis";
      break;
      
      default:
        urltekst = "Menukaart";
        if(check_client_or_ssr=='fr'){
          urltekst="Carte de menu";
        }
        else if(check_client_or_ssr=='en'){
          urltekst="Our menu";
        }
        break;
    }

    if ((restaurant != null) && (restaurant.restaurant_name !== undefined)) {
      pageTitle = restaurant.restaurant_name +   ((restaurant.restaurant_name.toLowerCase().indexOf(restaurant.restaurant_city.toLowerCase()) !== -1) ?"":(" " + restaurant.restaurant_city)) + " - ";
      let teller: number = 0;

      if ((restaurant.slogan != "") && (restaurant.slogan != null) && (check_client_or_ssr==restaurant.lang) ) {
        pageTitle += restaurant.slogan + " - ";
      }

      pageTitle += urltekst + " - "

      if (restaurant.disc > 0) {
        let roundDisc: number = Math.round(restaurant.disc * 100) / 100;
        pageTitle += roundDisc + "% "+kortingtxt+" - "
      }


      let typesmeta: string = "";

      for (let element of restaurant.types_subsite) {
        if (teller >= 3) {
          break;
        }


        let vartype = element.en;

        if(check_client_or_ssr=="fr"){
          vartype = element.fr;
        }
        else if(check_client_or_ssr=="nl"){
          vartype = element.type;
        }
        

        pageTitle = pageTitle + vartype + ", ";

        typesmeta = typesmeta + vartype + ", ";

        teller++;
      };

      pageTitle = pageTitle.slice(0, pageTitle.lastIndexOf(","));



      
      switch(url)
      {
        case "/reviews":
        case "/en/reviews":
        case "/beoordelingen":
        case "/nl/beoordelingen":
        case "/avis":
        case "/fr/avis":
          

        let t = "Beoordelingen van ";

        if(check_client_or_ssr=='fr'){
          t="Appréciations ";
        }
        else if(check_client_or_ssr=='en'){
          t="Reviews";
        }

        urldesc = t + restaurant.restaurant_name +", "+restaurant.restaurant_address+", "+restaurant.restaurant_postcode + " "+restaurant.restaurant_city;
        break;
        case "/contact":
        case "/en/contact":
        case "/nl/contact":
        case "/fr/contact":

          let t2 = "Contact gegevens ";

          if(check_client_or_ssr=='fr'){
            t2="Contact ";
          }
          else if(check_client_or_ssr=='en'){
            t2="Contact ";
          }

        urldesc = t2 + restaurant.restaurant_name +", "+restaurant.restaurant_address+", "+restaurant.restaurant_postcode + " "+restaurant.restaurant_city+(restaurant.disc>0?(" - "+ Math.round(restaurant.disc)+"% "+kortingtxt):"")+".";
        break;
        default:

          let t3 = "Eten bestellen bij ";

          if(check_client_or_ssr=='fr'){
            t3="Commander un repas auprès de  ";
          }
          else if(check_client_or_ssr=='en'){
            t3="Order takeaway at ";
          }

        urldesc = t3 + restaurant.restaurant_name +", "+restaurant.restaurant_address+", "+restaurant.restaurant_postcode + " "+restaurant.restaurant_city+(restaurant.disc>0?(" - "+ Math.round(restaurant.disc)+"% "+kortingtxt):"")+". "+typesmeta;

      }
      

      this._meta.updateTag({ name: "description", content: urldesc});
      this._meta.updateTag({ name: "keywords", content: restaurant.restaurant_name + ", " + restaurant.restaurant_name + " " + restaurant.restaurant_city +", "+etenbestellen+", "+typesmeta+keywords });
      this._meta.updateTag({property: "og:title",content:pageTitle});
      this._meta.updateTag({property: "og:description",content:urldesc});
      this._meta.updateTag({property: "og:image",content:'https://cdn.etenonline.be/upload/restaurants/' + restaurant.restaurant_logo + '-b.png'});
      this._meta.updateTag({property: "og:site_name",content:pageTitle });
      this._meta.updateTag({name: "twitter:title",content:pageTitle});
      this._meta.updateTag({name: "twitter:description",content:urldesc});
      this._meta.updateTag({name: "twitter:image",content:'https://cdn.etenonline.be/upload/restaurants/' + restaurant.restaurant_logo + '-b.png'});

     

    } else if (titleText != "") {
      pageTitle = titleText;
    }

    if (pageTitle != "") {
      //this._titleService.setTitle(pageTitle.length>65?pageTitle.substring(0,66):pageTitle);
      this._titleService.setTitle(pageTitle);
    }

    

    



    return pageTitle;
  }


  getlinkforcurrlanguage(page:string,restlang:string){


    // let currentpath = isPlatformServer(this._platformId)?this.shareddataservice.currentUrlPath:window.location.pathname;

    
    // var allowedurls = ["/","/fr","/nl","/en","/reviews","/avis","/beoordelingen","/en/reviews","/fr/avis","/nl/beoordelingen","/contact","/en/contact","/fr/contact","/nl/contact"]; //all urls default

    // if(this.resFactory.restaurant.lang=="nl"){
    //   allowedurls = ["/","/fr","/en","/beoordelingen","/fr/avis","/en/reviews","/contact","/fr/contact","/en/contact"];
    // }
    // else if(this.resFactory.restaurant.lang=="fr"){
    //   allowedurls = ["/","/nl","/en","/avis","/nl/beoordelingen","/en/reviews","/contact","/nl/contact","/en/contact"];
    // }
    // else if(this.resFactory.restaurant.lang=="en"){
    //   allowedurls = ["/","/fr","/nl","/reviews","/fr/avis","/nl/beoordelingen","/contact","/fr/contact","/nl/contact"];
    // }

    if(page=="home"){
     
      if(restlang==this.langpath){
        return "/";
      }
      else{
        if(this.langpath=="en"){
          return "/en";
        }
        else if (this.langpath=="nl"){
          return "/nl";
        }
        else if(this.langpath=="fr"){
          return "/fr";
        }   
      }

    }
    else if(page=="reviews"){
      if(restlang==this.langpath){
        if(restlang=="en"){
          return "/reviews";
        }
        else if (restlang=="nl"){
          return "/beoordelingen";
        }
        else if(restlang=="fr"){
          return "/avis";
        }      
      }
      else{
        if(this.langpath=="en"){
          return "/en/reviews";
        }
        else if (this.langpath=="nl"){
          return "/nl/beoordelingen";
        }
        else if(this.langpath=="fr"){
          return "/fr/avis";
        }   
      }
    }
    else if(page=="privacy-statement"){
      if(restlang==this.langpath){
        if(restlang=="en"){
          return "/privacy-statement";
        }
        else if (restlang=="nl"){
          return "/privacy-beleid";
        }
        else if(restlang=="fr"){
          return "/declaration-de-confidentialite";
        }      
      }
      else{
        if(this.langpath=="en"){
          return "/en/privacy-statement";
        }
        else if (this.langpath=="nl"){
          return "/nl/privacy-beleid";
        }
        else if(this.langpath=="fr"){
          return "/fr/declaration-de-confidentialite";
        }   
      }
    }
    else if(page=="contact"){
      if(restlang==this.langpath){
        return "/contact";
      }
      else{
        if(this.langpath=="en"){
          return "/en/contact";
        }
        else if (this.langpath=="nl"){
          return "/nl/contact";
        }
        else if(this.langpath=="fr"){
          return "/fr/contact";
        }   
      }
    }

    
  }




  private _accentMap = {
    'á':'a', 'é':'e', 'í':'i','ó':'o','ú':'u','ü':'u','ö':'o','ç':'c'
  };

  public accent_fold (s) {
    if (!s) { return ''; }
    var ret = '';
    for (var i = 0; i < s.length; i++) {
      ret += this._accentMap[s.charAt(i)] || s.charAt(i);
    }
    return ret;
  }


}
