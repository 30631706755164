import { LoaderService } from "./loader.service";
import { Injectable } from "@angular/core";
// import {
//     Http, XHRBackend, RequestOptions, Response
// } from "@angular/http";
import { HttpClient,HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
//import { Observable } from "rxjs/Observable";
//import { map } from "rxjs/operators";
//import "rxjs/add/operator/map";
// import "rxjs/add/operator/catch";
// import "rxjs/add/operator/finally";

@Injectable()
export class HttpXService extends HttpClient {
    constructor(
       xhrbackend:HttpHandler,
        public loaderService: LoaderService
    ) {
        super(xhrbackend);
    }

    // post(url:string, requestBody) {
    //     this.showLoader();

    //     return super
    //         .post(url, requestBody)
    //         .pipe(
    //             catchError(this.handleError),
    //             finalize(() => {this.hideLoader()})
    //         )
            
           
    // }

    postOptions(url:string, requestBody, options) {
        this.showLoader();

        return super
            .post(url, requestBody, options)
            .pipe(
                catchError(this.handleError),
                finalize(() => {this.hideLoader()})
            )
    }

    getOptions(url:string, options, loader:boolean=true) {
        if(loader){
            this.showLoader();
        }

        return super
            .get<any>(url, options)
            .pipe(
                catchError(this.handleError),
                finalize(() => {this.hideLoader()})
            )
    }



    // getOptions(url:string) {
    //     this.showLoader();

    //     return super
    //         .get<any>(url)
    //         .pipe(
    //             catchError(this.handleError),
    //             finalize(() => {this.hideLoader()})
    //         )
    // }

    // delete(url:string, options) {
    //     this.showLoader();

    //     return super
    //         .delete<any>(url, options)
    //         .pipe(
    //             catchError(this.handleError),
    //             finalize(() => {this.hideLoader()})
    //         )
    // }

    private showLoader(): void {
        this.loaderService.show();
    }

    private hideLoader(): void {
        this.loaderService.hide();
    }

    // ServiceBase
    private handleError(response: Response) {
        if (response.status === 0) {
            // console.log(
            //     "*** ServiceBase: Connection issue with Server ... Status Code:" +
            //     response.status
            // );
            return Observable.throw("[=error.message.connection.to.server]");
        }
        if (response.status === 401) {
            // console.log("*** ServiceBase: You are not autorized" + response.status);
            return Observable.throw("[=error.message.not.autorized]");
        }
        return Observable.throw(
            "[=error.message.statuscode." + response.status + "]"
        );
    }
}
