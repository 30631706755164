import { Component, OnInit } from '@angular/core';
import { RestaurantFactory } from '../shared/models/restaurantFactory';
import { DataService } from '../shared/services/data.service';
import { SharedDataService } from '../shared/services/shared-data.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
})
export class ContactComponent implements OnInit {
  
  //public showMaps: boolean = false;
  
  constructor(public resFactory: RestaurantFactory, public shareddataservice: SharedDataService) {
  }

  ngOnInit() {
    //this.setCoordinates();
    //this.getCoordinates();
  }

  getlinkforcurrlanguage(page:string){

    return this.shareddataservice.getlinkforcurrlanguage(page, this.resFactory.restaurant.lang);

    
  }
}
