export class BasketModel {
    public productId: string;
    public productName: string;
    public description: string = "";
    public catId: string;
    public catName: string;
    public extras: ExtrasModel[] = [];
    public count: number = 1;
    public itemPrice: number;
    public itemToPrice: number;
    public totalPriceWithExtras: number;
    public internalProductId: string = "";
    public links:any[]=[];

    public mapObjectToBasketModel(item, catName: string, isPickup: boolean): BasketModel {
        let basketModel: BasketModel = new BasketModel
        basketModel.catId = item.menu_cat;
        basketModel.productName = item.menu_name;
        basketModel.productId = item.id;
        basketModel.catName = catName;
        basketModel.itemPrice = +(item.price);
        basketModel.itemToPrice = +(item.toPrice);
        basketModel.totalPriceWithExtras = isPickup ? +(item.toPrice) : +(item.price);
        basketModel.description = item.description;
        basketModel.internalProductId = item.custom_id;
        basketModel.links = item.links;
        // this.openAddItemModal(item, res);
        return basketModel;
      }
}

export class ExtrasModel {
    public id: number;
    public Price: string;
    public Name: string;
    public ExtrasType: ExtrasType;
    public Index: number;
}

export class ExtrasType {
    public Name: string;
    public Id: number;
    public Index: number;
}
